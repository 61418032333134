import React, {startTransition, useEffect, useRef, useState} from "react";
import {useOptionsStore, useSearchStore} from "../state/store";
import {
    createSearchString, generateSkip, generateSort, generateTop,
} from "../integration/search/searchConnectorUtils";
import {initialOptions, initialSearch} from "../state/initialstate";
import {fetchResults} from "../integration/search/AzureSearchConnector";
import {Container, HStack, VStack} from "@chakra-ui/react";
import {getFieldsFromStore} from "../state/storeUtils";
import {SearchFilter} from "../components/SearchFilter/SearchFilter";
import {SearchResultList} from "../components/SearchResultList/SearchResultList";

export const Search = () => {

    console.log("Search page");

    const optionsStore = useOptionsStore();
    const searchStore = useSearchStore();

    useEffect(() => {

        console.log("Search page useEffect");

        const makeAsyncSearch = async () => {
            searchStore.setIsLoaded(false); //show spinner/skeleton

            const storeMap = getFieldsFromStore(optionsStore);

            const query = createSearchString(storeMap);
            const top = generateTop(initialSearch.itemsPerPage);
            const sort = generateSort(searchStore.sort, initialOptions.ignoreSponsored);


            const prevCoreSearchString = searchStore.coreSearchString;  //save the previous core search string for comparison
            let skip = searchStore.skip; //by default using currently selected page

            if (prevCoreSearchString !== query + top + sort) { //if search is new, reset page to 0
                console.log("Search page: coreSearchString changed! ...resetting active to 0", query + top + sort);
                skip = generateSkip(0, 7); //subset of search results
                searchStore.setActivePage(0); //active page
            }

            searchStore.setCoreSearchString(query + top + sort); //set core search string to detect changes at the next search

            const searchString = query + top + sort + skip;
            console.log("Search page useEffect[] ", "searchString", searchString);

            const results = await fetchResults(searchString);

            startTransition(() => {
                searchStore.setSearchResults(results);
                searchStore.setIsLoaded(true); //show results instead of spinner/skeleton
            });
        };

        makeAsyncSearch();

    }, [optionsStore, searchStore.sort, searchStore.skip]);

    useEffect(() => {
        console.log("Search page useEffect[searchStore.activePage]");

        window.scrollTo(0, 0);

    }, [searchStore.activePage]);

    return <Container maxW="container.xl" mt={[3, 2]} mb={6} id='search-container-xl'>
        <HStack w="full" spacing={6} align="start" as='div'>
            <VStack
                bgColor="#f4f4f4"
                p={6}
                rounded="xl"
                spacing={4}
                w="26rem"
                flexShrink={0}
                hideBelow="xl"
            >
                <SearchFilter/>
            </VStack>
            <SearchResultList/>
        </HStack>
    </Container>
};