import sha1 from 'crypto-js/sha1'

const hashUserID = userID => {
    const hash = sha1(userID);
    console.log('email as hash', hash.toString());
    return hash.toString().toLowerCase();
};

const getSHA1 = async email => {
    // Encode the text as a Uint8Array
    const encoder = new TextEncoder();
    const data = encoder.encode(email);

    // Compute the hash
    const hashBuffer = await crypto.subtle.digest('SHA-1', data);

    // Convert the hash to hexadecimal format
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

    return hashHex;
}

// Example usage
getSHA1('Hello, world!').then(hashHex => console.log(hashHex));


const isLandscapeOrientation = async file => {
    const url = URL.createObjectURL(file);
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        const bitmap = await createImageBitmap(blob);
        return bitmap.width > bitmap.height;
    } finally {
        URL.revokeObjectURL(url);
    }
};

const validateFiles = async files => {
    const FILE_SIZE_LIMIT = 400 * 1024; // 400 KB
    const FILE_TYPE = 'image/jpeg';
    const MAX_FILES = 20;
    const MAX_TOTAL_SIZE = 8 * 1024 * 1024; // 8  MB

    console.log('validateFiles files', files);

    if (files.length > MAX_FILES) {
        return [{file: 'All', errorCode: 400, errorMessage: `Maximum of ${MAX_FILES} files allowed`}];
    }

    const totalSize = files.reduce((acc, file) => acc + file.size, 0);
    if (totalSize > MAX_TOTAL_SIZE) {
        return [{file: 'All', errorCode: 400, errorMessage: `Total file size exceeds ${MAX_TOTAL_SIZE} bytes`}];
    }

    const errors = await Promise.all(files.map(async (file) => {
        if (file.size > FILE_SIZE_LIMIT) {
            return {file: file.name, errorCode: 413, errorMessage: 'Invalid file size'};
        }
        if (file.type !== FILE_TYPE) {
            return {file: file.name, errorCode: 415, errorMessage: 'Invalid file type'};
        }
        const isLandscape = await isLandscapeOrientation(file); // Ensure this function handles errors properly
        if (!isLandscape) {
            return {file: file.name, errorCode: 415, errorMessage: 'Invalid image orientation'};
        }
        // If there's no error, return null
        return null;
    }));

    return errors.filter(error => error !== null);
}


const translateErrorMessage = (statusObject, t) => {
    console.log('translateErrorMessage', statusObject);
    if (statusObject !== undefined) {
        if (statusObject.code === 413) return t('texts:sell.car-ad-card.edit-images.invalid-file-size');
        else if (statusObject.code === 415 && statusObject.message === 'invalid file type')
            return t('texts:sell.car-ad-card.edit-images.invalid-file-type');
        else if (statusObject.code === 415 && statusObject.message === 'invalid image orientation')
            return t('texts:sell.car-ad-card.edit-images.invalid-image-orientation')
    } else {
        console.log('translateErrorMessage: no errors, statusObject is undefined');
        return '';
    }
}

export {isLandscapeOrientation, validateFiles, translateErrorMessage, getSHA1}