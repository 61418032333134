import {Text, VStack} from '@chakra-ui/react';
import {PopularModelsList} from "./PopularModelsList";
import {useTranslation} from "react-i18next";

export const PopularModels = () => {

    const {t} = useTranslation(['texts']);

    return <VStack align="start" gap={{base: 4, md: 4}} w="full">
        <Text as="h3" textStyle="h2" textTransform="uppercase">
            {t('texts:home.popular-models')}
        </Text>
        <PopularModelsList />
    </VStack>
}