import React from "react";
import {Image} from "@chakra-ui/react";

import audi from "./audi.svg";
import bmw from "./bmw.svg";
import hyundai from "./hyundai.svg";
import lexus from "./lexus.svg";
import mercedes from "./mercedes.svg";
import peugeot from "./peugeot.svg";
import porsche from "./porsche.svg";
import tesla from "./tesla.svg";
import toyota from "./toyota.svg";
import vw from "./volkswagen.svg";

export const AudiLogo = () => <Image src={audi} alt="Audi Logo" opacity='.4' w={20}/>;
export const BmwLogo = () => <Image src={bmw} alt='BMW logo' opacity='.4'/>
export const HyundaiLogo = () => <Image src={hyundai} alt='Hyundai logo' opacity='.4'/>
export const LexusLogo = () => <Image src={lexus} alt='Lexus logo' opacity='.4'/>
export const MercedesLogo = () => <Image src={mercedes} alt='Mercedes logo' opacity='.4'/>
export const PeugeotLogo = () => <Image src={peugeot} alt="Peugeot Logo" opacity='.4' h={58}/>;
export const PorscheLogo = () => <Image src={porsche} alt='Porsche logo' opacity='.4'/>
export const TeslaLogo = () => <Image src={tesla} alt='Tesla logo' opacity='.4'/>
export const ToyotaLogo = () => <Image src={toyota} alt='Toyota logo' opacity='.4'/>
export const VwLogo = () => <Image src={vw} alt='VW logo' opacity='.4'/>

export const popularMakesList = [
    {logo: <AudiLogo/>, make: 'audi'},
    {logo: <BmwLogo/>, make: 'bmw'},
    {logo: <MercedesLogo/>, make: 'mercedes-benz'},
    {logo: <PorscheLogo/>, make: 'porsche'},
    {logo: <PeugeotLogo/>, make: 'peugeot'},
    {logo: <LexusLogo/>, make: 'lexus'},
    {logo: <TeslaLogo/>, make: 'tesla'},
    {logo: <HyundaiLogo/>, make: 'hyundai'},
    {logo: <VwLogo/>, make: 'vw'},
    {logo: <ToyotaLogo/>, make: 'toyota'}];