import {
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Tab,
    TabPanel,
    TabPanels,
    Tabs,
    Text
} from "@chakra-ui/react";
import React, {useState} from "react";
import {AddPhoto} from "../../AddPhoto/AddPhoto";
import {EditPhotos} from "../../EditPhotos/EditPhotos";
import {EditMetadata} from "../../EditMetadata/EditMetadata";
import {useTranslation} from "react-i18next";
import {tabsStyle} from "../../Tabs/TabsStyle";

export const UpdateAdvertisementModal = ({isOpen, onClose, isPublished, car}) => {

    const {t} = useTranslation(['texts']);

    const [tabIndex, setTabIndex] = useState(0);
    const handleTabsChange = index => {
        console.log('handleTabsChange', index);
        setTabIndex(index)
    };

    return <Modal isOpen={isOpen} onClose={onClose} isCentered size='xl'>
        <ModalOverlay>
            <ModalContent
                minH={'100vh'}
                // maxW={{base: '400px', md: '400px'}}
            >
                <ModalHeader>
                    <Text>{t('vehicle-information.edit-vehicle-info')}</Text>
                    <ModalCloseButton position='absolute' top='1rem'/>
                </ModalHeader>
                <ModalBody w={'full'} px={[0, 4]}>
                    <Tabs w={'full'}
                          sx={tabsStyle}
                          index={tabIndex}
                          onChange={handleTabsChange}
                          align='start'>
                        <HStack>
                            <Tab>{t('sell.car-ad-card.edit-modal.tabs.edit-info')}</Tab>
                            <Tab>{t('sell.car-ad-card.edit-modal.tabs.edit-images')}</Tab>
                            <Tab>{t('sell.car-ad-card.edit-modal.tabs.add-images')}</Tab>
                        </HStack>
                        <TabPanels>
                            <TabPanel px={0}>
                                <EditMetadata car={car} onClose={onClose} isPublished={isPublished}/>
                            </TabPanel>
                            <TabPanel bg='#F4F4F4'>
                                <EditPhotos car={car} onClose={onClose} isPublished={isPublished}/>
                            </TabPanel>
                            <TabPanel bg='#F4F4F4'>
                                <AddPhoto car={car} onClose={onClose} isPublished={isPublished}
                                          handleTabsChange={handleTabsChange}
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ModalBody>
            </ModalContent>
        </ModalOverlay>
    </Modal>
}