import {defineStyle, defineStyleConfig} from '@chakra-ui/react'

const baseStyle = defineStyle({
    border: "1px solid",
    borderColor: "neutral.500",
    background: "white",
    color: "neutral.700",
    borderRadius: "md",
    padding: 3,
    _hover: {
        borderColor: "neutral.900",
        color: "neutral.900",
    },
    _focus: {
        borderColor: "neutral.900",
        color: "neutral.900",
    },
    _placeholder: {
        color: "neutral.700",
    },
    _disabled: {
        color: "neutral.400",
        borderColor: "neutral.600",
    },
});

const primaryStyle = defineStyle({
    borderColor: "neutral.500",
    color: "neutral.700",
    _hover: {
        borderColor: "neutral.900",
        color: "neutral.900",
    },
});

const primaryActiveStyle = defineStyle({
    borderColor: "neutral.900",
    color: "neutral.900",
});

export const textareaTheme = defineStyleConfig({
    baseStyle,
    variants: {base: baseStyle, primary: primaryStyle, primaryActive: primaryActiveStyle},
});
