export const years = [
    {value: "", name: "", disabled: true, selected: false},
    {value: "2024", name: "2024"},
    {value: "2023", name: "2023"},
    {value: "2022", name: "2022"},
    {value: "2021", name: "2021"},
    {value: "2020", name: "2020"},
    {value: "2019", name: "2019"},
    {value: "2018", name: "2018"},
    {value: "2015", name: "2015"},
    {value: "2010", name: "2010"},
    {value: "2005", name: "2005"},
    {value: "2000", name: "2000"},
    {value: "1995", name: "1995"},
    {value: "1990", name: "1990"},
    {value: "1980", name: "1980"},
    {value: "1970", name: "1970"}
];