export const getInitialLanguage = () => {
    console.log('getInitialLanguage in i18n');

    const userSelectedLanguage = localStorage.getItem('user-selected-language');
    if (userSelectedLanguage) {
        return userSelectedLanguage;
    }

    const hostname = window.location.hostname;
    const tld = hostname.split('.').pop();

    let matchingLanguage;
    switch (tld) {
        case 'eu':
            matchingLanguage = 'en';
            break;
        case 'ee':
            matchingLanguage = 'ee';
            break;
        case 'fi':
            matchingLanguage = 'fi';
            break;
        case 'lv':
            matchingLanguage = 'lv';
            break;
        case 'lt':
            matchingLanguage = 'lt';
            break;
        case 'se':
            matchingLanguage = 'se';
            break;
        default:
            const lang = navigator.language;
            matchingLanguage = selectLanguageFromLocaleMap(lang);
            break;
    }
    return matchingLanguage;
}

const selectLanguageFromLocaleMap = lang => {
    let matchingLanguage = languageLocaleMap[lang];
    if (matchingLanguage === undefined) {
        return 'en'
    }
    return matchingLanguage;
}

const languageLocaleMap = {
    'ru-RU': 'ru', 'et-EE': 'ee',
}