import React, {useCallback, useState} from "react";
import {useDictionaryStore, useOptionsStore} from "../../state/store";

import {initialOptions} from "../../state/initialstate";

import {
    Card,
    CardBody,
    CardHeader,
    HStack,
    VStack,
    SimpleGrid,
    Text,
    Box,
    GridItem,
    Checkbox,
    Fade,
    Button,
    useDisclosure,
} from "@chakra-ui/react";

import {useShallow} from "zustand/react/shallow";
import {useTranslation} from "react-i18next";
import {
    parseAndSetStart, parseAndSetEnd, formatCities,
} from "../SimpleSearch/simpleSearchHelper";
import {years} from "../../data/years";
import {pickEnding, pickStarting} from "../Search/SearchUtils";

import {isEmpty} from "lodash";
import {pricesFormatted as prices} from "../../data/prices";
import SelectFieldSimple from "../Search/SelectFieldSimple";
import SelectFieldSimple2 from "../Search/SelectFieldSimple2";
import {mileages} from "../../data/mileages";
import {powers} from "../../data/powers";
import {FilterCheckbox} from "../FilterCheckbox/FilterCheckbox";

export const SearchFilter = () => {


    const optionsStore = useOptionsStore();
    const {t} = useTranslation(["texts"]);

    const makes = useDictionaryStore(useShallow((store) => store.makes));
    const models = useDictionaryStore(useShallow((store) => store.models));
    const transmissions = useDictionaryStore(useShallow((store) => store.transmissions));
    const histories = useDictionaryStore(useShallow((store) => store.history));
    const fuels = useDictionaryStore(useShallow((store) => store.fuels));
    const drivetrains = useDictionaryStore(useShallow((store) => store.drivetrains));
    const countries = useDictionaryStore(useShallow((store) => store.countries));
    const features = useDictionaryStore(useShallow((store) => store.features));
    // console.log("features in SearchFilter", features);
    const colors = useDictionaryStore(useShallow((store) => store.colors));

    const make = useOptionsStore(useShallow((store) => store.make));
    const model = useOptionsStore(useShallow((store) => store.model));
    const transmission = useOptionsStore(useShallow((store) => store.transmission));
    const mileage = useOptionsStore(useShallow((store) => store.mileage));
    const [endMileages, setEndMileages] = useState(mileages);
    const [startMileages, setStartMileages] = useState(mileages);

    const year = useOptionsStore((store) => store.year);
    const [endYears, setEndYears] = useState(years);
    const [startYears, setStartYears] = useState(years);

    const price = useOptionsStore(useShallow((store) => store.price));
    const [endPrices, setEndPrices] = useState(prices);
    const [startPrices, setStartPrices] = useState(prices);

    const power = useOptionsStore(useShallow((store) => store.power));

    // console.log("SearchFilter color", optionsStore.color, colors);


    const [endPowers, setEndPowers] = useState(powers);
    const [startPowers, setStartPowers] = useState(powers);

    const [cities, setCities] = useState([]);

    const {isOpen, onToggle} = useDisclosure();

    //how to avoid repetition of short search functions?
    const changeMake = useCallback((e) => {
        const make = e.target.value;

        if (make) {
            const matchingMake = makes.find((item) => item.value === make);
            console.log("Search page changeMake matchingMake", matchingMake);
            optionsStore.updateMultipleOptions({
                make: {...matchingMake, visible: true}, model: initialOptions.model,
            });
        } else {
            console.log("Search page changeMake matchingMake is empty", initialOptions.make);
            optionsStore.setMake(initialOptions.make);
        }
    }, [makes, optionsStore.setMake, optionsStore.updateMultipleOptions]);

    const changeModel = useCallback((e) => {
        const model = e.target.value;
        if (model) {
            const matchingModel = models.find((item) => item.value === model);
            optionsStore.setModel({...matchingModel, visible: true});
        } else {
            optionsStore.setModel(initialOptions.model);
        }
    }, [optionsStore.setModel, models]);


    const changeYearStart = (e) => {
        const yearFromForm = e.target.value;

        if (!isEmpty(yearFromForm)) {
            /** year from form is not empty */

            if (year.value && year.value[1] > yearFromForm) {
                /** end year option is kept */

                const yearArray = [yearFromForm, year.value[1]];
                const yearCopy = {name: `${year.value[0] - year.value[1]}`, value: yearArray, visible: true};
                optionsStore.setYear(yearCopy);
                const toYears = pickEnding(yearFromForm, years);
                setEndYears(toYears);

            } else {
                /** end year dictionary and option are reset */
                const toYears = pickEnding(yearFromForm, years);
                console.log("changeYearStart set endingYears", toYears);
                setEndYears(toYears);
                parseAndSetStart(yearFromForm, year, optionsStore.setYear);
            }
        } else {
            /**start year from form is empty*/
            if (year.value &&
                ((year.value.length === 2 && year.value[1] === undefined) ||
                    year.value.length === 1)) {
                /** end year is empty: reset end-year dictionary and reset start year */
                console.log("Search page changeYearStart value is empty; year", year);
                setEndYears(years);
                parseAndSetStart(undefined, initialOptions.year, optionsStore.setYear);
            } else {
                /** end year is not empty: reset select, keep end year */
                console.log("Search page changeYearStart value === 2; and second is filled", year.value[1]);
                setEndYears(years);
                const yearCopy = [undefined, year.value[1]];
                optionsStore.setYear({value: yearCopy, name: `${year.value[1]}`, visible: true});
            }
        }
    };

    const changeYearEnd = (e) => {
        const yearFromForm = e.target.value;
        console.log("Search page changeYear End", yearFromForm);

        if (!isEmpty(yearFromForm)) {
            const fromYears = pickStarting(yearFromForm, years);
            console.log("Search page changeYearEnd fromYears", fromYears);
            setStartYears(fromYears);
        } else {
            console.log("Search page changeYearEnd value is empty");
            setStartYears(years);
        }

        parseAndSetEnd(yearFromForm, year, optionsStore.setYear);
    };

    const changePriceStart = (e) => {
        const startPrice = e.target.value;

        if (!isEmpty(startPrice)) {
            /** start price from form is not empty */

            const startPriceAsNumber = Number.parseInt(startPrice);

            if (price.value && (price.value[1] > startPriceAsNumber)) {
                /** end price option is kept */
                const priceArray = [startPriceAsNumber, price.value[1]];
                console.log('changePriceStart priceArray', priceArray, 'startPrice', startPrice, 'typeof', typeof startPrice);
                const priceCopy = {name: `${startPrice} - ${price.value[1]}`, value: priceArray, visible: true};
                optionsStore.setPrice(priceCopy);
                const toPrices = pickEnding(startPrice, prices);
                setEndPrices(toPrices);
            } else {
                /** end price dictionary and option are reset */
                const toPrices = pickEnding(startPrice, prices);
                console.log("changePriceStart set endingPrices", toPrices);
                setEndPrices(toPrices);
                parseAndSetStart(startPrice, price, optionsStore.setPrice);
            }
        } else {
            /** start price from form is empty */
            if (
                price.value &&
                ((price.value.length === 2 && price.value[1] === undefined) ||
                    price.value.length === 1)
            ) {
                /** end price is empty: reset end-price dictionary and reset start price */
                console.log("Search page changePriceStart value is empty; price", price);
                setEndPrices(prices);
                parseAndSetStart(undefined, initialOptions.price, optionsStore.setPrice);
            } else {
                /** end price is not empty: reset select, keep end price */
                console.log("Search page changePriceStart value < 2; price");
                setEndPrices(prices);
                const priceCopy = [undefined, price.value[1]];
                optionsStore.setPrice({value: priceCopy, name: `${price.value[1]}`, visible: true});
            }
        }
    };

    const changePriceEnd = (e) => {
        const endPrice = e.target.value;
        console.log("Search page changePriceEnd", endPrice);

        if (!isEmpty(endPrice)) {
            const fromPrices = pickStarting(endPrice, prices);
            console.log("Search page changePriceEnd fromPrices", fromPrices);
            setStartPrices(fromPrices);
        } else {
            console.log("Search page changePriceEnd value is empty");
            setStartPrices(prices);
        }

        parseAndSetEnd(endPrice, price, optionsStore.setPrice);
    };


    const changeTransmission = (e) => {
        const transmission = e.target.value;
        console.log("Search page changeTransmission", transmission);
        //{"value":"automatic","name":"Automatic"}

        if (isEmpty(transmission)) {
            optionsStore.setTransmission(initialOptions.transmission);
        } else {
            const match = transmissions.find((item) => item.value === transmission);
            optionsStore.setTransmission({
                ...match, visible: true,
            });
        }
    };

    const changeFuel = (e) => {
        const fuel = e.target.value;
        console.log("Search page changeFuel", fuel);

        if (isEmpty(fuel)) {
            optionsStore.setFuel(initialOptions.fuel);
        } else {
            const match = fuels.find((item) => item.value === fuel);
            optionsStore.setFuel({
                ...match, visible: true,
            });
        }
    };

    const changeVat = (e) => {
        console.log("Search page changeVat", e.target.checked);
        if (price.visible) {
            optionsStore.setVatIncluded({
                value: e.target.checked.toString(), visible: e.target.checked, name: "VAT included",
            });
        }
    };

    const changeHistory = (historyName, e) => {
        console.log("Search page changeHistory", historyName, e.target.checked);
        const selected = histories.find((item) => item.value === historyName); // find history in the dictionary
        selected.visible = true; // set visible to true

        if (e.target.checked) {
            optionsStore.setHistory([...optionsStore.history, selected]); // add to the store
        } else {
            const index = optionsStore.history.findIndex((el) => el.value === historyName);
            const newHistory = [...optionsStore.history.slice(0, index), ...optionsStore.history.slice(index + 1),];
            // remove from the copy of the history in a store
            optionsStore.setHistory(newHistory);

            console.log("Search page changeHistory", newHistory);
        }
    };

    const changeMileageStart = (e) => {
        const startMileage = e.target.value;

        if (!isEmpty(startMileage)) {
            /** start mileage from form is not empty */

            const startMileageAsNumber = Number.parseInt(startMileage);

            if (mileage.value && (mileage.value[1] > startMileageAsNumber)) {
                /** end mileage option is kept */
                const mileageArray = [startMileageAsNumber, mileage.value[1]];
                const mileageCopy = {name: `${startMileage} - ${mileage.value[1]}`, value: mileageArray, visible: true};
                optionsStore.setMileage(mileageCopy);
                const toMileages = pickEnding(startMileage, mileages);
                setEndMileages(toMileages);
            } else {
                /** end mileage dictionary and option are reset */
                const toMileages = pickEnding(startMileage, mileages);
                console.log("changeMileageStart set endingMileages", toMileages);
                setEndMileages(toMileages);
                parseAndSetStart(startMileage, mileage, optionsStore.setMileage);
            }
        } else {
            /** start mileage from form is empty */
            if (
                mileage.value &&
                ((mileage.value.length === 2 && mileage.value[1] === undefined) ||
                    mileage.value.length === 1)
            ) {
                /** end mileage is empty: reset end-mileage dictionary and reset start mileage */
                console.log("Search page changeMileageStart value is empty; mileage", mileage);
                setEndMileages(mileages);
                parseAndSetStart(undefined, initialOptions.mileage, optionsStore.setMileage);
            } else {
                /** end mileage is not empty: reset select, keep end mileage */
                console.log("Search page changeMileageStart value < 2; mileage");
                setEndMileages(mileages);
                const mileageCopy = [undefined, mileage.value[1]];
                optionsStore.setMileage({value: mileageCopy, name: `${mileage.value[1]}`, visible: true});
            }
        }
    };


    const changeMileageEnd = (e) => {
        const endMileage = e.target.value;
        console.log("Search page changeMileage End", endMileage);

        if (!isEmpty(endMileage)) {
            const fromMileages = pickStarting(endMileage, mileages);
            console.log("Search page changeMileageEnd fromMileages", fromMileages);
            setStartMileages(fromMileages);
        } else {
            console.log("Search page changeMileageEnd value is empty");
            setStartMileages(mileages);
        }

        parseAndSetEnd(endMileage, mileage, optionsStore.setMileage);
    };

    const changeDrivetrain = (e) => {
        const drivetrain = e.target.value;
        console.log("Search page changeDrivetrain", drivetrain);

        if (isEmpty(drivetrain)) {
            optionsStore.setDrivetrain(initialOptions.drivetrain);
            console.log("Search page changeDrivetrain isEmpty", initialOptions.drivetrain);
        } else {
            const match = drivetrains.find((item) => item.value === drivetrain);
            optionsStore.setDrivetrain({
                ...match, visible: true,
            });
        }
    };

    const changePowerStart = (e) => {
        const startPower = e.target.value;

        if (!isEmpty(startPower)) {
            /** start power from form is not empty */

            const startPowerAsNumber = Number.parseInt(startPower);

            if (power.value && (power.value[1] > startPower)) {
                /** end power option is kept */
                const powerArray = [startPowerAsNumber, power.value[1]];
                const powerCopy = {name: `${startPower} - ${power.value[1]}`, value: powerArray, visible: true};
                optionsStore.setPower(powerCopy);
                const toPowers = pickEnding(startPower, powers);
                setEndPowers(toPowers);
            } else {
                /** end power dictionary and option are reset */
                const toPowers = pickEnding(startPower, powers);
                console.log("changePowerStart set endingPowers", toPowers);
                setEndPowers(toPowers);
                parseAndSetStart(startPower, power, optionsStore.setPower);
            }
        } else {
            /** start power from form is empty */
            if (
                power.value &&
                ((power.value.length === 2 && power.value[1] === undefined) ||
                    power.value.length === 1)
            ) {
                /** end power is empty: reset end-power dictionary and reset start power */
                console.log("Search page changePowerStart value is empty; power", power);
                setEndPowers(powers);
                parseAndSetStart(undefined, initialOptions.power, optionsStore.setPower);
            } else {
                /** end power is not empty: reset select, keep end power */
                console.log("Search page changePowerStart value < 2; power");
                setEndPowers(powers);
                const powerCopy = [undefined, power.value[1]];
                optionsStore.setPower({value: powerCopy, name: `${power.value[1]}`, visible: true});
            }
        }
    };

    const changePowerEnd = (e) => {
        const endPower = e.target.value;
        console.log("Search page changePower End", endPower);

        if (!isEmpty(endPower)) {
            const fromPowers = pickStarting(endPower, powers);
            console.log("Search page changePowerEnd fromPowers", fromPowers);
            setStartPowers(fromPowers);
        } else {
            console.log("Search page changePowerEnd value is empty");
            setStartPowers(powers);
        }

        parseAndSetEnd(endPower, power, optionsStore.setPower);
    };


    const changeCountry = (e) => {
        console.log("Search page changeCountry", e.target.value);

        const country = e.target.value;

        if (isEmpty(country)) {
            optionsStore.setCountry(initialOptions.country);
        } else {
            const match = countries.find((item) => item.value === country);
            optionsStore.setCountry({
                ...match, visible: true,
            });

            const formattedCities = formatCities(match.cities);
            console.log("changeCountry match", formattedCities);
            setCities(formattedCities);
        }
    };

    const changeCity = (e) => {
        console.log("Search page changeCity", e.target.value);
        if (isEmpty(e.target.value)) {
            optionsStore.setCity(initialOptions.city);
        } else {
            const match = cities.find((item) => item.value === e.target.value);
            optionsStore.setCity({
                ...match, visible: true,
            });
        }
    };

    const changeFeatures = (featureName, e) => {
        const selected = features.find((item) => item.value === featureName); // find feature in the dictionary
        selected.visible = true; // set visible to true

        if (e.target.checked) {
            optionsStore.setFeatures([...optionsStore.features, selected]); // add to the store
        } else {
            const index = optionsStore.features.findIndex((el) => el.value === featureName);
            const newFeatures = [...optionsStore.features.slice(0, index), ...optionsStore.features.slice(index + 1),];
            // remove from the copy of the features in a store
            optionsStore.setFeatures(newFeatures);
        }
    };

    const changeColor = (colorName, e) => {
        console.log("Search page changeColor", colorName, e.target.checked, 'color', optionsStore.color);
        const selected = colors.find((item) => item.value === colorName); // find color in the dictionary
        console.log("Search page changeColor selected", selected);

        selected.visible = true; // set visible to true
        if (e.target.checked) {
            optionsStore.setColor([...optionsStore.color, selected]); // add to the store
        } else {
            const index = optionsStore.color.findIndex((el) => el.value === colorName);
            const newColors = [...optionsStore.color.slice(0, index), ...optionsStore.color.slice(index + 1),];
            // remove from the copy of the colors in a store
            optionsStore.setColor(newColors);
        }
    };

    return (<>
        <MenuBlock name={t("texts:search.search-area.basic-parameters")}>
            <SimpleGrid columns={1} rowGap={6} w="full">
                <SelectFieldSimple2
                    label={t("texts:sell.characteristics.make")}
                    options={makes}
                    field="make"
                    handler={changeMake}
                    fieldFromStore={make}
                />
                <SelectFieldSimple2
                    label={t("texts:sell.characteristics.model")}
                    field="model"
                    options={!make.name ? [] : models}
                    fieldFromStore={model}
                    handler={changeModel}
                />
                <GridItem>
                    <HStack>
                        <SelectFieldSimple
                            label={t("texts:common.price-from")}
                            field="start-price"
                            options={startPrices}
                            fieldFromStore={price}
                            handler={changePriceStart}
                        />
                        <SelectFieldSimple
                            label={t("texts:common.price-to")}
                            field="end-price"
                            options={endPrices}
                            fieldFromStore={price}
                            handler={changePriceEnd}
                        />
                    </HStack>
                    <Box pt={3}>
                        <Checkbox
                            size="lg"
                            spacing="1rem"
                            disabled={!price.visible}
                            colorScheme="primary"
                            onChange={changeVat}
                        >
                            <Text fontSize="14px">{t("texts:vehicle-information.including-vat")}</Text>
                        </Checkbox>
                    </Box>
                </GridItem>
                <HStack>
                    <SelectFieldSimple
                        field="start-year"
                        label={t("texts:common.year-from")}
                        options={startYears}
                        fieldFromStore={year}
                        handler={changeYearStart}
                    />
                    <SelectFieldSimple
                        field="end-year"
                        label={t("texts:common.year-to")}
                        options={endYears}
                        fieldFromStore={year}
                        handler={changeYearEnd}
                    />
                </HStack>
                <SelectFieldSimple2
                    fieldFromStore={optionsStore.fuel}
                    field="fuel"
                    label={t("texts:search.search-area.fuel")}
                    options={fuels}
                    handler={changeFuel}
                />
            </SimpleGrid>
        </MenuBlock>

        <MenuBlock name={t("texts:search.search-area.history")} id>
            <SimpleGrid columns={2} rows={2} spacing={4} w="full">
                {histories.map((history) => {
                    return (<Checkbox
                        size="lg"
                        spacing="1rem"
                        colorScheme="primary"
                        key={history.value}
                        isChecked={optionsStore.history.findIndex((el) => el.value === history.value) > -1}
                        onChange={(e) => {
                            changeHistory(history.value, e);
                        }}
                    >
                        <Text fontSize="14px">{history.name}</Text>
                    </Checkbox>);
                })}
            </SimpleGrid>
        </MenuBlock>

        <Box w={'full'} justifyContent={'center'} marginTop={3}
             display={isOpen === true ? 'none' : 'flex'}>
            <Button fontSize='sm' variant='plainRed'
                    onClick={onToggle}>
                {t('texts:search.search-area.show-more-filters')}</Button>
        </Box>

        <Fade in={isOpen} w='full'>
            <VStack spacing={4} display={isOpen === true ? 'flex' : 'none'} px={0} mx={0}>
                <MenuBlock name={t("texts:search.search-area.additional-parameters")}>
                    <SimpleGrid columns={1} spacing={4} w="full">
                        <SelectFieldSimple2
                            label={t("texts:sell.characteristics.transmission")}
                            options={transmissions}
                            fieldFromStore={transmission}
                            field="transmission"
                            handler={changeTransmission}
                        />
                        <HStack>
                            <SelectFieldSimple
                                fieldFromStore={mileage}
                                field="start-mileage"
                                handler={changeMileageStart}
                                options={startMileages}
                                label={t("texts:common.mileage-from")}
                            />
                            <SelectFieldSimple
                                fieldFromStore={mileage}
                                field="end-mileage"
                                handler={changeMileageEnd}
                                options={endMileages}
                                label={t("texts:common.mileage-to")}
                            />
                        </HStack>
                        <SelectFieldSimple2
                            fieldFromStore={optionsStore.drivetrain}
                            field="drivetrain"
                            label={t("texts:vehicle-information.drivetrain")}
                            options={drivetrains}
                            handler={changeDrivetrain}
                        />
                        <HStack>
                            <SelectFieldSimple
                                fieldFromStore={power}
                                field="start-power"
                                handler={changePowerStart}
                                options={startPowers}
                                label={t("texts:common.power-from")}
                            />
                            <SelectFieldSimple
                                fieldFromStore={power}
                                field="end-power"
                                handler={changePowerEnd}
                                options={endPowers}
                                label={t("texts:common.power-to")}
                            />
                        </HStack>
                        <HStack>
                            <SelectFieldSimple2
                                fieldFromStore={optionsStore.country}
                                field="countries"
                                label={t("texts:search.search-area.country")}
                                options={countries}
                                handler={changeCountry}
                            />
                            <SelectFieldSimple2
                                fieldFromStore={optionsStore.city}
                                field="cities"
                                label={t("texts:search.search-area.city")}
                                options={cities}
                                handler={changeCity}
                            />
                        </HStack>
                    </SimpleGrid>
                </MenuBlock>

                <MenuBlock name={t("texts:search.search-area.features")}>
                    <SimpleGrid columns={2} rows={4} spacing={4} w="full">
                        {/*{console.log("features in SearchFilter", features)}*/}
                        {features.map((feature) => {
                            return (<Checkbox
                                size="lg"
                                spacing="1rem"
                                colorScheme="primary"
                                key={feature.value}
                                isChecked={
                                    optionsStore.features.length > 0 &&
                                    optionsStore.features.findIndex((el) => el.value === feature.value) > -1}
                                onChange={(e) => changeFeatures(feature.value, e)}
                            >
                                <Text fontSize="14px">{feature.name}</Text>
                            </Checkbox>);
                        })}
                    </SimpleGrid>
                </MenuBlock>

                <MenuBlock name={t("texts:search.search-area.color")}>
                    <SimpleGrid rows={2} columns={8} spacing={4} w="full">
                        {/*{console.log("colors in SearchFilter", colors)}*/}
                        {colors.map((color) => (<FilterCheckbox
                            size="lg"
                            key={color.value}
                            color={color.value}
                            isChecked={
                                optionsStore.color.length > 0 &&
                                optionsStore.color.findIndex((el) => el.value === color.value) > -1}
                            onChange={e => changeColor(color.value, e)}
                            variant="colorFilter"
                        />))}
                    </SimpleGrid>
                </MenuBlock>

                <Box w={'full'} textAlign='center' marginTop={2} marginBottom={1}>
                    <Button fontSize='sm' variant='plainRed'
                            onClick={() => {
                                onToggle();
                                window.scrollTo(0, 0);
                            }}>{t('texts:search.search-area.show-less-filters')}
                    </Button>
                </Box>
            </VStack>
        </Fade>

    </>);
};

export const MenuBlock = ({name, children}) => (<Card w="full" variant="filterCard">
    <CardHeader>{name}</CardHeader>
    <CardBody as={VStack} spacing={6}>
        {children}
    </CardBody>
</Card>);