import {Text, VStack} from '@chakra-ui/react';
import {SmallCards} from './SmallCards';
import {useTranslation} from "react-i18next";

export const PopularTypes = () => {

    const {t} = useTranslation(['texts']);

    return <VStack align="start" spacing={{base: 4, lg: 6}}
                   // mt={[3, 5]}
                   w='full' id='popular-types-stack'>
        <Text as="h3" textStyle="h2" textTransform="uppercase">
            {t('texts:home.popular-vehicle-types')}
        </Text>
        <SmallCards/>
    </VStack>
}

/* mt={5} because of scroll of popular models  */