import React, {useEffect, useState} from 'react';
import {
    Badge,
    Box,
    Button,
    Card,
    Flex,
    Grid,
    GridItem,
    Heading, Spacer, Table, Tbody, Td,
    Text, Tr,
    useDisclosure,
    VStack,
    Wrap
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {AzureCharacteristicsRESTConnector} from "../../integration/tables/AzureCharacteristicsRESTConnector";
import {
    getTitleFromDictionary,
    processCharacteristicsValuesByLanguage,
    processUnitsByLanguage
} from "../../integration/i18n/languageUtils";
import {capitalize} from "lodash";
import {RiDashboard3Line, RiFileTextLine, RiSettings3Line, RiTimeLine} from "react-icons/ri";
import {AskQuestionDrawer} from "../Modals/Car/AskQuestionDrawer";
import {badgeColorScheme} from "../Badge/badgeColorScheme";
import {BiCommentDetail} from "react-icons/bi";

export const CarMetadataArea2 = ({car}) => {


    const {t, i18n} = useTranslation(["texts", "data-for-ui"]);

    // const possibleHistoryValues =
    const history_d = t("data-for-ui:history", {returnObjects: true});
    const features_d = t("data-for-ui:features", {returnObjects: true});
    const countries_d = t('countries:countries', {returnObjects: true});
    const fuels_d = t("extended-dictionary:fuels", {returnObjects: true});
    const drivetrains_d = t("extended-dictionary:drivetrains", {returnObjects: true});

    // const colorSchemes = ["green", "purple", "blue", "orange"];
    // console.log('history_d', history_d);


    const characteristicsRESTConnector = new AzureCharacteristicsRESTConnector();
    const [characteristics, setCharacteristics] = useState(null);

    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [fuel, setFuel] = useState('');
    const [drivetrain, setDrivetrain] = useState('');

    const units_d = t('characteristics:units', {returnObjects: true});
    const values_d = t('characteristics:values', {returnObjects: true});
    const suspension_d = t('characteristics:suspension', {returnObjects: true});

    const [activeModal, setActiveModal] = useState(null);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const ASK_QUESTION = 'AskQuestionModal';
    const SELLER_INFO = 'SellerInfoModal';


    useEffect(() => {
        console.log('CarMetadataArea useEffect', car);

        const fetchCharacteristics = async () => {
            const data = await characteristicsRESTConnector.retrieveCharacteristicsByLanguage(car.characteristicID, i18n.language);
            console.log('CarMetadataArea useEffect fetchCharacteristics', data);
            processUnitsByLanguage(data, units_d);
            processCharacteristicsValuesByLanguage(data, values_d, suspension_d);

            setCharacteristics(data);
        }

        if (car && car.characteristicID) {
            console.log('CarMetadataArea useEffect', car.characteristicID);
            fetchCharacteristics();
        }

        if (car.country) {
            setCountry(countries_d[capitalize(car.country)].title);
            setCity(countries_d[capitalize(car.country)].cities[capitalize(car.city)].title);
            setFuel(getTitleFromDictionary(car.fuel, fuels_d));
            setDrivetrain(getTitleFromDictionary(car.drivetrain, drivetrains_d));
        }

    }, [car]);

    const [showAllCharacteristics, setShowAllCharacteristics] = useState(false);
    // only show 10 characteristics, the rest shown on click of a button
    const characteristicsToShow = characteristics && !showAllCharacteristics ?
        characteristics.slice(0, 5) : characteristics;


    return <Box my={4} id='car-metadata-area-2-box'>
        <Grid py={5}
              templateColumns={{base: "1fr", md: "7fr 3fr"}}
            // bg={'gray.100'}
              gap={6}
        >
            <GridItem
                // bg='yellow.200'
                order={{base: 1, md: 2}}
                minH={{base: '200px', md: '480px'}}>
                <Box rounded='lg' p={4} bg='neutral.25'>
                    <Card p={6} boxShadow="md" rounded='lg'>
                        <VStack align="start" spacing={4}>
                            <Box pb={0}>
                                <Heading size="md" mb={1} fontWeight='600'>Цена</Heading>
                                <Text fontSize="24" fontWeight="bold">
                                    {car.price && car.price.toLocaleString('fr-fr')} €
                                </Text>
                                <Text fontSize="md" color="gray.500">вкл. НДС 20%</Text>
                            </Box>


                            {car.history && car.history.length > 0 && <Wrap direction="row"
                                                                            spacing={3}
                                                                            align="center">
                                {car.history && car.history.map((key, index) => (
                                    <Badge key={index}
                                           colorScheme={badgeColorScheme[key]}>
                                        {history_d[key].title}
                                    </Badge>))
                                }
                            </Wrap>}
                        </VStack>
                    </Card>
                    <VStack w='full' gap={4} mt={4}>
                        <Button colorScheme="white"
                                size="lg"
                                w='full'
                                variant="outlineRed"
                                border='none'
                                boxShadow={'md'}
                                onClick={() => setActiveModal(ASK_QUESTION)}
                        >
                            {t('texts:vehicle-information.seller-info.contact-seller')}
                        </Button>
                    </VStack>
                </Box>
            </GridItem>

            <GridItem
                // bg='red.200'
                order={{base: 2, md: 1}}
                colSpan={1} minH={{base: '200px', md: '480px'}}>
                <Heading size="md" mb={6} fontWeight='600'>
                    {t('texts:vehicle-information.vehicle-information')}
                </Heading>
                <Heading size="sm" mb={4} fontWeight='bold'>
                    <Flex alignItems='center'>
                        <RiFileTextLine style={{marginRight: '8px', width: '24px', height: '24px'}}/>
                        <Text>{t('texts:sell.main-characteristics')}</Text>
                    </Flex>
                </Heading>
                <VStack spacing={4} align="start">
                    <Table variant="simple" size="md" w="full">
                        <Tbody>
                            <Tr>
                                <Td w='50%'>{t('sell.characteristics.make')}</Td>
                                <Td w='50%'>{capitalize(car.PartitionKey)}</Td>
                            </Tr>
                            <Tr>
                                <Td w='50%'>{t('sell.characteristics.model')}</Td>
                                <Td w='50%'>{capitalize(car.model)}</Td>
                            </Tr>
                            <Tr>
                                <Td w='50%'>{t('sell.characteristics.modification')}</Td>
                                <Td w='50%'>{capitalize(car.modification)}</Td>
                            </Tr>
                            <Tr>
                                <Td w='50%'>{t('sell.characteristics.mileage')}</Td>
                                <Td w='50%'>{car.mileage}</Td>
                            </Tr>
                            <Tr>
                                <Td w='50%'>{t('sell.characteristics.year')}</Td>
                                <Td w='50%'>{car.year}</Td>
                            </Tr>
                            <Tr>
                                <Td w='50%'>{t('vehicle-information.location')}</Td>
                                <Td w='50%'>{country}{', '}{city}</Td>
                            </Tr>
                            <Tr>
                                <Td w='50%'>{t('sell.characteristics.fuel')}</Td>
                                <Td w='50%'>{fuel}</Td>
                            </Tr>
                            <Tr>
                                <Td w='50%'>{t('sell.characteristics.power')}</Td>
                                <Td w='50%'>{car.power}</Td>
                            </Tr>
                            <Tr>
                                <Td w='50%'>{t('sell.characteristics.drivetrain')}</Td>
                                <Td w='50%'>{drivetrain}</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </VStack>
                <Spacer py={4}/>
                <Heading size="sm" mb={4} fontWeight='bold'>
                    <Flex alignItems='center'>
                        <RiTimeLine style={{marginRight: '8px', width: '24px', height: '24px'}}/>
                        <Text>{t('search.search-area.history')}</Text>
                    </Flex>
                </Heading>
                <VStack spacing={4} align="start">
                    <Table variant="simple" size="md" w="full">
                        <Tbody>
                            {Object.keys(history_d).map((key, index) => (
                                <Tr key={index}>
                                    <Td w='50%'>{history_d[key].title}</Td>
                                    <Td w='50%'>{car.history && car.history.includes(key) ? 'Yes' : '-'}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </VStack>

                {car.comment && <Box>
                    <Spacer py={4}/>
                    <Heading size="sm" mb={4} fontWeight='bold'>
                        <Flex alignItems='center'>
                            <BiCommentDetail style={{marginRight: '8px', width: '24px', height: '24px'}}/>
                            <Text>{t('vehicle-information.comment')}</Text>
                        </Flex>
                        <Wrap mt={4} px={6}>
                            <Text fontWeight='normal'>{car.comment}</Text>
                        </Wrap>
                    </Heading>
                </Box>
                }
                {car.options && car.options.length > 0 && <Box>
                    <Spacer py={4}/>
                    <Heading size="sm" mb={4} fontWeight='bold'>
                        <Flex alignItems='center'>
                            <RiSettings3Line style={{marginRight: '8px', width: '24px', height: '24px'}}/>
                            <Text>{t('vehicle-information.options')}</Text>
                        </Flex>
                    </Heading>
                    <VStack spacing={4} align="start">
                        <Table variant="simple" size="md" w="full">
                            <Tbody>
                                {car.options.map((key, index) => (
                                    <Tr key={index}>
                                        <Td w='50%'>{features_d[key].title}</Td>
                                        <Td w='50%'>Yes</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </VStack></Box>
                }
                {characteristics && (
                    <Box>
                        <Spacer py={4}/>
                        <Heading size="sm" mb={4} fontWeight='bold'>
                            <Flex alignItems="center">
                                <RiDashboard3Line style={{marginRight: '8px', width: '24px', height: '24px'}}/>
                                <Text>{t('sell.full-technical-characteristics')}</Text>
                            </Flex>
                        </Heading>
                        <Table variant="simple" size="md" w="full">
                            <Tbody>
                                {characteristicsToShow.map(el => (
                                    <Tr key={el.id}>
                                        <Td width="50%">{el.name}</Td>
                                        <Td width="50%">
                                            <span>{el.value}</span>{' '}
                                            {el.unit !== 'N/A' && el.unit}
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                        <Spacer py={3}/>
                        <Flex w='full' justifyContent='center'>
                            <Button
                                variant='plainRed'
                                onClick={() => setShowAllCharacteristics(!showAllCharacteristics)}>
                                {!showAllCharacteristics
                                    ? t('car.show-all-characteristics')
                                    : t('car.show-less-characteristics')
                                }
                            </Button>
                        </Flex>
                    </Box>
                )
                }
            </GridItem>
        </Grid>

        <AskQuestionDrawer car={car}
                           isOpen={activeModal === ASK_QUESTION}
                           onClose={() => {
                               onClose();
                               setActiveModal('');
                           }}/>
    </Box>;
}