import {Text, VStack} from '@chakra-ui/react';
import {PopularByCountryList} from "./PopularByCountryList";
import {useTranslation} from "react-i18next";

export const PopularByCountry = () => {

    const {t} = useTranslation(['texts']);

    return <VStack align="start" spacing={{base: 4, md: 4}} w="full">
        <Text as="h3" textStyle="h2" textTransform="uppercase">
            {t('texts:home.by-country-and-city')}
        </Text>
        <PopularByCountryList />
    </VStack>
}