const checkIsAliasValid = (alias) => alias.length >= 3 && alias.length <= 15;

const checkIsPhoneValid = (phone) => {
    const regExp = /[a-zA-Zа-яА-Я]/i;
    return phone.length >= 5 && phone.length <= 18 && !regExp.test(phone);
};

const checkRegistrationNumberValid = (regNumber) => {
    const regExp = /[a-zA-Zа-яА-Я]/i;
    return regNumber.length >= 5 && regNumber.length <= 20 && !regExp.test(regNumber);
};

const checkIsCompanyAddressValid = (address) => address.length >= 3 && address.length <= 80;


const checkIsURLValid = url => {
    const urlRegex = new RegExp('[-a-zA-Z0-9@:%._\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\+.~#?&/=]*)');
    return url.length === 0 || urlRegex.test(url);
}

export {
    checkIsAliasValid, checkIsPhoneValid, checkRegistrationNumberValid, checkIsCompanyAddressValid, checkIsURLValid
}