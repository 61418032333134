import {defineStyleConfig} from '@chakra-ui/react';

export const switchTheme = defineStyleConfig({

    baseStyle: {
        track: {
            _checked: {
                bg: 'primaryGreen.700', // Set the checked state background color
            },
        },
    },
});
