import {useTranslation} from "react-i18next";
import {
    Box,
    Button,
    Flex,
    Slider,
    SliderFilledTrack,
    SliderMark,
    SliderThumb,
    SliderTrack, Spacer,
    Text
} from "@chakra-ui/react";
import {getPrice} from "../../../MyOffers/utils/offersHelper";
import {MdGraphicEq} from "react-icons/md";
import React from "react";

export const UpgradeModalContent = ({
                                        car, upgradePeriod, setUpgradePeriod, isPublished,
                                        setActivateArea
                                    }) => {

    const {t} = useTranslation();

    const marks = {
        7: '1w',
        14: '2w',
        21: '3w',
        28: '4w',
    };

    return <Box>
        <Flex direction={'column'} spacing={1} gap={4}>
            <Text fontSize='sm'>{t('sell.car-ad-card.upgrade-modal.want-to-sell-faster')}</Text>
            <Text
                fontSize='sm'>{t('sell.car-ad-card.upgrade-modal.choose-promotional-period')}{' '}{car.originalVIN}</Text>
            <Text fontSize='sm'>{t('sell.car-ad-card.upgrade-modal.upgrade-period')} {upgradePeriod}
                {t('sell.car-ad-card.upgrade-modal.days')}</Text>
            <Text fontSize='sm'>{t('common.price')}: {getPrice(upgradePeriod)}</Text>
        </Flex>
        <Box w='full' my={4}>
            <Slider w='full' aria-label='slider-ex-4'
                    size={'lg'}
                    min={0}
                    max={28}
                    step={7}
                    defaultValue={7}
                    value={upgradePeriod}
                    onChange={setUpgradePeriod}
            >
                {Object.keys(marks).map((mark, index) =>
                    <SliderMark
                        value={mark}
                        key={`mark-${index}`}
                        mt='3'
                        ml='-2'
                        fontSize='sm'>{mark}</SliderMark>
                )}
                <SliderTrack bg='red.100'>
                    <SliderFilledTrack bg='tomato'/>
                </SliderTrack>
                <SliderThumb boxSize={6}>
                    <Box color='tomato' as={MdGraphicEq}/>
                </SliderThumb>
            </Slider>
        </Box>
        <Flex w={'full'} pt={4}>
            <Button variant='outline'
                    mt={1} spacing={4}
                    isDisabled={!isPublished}
                    onClick={() => {
                        console.log('upgradePeriod', upgradePeriod);
                        setActivateArea('history');
                        // onClose();
                    }}>
                {t('sell.car-ad-card.upgrade-modal.history')}
            </Button>
            <Spacer/>
            <Button variant='outline'
                    mt={1} spacing={4}
                    isDisabled={!isPublished || upgradePeriod === 0}
                    onClick={() => {
                        console.log('upgradePeriod', upgradePeriod);
                        setActivateArea('payment');
                        // onClose();
                    }}>
                {t('sell.car-ad-card.upgrade-modal.pay')}
            </Button>
        </Flex>
    </Box>
}
