import {
    HStack,
    Button,
    IconButton,
    Stack,
    Text,
    Card,
    CardBody,
    VStack,
    Tag,
    Box,
    useDisclosure,
    GridItem,
    Grid,
    Wrap,
    Badge
} from "@chakra-ui/react";
import {Verify16} from "../../icons/16";
import {useTranslation} from "react-i18next";
import {
    getCityFromDictionary,
    getCountryFromDictionary,
    getTitleFromDictionary,
} from "../../integration/i18n/languageUtils";
import {getCompanyVatByCountry} from "../../data/priceUtils";
import {FetchableImage} from "../FetchableImage/FetchableImage";
import {capitalize} from "lodash";
import {useLocation, useNavigate} from "react-router-dom";
import {AskQuestionDrawer} from "../Modals/Car/AskQuestionDrawer";
import React, {useEffect} from "react";
import {TbCalendarFilled, TbCarTurbine, TbManualGearbox, TbMapPin, TbRoad} from "react-icons/tb";
import {getContainerIDfromPath} from "../../integration/tables/sellerUtils";
import {AzureSellersRESTConnector} from "../../integration/tables/AzureSellersRESTConnector";
import {updateFavouriteInLocalStorage} from "../Favourite/favouriteUtils";
import {badgeColorScheme} from "../Badge/badgeColorScheme";
import {PictureCounterBox} from "../PictureCounterBox/PictureCounterBox";
import {IoHeart, IoHeartOutline} from "react-icons/io5";
import {splitIntoTokens} from "./textUtils";


const DescriptionItem = ({title, value, unit, icon}) => (
    <HStack fontSize="sm" align="start" lineHeight={4} spacing={3}>
        {icon ? (
            icon
        ) : (
            <Text color="neutral.750">{title}:</Text>
        )}
        <Text>
            {value} {unit && unit.toLocaleString("Ru-ru")}
        </Text>
    </HStack>
);

const VerifyLabel = ({text}) => (
    <HStack mb={1} spacing={0.5} w="full" justify={{md: "end"}}>
        <Verify16/>
        <Text fontSize="sm" lineHeight={4} whiteSpace="nowrap">
            {text}
        </Text>
    </HStack>
);

export const SearchResultListItem = ({resultItem, isPublished}) => {

    const item = resultItem;

    const page = useLocation(); //to differentiate between Search and Seller areas

    console.log('SearchResultListItem item', page.pathname);

    const {t} = useTranslation(["texts", "data-for-ui"]);
    const navigate = useNavigate();

    const fuel_d = t("extended-dictionary:fuels", {returnObjects: true});
    const transmission_d = t("extended-dictionary:transmissions", {
        returnObjects: true,
    });
    const locations_d = t("locations:countries", {returnObjects: true});
    const history_d = t("data-for-ui:history", {returnObjects: true});

    const fuel = getTitleFromDictionary(item.fuel, fuel_d);
    const engine = `${fuel}, ${item.power}`;
    const transmission = getTitleFromDictionary(
        item.transmission,
        transmission_d
    );
    const location =
        getCityFromDictionary(item, locations_d) +
        ", " +
        getCountryFromDictionary(item, locations_d);


    const vat = item.vatIncluded === 'true' ?
        `${t("vehicle-information.vat")} ${getCompanyVatByCountry(item.country)}%` :
        `${t("vehicle-information.vat")} 0%`

    // console.log('SearchResultListItem vat', item.vatIncluded, vat);

    const {onOpen, onClose, isOpen} = useDisclosure();

    const [sellerInfo, setSellerInfo] = React.useState(null);

    const itemKey = item.RowKey || item.vin;

    const favouriteHandler = RowKey => {
        const isAdded = updateFavouriteInLocalStorage(RowKey);
        setIsFavourite(isAdded);
    }

    useEffect(() => {
        console.log('SearchResultItem useEffect');

        const retrieveSellerInfo = async () => {
            const sellerID = getContainerIDfromPath(item);
            // console.log('SellerInfoContent sellerID:', sellerID, typeof sellerID);
            const sellerInfo = await new AzureSellersRESTConnector().retrieveSellerInfo(sellerID);
            // console.log('SellerInfoContent sellerInfo:', sellerInfo);
            setSellerInfo(sellerInfo); // Set the retrieved sellerInfo
        }
        // console.log('SearchResultItem useEffect; retrieve Seller Info is opened from SearchResults');
        retrieveSellerInfo();
    }, []);

    const [isFavourite, setIsFavourite] = React.useState(false);

    useEffect(() => { //triggered once
        const storedFavourites = JSON.parse(localStorage.getItem('favourites')) || [];
        setIsFavourite(storedFavourites.includes(item.RowKey));
    }, []);

    return (
        <Card
            w="full"
            variant="outline"
            _hover={{borderColor: "neutral.600"}}
            role="group"
            display="flex"
            flexDir="row"
            gap={6}
            p={0}
            overflow="hidden"
            // h={{md: 268}}
            h={{md: '288px'}}
        >
            <Stack flexDir={{base: "column", sm: "row"}} w="full" spacing={0}
                   id='card-stack'
            >
                <Box h="full" w={{base: "full", sm: 340, md: 338}} pos="relative"
                     cursor='pointer'
                     onClick={() => {
                         // console.log('SearchResultListItem', item);
                         navigate(`/cars/${itemKey}`); // Search:RowKey, Sell:vin
                     }}

                >
                    {/*<Gallery images={item.blobPaths} /> todo revert back */}
                    <FetchableImage blobPath={item.blobPaths[0]}
                                    height={'100%'}
                    />
                    <PictureCounterBox totalImages={item.blobPaths.length}/>
                </Box>
                <CardBody px={{base: 4, sm: 2, md: 4}} py={4} pl={{lg: 6}} h="full"
                >

                    <Stack spacing={{base: 3, sm: 4}} h="full"
                           id='search-result-list-item-data-stack'
                    >

                        {/** Top row: heading and isFavourite */}
                        <HStack spacing={4} w="full" align="start" justify="space-between">
                            <VStack align="start" w="full" spacing={0}>
                                <Text fontSize="xl" fontWeight={600} lineHeight={6}>
                                    {capitalize(item.PartitionKey || item.make)} {item.model}
                                </Text>
                                <Text fontSize="sm" textTransform='uppercase' lineHeight={6}
                                      color="#676767">
                                    {splitIntoTokens(item.modification, 43)}
                                </Text>
                            </VStack>
                            {/** page is used to identify if component in Seller area*/}
                            {
                                (page.pathname === '/' || page.pathname === '/favourites') ?
                                    <VStack align="start" spacing={0}>
                                        <IconButton
                                            marginTop='1px'
                                            variant="plain"
                                            aria-label="Add to favorites"
                                            flexShrink={0}
                                            icon={isFavourite
                                                ? <IoHeart size={'24px'} fill='#f44545'/>
                                                : <IoHeartOutline size={'24px'} fill='#f44545'/>}
                                            size="xs"
                                            onClick={() => favouriteHandler(resultItem.RowKey)}
                                        />
                                    </VStack> :
                                    <Tag bg={isPublished ? 'primaryGreen.600' : 'neutral.100'} justifyContent='center'>
                                        {isPublished ? 'Active' : 'Inactive'}
                                    </Tag>
                            }
                        </HStack>
                        {/** Specs and history */}
                        <Stack
                            id='description-items-stack'
                            // mt={{base: 2, sm: 0}}
                            alignItems='center'
                            flexDir={{base: "column", md: "row"}}
                            // spacing={4}
                            align="start"
                            h="full"
                        >
                            <Grid w="full"
                                  id='grid-description-items-parent'
                                  align="start"
                                  gap={[3, 0]}
                                  templateColumns={{base: '1fr', md: '1fr 1fr'}}>


                                <GridItem id='griditem-description-items'
                                          pb={{base: 1, md: 0}}
                                >
                                    <Grid templateColumns={{base: '1fr 1fr', md: '1fr'}}
                                          id='grid-description-items'
                                          gap={{base: 2, sm: 2}}>
                                        <GridItem>
                                            <DescriptionItem
                                                // title={t("vehicle-information.year")}
                                                icon={<TbCalendarFilled size='1rem' color='grey'/>}
                                                value={item.year}
                                            />
                                        </GridItem>
                                        <GridItem>
                                            <DescriptionItem
                                                icon={<TbRoad size='1rem' color='grey'/>}
                                                // title={t("search.search-area.mileage")}
                                                value={item.mileage.toLocaleString("RU-ru")}
                                                unit={t("search.car-card.km")}
                                            />
                                        </GridItem>
                                        <GridItem>
                                            <DescriptionItem
                                                icon={<TbCarTurbine size='1rem' color='grey'/>}
                                                // title={t("vehicle-information.engine")}
                                                value={engine}
                                                unit={t("sell.characteristics.hp")}
                                            />
                                        </GridItem>
                                        <GridItem>
                                            <DescriptionItem
                                                // title={t("vehicle-information.transmission")}
                                                icon={<TbManualGearbox size='1rem' color='grey'/>}
                                                value={transmission}
                                            />
                                        </GridItem>
                                        <GridItem colSpan={{base: 2, md: 1}}>
                                            <DescriptionItem
                                                // title={t("vehicle-information.location")}
                                                icon={<TbMapPin size='1rem' color='grey'/>}
                                                value={location}
                                            />
                                        </GridItem>
                                    </Grid>
                                </GridItem>

                                {item.history && item.history.length > 0 &&
                                    <GridItem alignContent='end' id='grid-item-history'>
                                        <Wrap
                                            my={{base: '3px', md: 0}}
                                            spacing={2}
                                            align={{base: 'start', md: 'end'}}
                                            justify={{base: 'start', md: 'end'}}
                                        >
                                            {item.history.map((value, index) => {
                                                const historyItem = getTitleFromDictionary(
                                                    value,
                                                    history_d
                                                );
                                                return <Badge key={index}
                                                              colorScheme={badgeColorScheme[value]}>
                                                    {historyItem}
                                                </Badge>
                                                // <Tag key={index} m={1}>
                                                //     {historyItem}
                                                // </Tag>
                                            })}
                                        </Wrap>
                                    </GridItem>
                                }
                            </Grid>
                        </Stack>
                        {/** Price and contact seller */}
                        <Grid
                            id='price-contact-seller-stack'

                            pt={[0, 0]}
                            templateColumns={{base: '1fr', md: '1fr 1fr'}}
                            alignItems={{base: 'start', md: 'end'}}
                            justifyContent={'space-between'}
                            // justifyContent={{base: 'space-between', md: 'space-between'}}
                            gap={2}
                            h={["full", "min-content"]}
                            w="full"
                        >
                            <GridItem
                                id='price-block'
                                order={{base: 1, md: 2}}
                                // paddingTop={['3px', '0']}
                                paddingBottom={['1px', '0']}
                            >
                                <VStack align={{base: 'start', md: 'end'}} spacing={0}>
                                    <Box
                                        hideBelow='md'
                                        w='full'>{sellerInfo?.sellersRank?.includes('verified') ?
                                        <VerifyLabel
                                            text={t('vehicle-information.seller-info.verified-seller')}
                                        /> : <Box h={4} w={'full'} mb={1}/>
                                    }
                                    </Box>
                                    <Text fontSize="1.5rem" lineHeight="1.75rem" fontWeight={600}>
                                        {item.price.toLocaleString("ru-RU")} €
                                    </Text>
                                    <HStack justifyContent={['space-between', 'end']} w='full'
                                    >
                                        <Box>
                                            {vat && (
                                                <Text textStyle="xs" color="neutral.750">
                                                    {vat}
                                                </Text>
                                            )}
                                        </Box>

                                        >{sellerInfo?.sellersRank?.includes('verified') &&
                                        <Box
                                            justifyContent='end'
                                            hideFrom='md'>
                                            <VerifyLabel
                                                text={t('vehicle-information.seller-info.verified-seller')}
                                            />
                                        </Box>
                                    }
                                    </HStack>
                                </VStack>
                            </GridItem>
                            <GridItem
                                order={{base: 2, md: 1}}
                                alignSelf={{base: 'stretch', md: 'end'}}
                                // justifySelf={{md: 'end'}}
                            >
                                {(page.pathname === '/' || page.pathname === '/favourites') &&
                                    <Button //page.pathname is used to identify if component in Search area
                                        mt={2}
                                        w={{base: "full", md: "auto"}}
                                        variant="outlinePrimary"
                                        boxShadow={['1px 1px 2px 1px #e8e8e8', 'none']}
                                        onClick={onOpen}
                                    >
                                        {t("vehicle-information.seller-info.contact-seller")}
                                    </Button>}
                            </GridItem>
                        </Grid>
                    </Stack>
                </CardBody>
            </Stack>

            <AskQuestionDrawer car={item}
                               isOpen={isOpen}
                               onClose={onClose}
                               sellerInfo={sellerInfo}
            />
        </Card>
    );
};