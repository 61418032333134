import {extendTheme, withDefaultColorScheme} from "@chakra-ui/react";
import {buttonTheme} from "./buttonsTheme";
import {textStyles} from "./textStyles";
import {colors} from "./colorsTheme";
import {selectTheme} from "./selectTheme";
import {formLabelTheme} from "./formLabelTheme";
import {fontSizes} from "./fontSizes";
import {borderRadius} from "./borderRadius";
import {breakpoints} from "./breakpoints";
import {headingTheme} from "./headingTheme";
import {shadows} from "./shadows";
import {linkTheme} from "./linkTheme";
import {cardTheme} from "./cardTheme";
import {checkboxTheme} from "./checkboxTheme";
import {tagTheme} from "./tagTheme";
import {drawerTheme} from "./drawerTheme";
import {tableTheme} from "./tableTheme";
import {inputTheme} from "./inputTheme";
import {textareaTheme} from "./textareaTheme";
import {switchTheme} from "./switchTheme";
import {badgeTheme} from "./badgeTheme";

const config = {
    initialColorMode: "light",
    useSystemColorMode: false,
};

const globalStyles = {
    global: () => ({
        html: {
            minHeight: "100%",
            minWidth: "280px",
        },
        body: {
            color: "neutral.900",
            textStyle: "base",
        },
        ".pagination": {
            alignItems: "center",
            display: "flex",
            listStyle: "none",
            justifyContent: "space-between",
            width: "full", //width controlled by wrapping container
        },
        ".paginationBtn a": {
            alignItems: "center",
            color: "neutral.750",
            display: "flex",
            justifyContent: "center",
            h: 10,
            px: 4,
            _hover: {
                color: "neutral.900",
            },
        },
        ".paginationBtnActive a": {
            border: "1px solid",
            borderColor: "primary.600",
            rounded: "md",
            color: "neutral.900",
            h: 10,
            w: 10,
        },
        ".paginationNext a, .paginationPrev a": {
            color: "neutral.700",
            h: 8,
            w: 8,
            _hover: {
                color: "primary.600",
            },
        },
        ".paginationNext.disabled a, .paginationPrev.disabled a": {
            color: "neutral.500",
        },
    }),
};

const theme = extendTheme(
    {
        config,
        breakpoints,
        fontSizes,
        fonts: {
            heading: `'IBM Plex Sans', sans-serif`,
            body: `'IBM Plex Sans', sans-serif`,
        },
        colors,
        ...borderRadius,
        components: {
            Button: buttonTheme,
            Select: selectTheme,
            FormLabel: formLabelTheme,
            Heading: headingTheme,
            Link: linkTheme,
            Card: cardTheme,
            Checkbox: checkboxTheme,
            Tag: tagTheme,
            Drawer: drawerTheme,
            Table: tableTheme,
            Input: inputTheme,
            Textarea: textareaTheme,
            Switch: switchTheme,
            Badge: badgeTheme
        },
        textStyles,
        shadows,
        styles: globalStyles,
    },
    withDefaultColorScheme({
        colorScheme: "brand",
        components: ["Button", "Select"],
    })
);

export {theme};
