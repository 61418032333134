import React, {useEffect, useState} from 'react';
import {Box, Container, IconButton, Link, VStack} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {createSearchString, generateSort, generateTop} from "../integration/search/searchConnectorUtils";
import {initialOptions, initialSearch} from "../state/initialstate";
import {fetchResults} from "../integration/search/AzureSearchConnector";
import {SearchResultListItem} from "../components/SearchResultListItem/SearchResultListItem";
import {PlaceholderImage} from "../components/PlaceholderImage/PlaceholderImage";
import {IoMdArrowBack} from "react-icons/io";
import {useTranslation} from "react-i18next";

export const Favourites = () => {
    const {t} = useTranslation(['texts']);

    const navigate = useNavigate();
    const [results, setResults] = useState([]);
    const [favourites, setFavourites] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const handleStorageChange = () => {
            const storedFavourites = localStorage.getItem('favourites');
            setFavourites(storedFavourites ? JSON.parse(storedFavourites) : []);
        };

        // Initialize favourites on mount
        handleStorageChange();

        // Add event listener for localStorage changes
        window.addEventListener('storage', handleStorageChange);

        // Clean up event listener on unmount
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    useEffect(() => {
        if (favourites.length === 0) {
            setResults([]); // Clear results if no favourites
            return;
        }

        const fetchFavourites = async () => {
            setIsLoading(true);
            const options = {RowKey: favourites.map(key => ({value: key, visible: true}))};
            const query = createSearchString(options);
            const top = generateTop(initialSearch.itemsPerPage);
            const sort = generateSort(initialSearch.sort, initialOptions.ignoreSponsored);
            const searchString = `${query}${top}${sort}`;

            console.log("Favourites searchString:", searchString);

            try {
                const fetchedResults = await fetchResults(searchString);
                setResults(fetchedResults);
            } catch (error) {
                console.error("Failed to fetch favourites:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchFavourites();
    }, [favourites]);

    return (
        <Container mt={{base: 1, md: 0}}
                   mb={{base: 4, md: 4}}
                   py={0}
                   id="favourites-container"
                   maxW='container.md'>
            <VStack w='full' spacing={{base: 2, md: 0}} gap={[0, 2]}>
                <Box w='full' mb={{
                    base: 1,
                    md: 0
                }}>
                    <IconButton aria-label={'Back'}
                                rounded='full'
                                icon={<IoMdArrowBack size='24'/>}
                                colorScheme='gray'
                                variant='ghost'
                                onClick={() => navigate(-1)}/>
                </Box>
                {isLoading ? (
                    <VStack w="full" spacing={6}>
                        {Array.from({length: favourites.length}, (_, index) => (
                            <Box key={index} w="full" height="268px" rounded="xl">
                                <PlaceholderImage rounded='xl'/>
                            </Box>
                        ))}
                    </VStack>
                ) : results.length === 0 ? (
                    <VStack w='full' minH='150px' justify='center' mb={6}>
                        <Box mt={4}>{t('favourites.your-area')}</Box>
                    </VStack>
                ) : (
                    <VStack w='full' align="start" spacing={6} id='search-results-stack' mb={4}>
                        {results.value.map((item) => (
                            <SearchResultListItem key={item.RowKey} resultItem={item}/>
                        ))}
                    </VStack>
                )}
            </VStack>
        </Container>
    );
};