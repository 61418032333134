import React, {useEffect, useState} from 'react';
import {Container, Flex, Stack, Box, Text, Heading} from "@chakra-ui/react";
import {FaHeart} from 'react-icons/fa';
import {useTranslation} from "react-i18next";
import {Sidebar} from "../Sidebar";

export const About = () => {
    const {t} = useTranslation(['support', 'texts']);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [active, setActive] = useState('about');

    return (
        <Container maxW="container.xl" my={[4, 9]}>
            <Stack direction={{base: 'column', md: 'row'}}
                   align='start'
                   spacing={[8, 12]}
                   w="full"
            >
                <Sidebar active={active} setActive={setActive}/>
                <Flex direction="column" maxW={{base: '100%', md: '60%'}}>
                    <Box mb={6}>
                        <Heading as="h2" size="md"
                                 textTransform='capitalize'
                                 mb={4}>{t('support:company.about.about-us')}</Heading>
                        <Text>{t('support:company.about.we-are-online-car-marketplace')}</Text>
                    </Box>
                    <Flex direction={'row'} mb={6} align="center" gap={2}>
                        <Text>
                            {t('company.about.we-love-cars')}{" "}
                            <span color="red">❤️</span>{" "}
                            {t('company.about.looking-through-buyer-eyes')}
                        </Text>
                        {/*<FaHeart color="red"/>*/}
                        <Text> </Text>
                    </Flex>
                    <Box mb={6}>
                        <Text>{t('company.about.best-tech-to-express-our-passion')}</Text>
                    </Box>
                    <Box mb={6}>
                        <Text>{t('company.about.our-vision')}</Text>
                    </Box>
                    <Box>
                        <Heading as="h2" size="md" mb={4}>
                            {t('company.about.company-details')}</Heading>
                        <Text>Caruniverse OÜ</Text>
                        <Text>16774206</Text>
                        <Text>Tartu mnt 55, Tallinn</Text>
                    </Box>
                </Flex>
            </Stack>
        </Container>
    );
};