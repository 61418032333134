import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Box, Text} from "@chakra-ui/react";
import {useProfileStore} from "../../../../state/store";

const PaymentConfirmation = ({car, isComplete}) => {

    const {t} = useTranslation(['texts']);
    const profileStore = useProfileStore();

    useEffect(() => {
        console.log('PaymentConfirmation triggered for vin', car.vin);
        profileStore.setNeedsRefresh(true);
    }, [isComplete]);

    return <Box id='payment-confirmation-div'>
        <Text fontWeight='bold' my={2}>
            {t('sell.car-ad-card.upgrade-modal.transaction-results')}</Text>
        <Text>{t('sell.car-ad-card.upgrade-modal.congratulations-message')}</Text>
    </Box>
}

export default PaymentConfirmation;