import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Box,
    Flex,
    HStack,
    VStack,
    Text,
    Link as ChakraLink,
    Skeleton,
    useToast,
    SimpleGrid, Spacer,
} from '@chakra-ui/react';
import {formatWebsiteURL, getContainerIDfromPath} from "../../../integration/tables/sellerUtils";
import {AzureSellersRESTConnector} from "../../../integration/tables/AzureSellersRESTConnector";
import {MdOutlineVerified} from "react-icons/md";

export const SellerInfoContent = (props) => {
    // console.log('SellerInfoContent props:', props);

    const {t} = useTranslation(['texts']);

    const juridicalStatus_d = t('data-for-ui:juridical-status', {returnObjects: true});
    const [showPhone, setShowPhone] = useState(false);
    const [sellerInfo, setSellerInfo] = useState(props.sellerInfo);

    const toast = useToast();

    useEffect(() => {
        // console.log('SellerInfoContent useEffect:', props);

        const retrieveSellerInfo = async () => {
            const sellerID = getContainerIDfromPath(props.car);
            // console.log('SellerInfoContent sellerID:', sellerID, typeof sellerID);
            const sellerInfo = await new AzureSellersRESTConnector().retrieveSellerInfo(sellerID);
            // console.log('SellerInfoContent sellerInfo:', sellerInfo);
            setSellerInfo(sellerInfo); // Set the retrieved sellerInfo
        }

        if (!props.sellerInfo) {
            console.log('SellerInfoContent is empty - retrieveSellerInfo opened from Car directly',
                props.sellerInfo);
            retrieveSellerInfo();
        }
    }, [props.sellerInfo]);

    const handleShowVerifiedToast = () => {
        toast({
            position: 'top',
            render: () => (
                <Box color="white" p={0} bg="blue.500" borderRadius="md">
                    <HStack>
                        <MdOutlineVerified/>
                        <VStack align="start" spacing={1}>
                            <Text>Verified by CarUniverse</Text>
                            <Text>
                                More about verification criteria{' '}
                                <ChakraLink href="/support/how-to-partner" textDecoration="underline">
                                    Here
                                </ChakraLink>
                            </Text>
                        </VStack>
                    </HStack>
                </Box>
            ),
        });
    };

    if (!sellerInfo) {
        return (
            <SimpleGrid columns={1} spacing={4} mt={4} p={4}>
                <Skeleton height="50px" width="full" startColor="neutral.50" endColor="neutral.200"/>
                <Skeleton height="50px" width="full" startColor="neutral.50" endColor="neutral.200"/>
                <Skeleton height="50px" width="full" startColor="neutral.50" endColor="neutral.200"/>
                <Skeleton height="50px" width="full" startColor="neutral.50" endColor="neutral.200"/>
            </SimpleGrid>
        );
    }

    return (
        <Box id="seller-info-panel" mt={4} px={0} style={props.style}>
            {/* Seller Title */}
            <Flex justify="space-between" align="center" borderBottom="1px solid #eee" py={3}>
                <Text>{t('texts:vehicle-information.seller-info.seller')}</Text>
                <HStack>
                    <Text fontWeight="bold" textTransform="capitalize">
                        {sellerInfo.name}
                    </Text>
                    {sellerInfo.sellersRank && sellerInfo.sellersRank.includes('verified') && (
                        <MdOutlineVerified color='blue'
                                           style={{
                                               userSelect: 'none',
                                               cursor: 'pointer',
                                               height: '16px',
                                           }} onClick={handleShowVerifiedToast}
                        />
                    )}
                </HStack>
            </Flex>

            {/* Phone */}
            <Flex justify="space-between" align="center" borderBottom="1px solid #eee" py={3}>
                <Text>{t('texts:vehicle-information.seller-info.phone')}</Text>
                <Box>
                    <Text fontWeight="bold" whiteSpace="nowrap">
                        {sellerInfo.phone}
                    </Text>

                </Box>
            </Flex>

            {/* Juridical status */}
            <Flex justify="space-between" align="center" borderBottom="1px solid #eee" py={3}>
                <Text>{t('texts:vehicle-information.seller-info.juridicalStatus')}</Text>
                <Text fontWeight="bold" textTransform="capitalize">
                    {sellerInfo.type && juridicalStatus_d[sellerInfo.type.toLowerCase()].title}
                </Text>
            </Flex>

            {/* Reg. number */}
            {sellerInfo.type === 'company' && <Box>
                <Flex justify="space-between" align="center" borderBottom="1px solid #eee" py={3}>
                    <Text>{t('texts:vehicle-information.seller-info.registrationNumber')}</Text>
                    <Text fontWeight="bold" textTransform="capitalize">
                        {sellerInfo.registrationNumber}
                    </Text>
                </Flex>

                {/* Company address */}
                <Flex justify="space-between"
                      gap={'1.5rem'}
                      align="top" borderBottom="1px solid #eee" py={3}>
                    <Text style={{textWrap: 'nowrap'}}
                    >{t('texts:vehicle-information.seller-info.companyAddress')}</Text>
                    <Text fontWeight="bold" textTransform="capitalize">
                        {sellerInfo.companyAddress}
                    </Text>
                </Flex>

                {/* Website */}
                <Flex justify="space-between" mt={3} mb={1}>
                    <Text>{t('texts:vehicle-information.seller-info.website')}</Text>
                    {sellerInfo.website ? <ChakraLink fontWeight="bold"
                                                      whiteSpace="nowrap"
                                                      href={formatWebsiteURL(sellerInfo.website)}
                                                      isExternal
                        >
                            {sellerInfo.website}
                        </ChakraLink>
                        : <Text fontWeight="bold" textTransform="capitalize">-</Text>
                    }
                </Flex>

                {/* Seller logo */}
                {sellerInfo.logo ? <Flex justify="center" my={3}>
                        <ChakraLink href={formatWebsiteURL(sellerInfo.website)} isExternal>
                            <Box maxH="2rem" dangerouslySetInnerHTML={{__html: sellerInfo.logo}}/>
                        </ChakraLink>
                    </Flex>
                    : <Box h='24px' w='full'><Spacer my={2}/></Box>}
            </Box>}
            {sellerInfo.type !== 'company' && <Box h='24px' w='full'>
                <Spacer my={2}/>
            </Box>}
        </Box>
    );
};