import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    Flex,
    FormControl,
    FormErrorMessage,
    HStack,
    Input,
    ListItem,
    OrderedList,
    SimpleGrid,
    Text,
    Tag,
    TagLabel,
    VStack,
    Switch,
    FormHelperText,
    useDisclosure, ModalFooter, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody
} from '@chakra-ui/react';
import SelectFieldSimple2 from '../Search/SelectFieldSimple2';
import {
    useDictionaryStore,
    useErrorStore,
    useProfileStore,
    useSellDictionaryStore,
    useSellStore
} from '../../state/store';
import {useTranslation} from 'react-i18next';
import {useShallow} from 'zustand/react/shallow';
import {initialOptions} from '../../state/initialstate';
import {years} from '../../data/years';
import {HistoryModal} from '../Modals/Sell/HistoryModal';
import {FeaturesModal} from '../Modals/Sell/FeaturesModal';
import {CommentModal} from "../Modals/Sell/CommentModal";
import {getSHA1, validateFiles} from "../../integration/storage/storageUtils";
import {
    calculateAdlerCode, lowerCase,
    prepareDataForUpload, updateObjectForInsert,
    verifyMandatoryFields
} from "../../integration/tables/tableUtils";
import {getKWfromHP, processCharacteristicsFields} from "./utils/addCarUtils";
import {useMsal} from "@azure/msal-react";
import {AzureStorageRESTConnector} from "../../integration/storage/AzureStorageRESTConnector";
import {AzureAdvertisementsRESTConnector} from "../../integration/tables/AzureAdvertisementsRESTConnector";
import {AzureModelsRESTConnector} from "../../integration/tables/AzureModelsRESTConnector";
import {AzureSeriesModificationRESTConnector} from "../../integration/tables/AzureSeriesModificationRESTConnector";
import {AzureCharacteristicsRESTConnector} from "../../integration/tables/AzureCharacteristicsRESTConnector";
import {formatCities} from "../SimpleSearch/simpleSearchHelper";
import {SuccessModal} from "../Modals/Sell/SuccessModal";

export const AddCar = () => {

    const sellStore = useSellStore();
    const sellDictionaryStore = useSellDictionaryStore();
    const appDictionaryStore = useDictionaryStore();
    const profileStore = useProfileStore();
    const errorStore = useErrorStore();

    const {t, i18n} = useTranslation(["texts", "countries"]);
    const {accounts} = useMsal();

    const blobsConnector = new AzureStorageRESTConnector();
    const advRESTConnector = new AzureAdvertisementsRESTConnector();
    const modelsRESTConnector = new AzureModelsRESTConnector();
    const modificationsConnector = new AzureSeriesModificationRESTConnector();
    const characteristicsConnector = new AzureCharacteristicsRESTConnector();

    /** dictionaries **/
    const makes = useSellDictionaryStore(useShallow((store) => store.makes));
    const [models, setModels] = useState([]);
    const [allModels, setAllModels] = useState([]);
    const [generations, setGenerations] = useState([]);
    const [modifications, setModifications] = useState([]);
    const [cities, setCities] = useState([]);
    const colors = sellDictionaryStore.colors;

    /** local attributes **/
    const [mileage, setMileage] = useState(initialOptions.mileage);
    const [color, setColor] = useState({value: '', name: '', disabled: true, selected: false});
    const [vin, setVIN] = useState(initialOptions.vin);
    const [price, setPrice] = useState(initialOptions.price);
    const [vatIncluded, setVatIncluded] = useState(initialOptions.vatIncluded);
    const [power, setPower] = useState(initialOptions.power);
    const [powerKW, setPowerKW] = useState(initialOptions.power);
    const [displacement, setDisplacement] = useState(initialOptions.displacement);
    const [fuel, setFuel] = useState(initialOptions.fuel);
    const [transmission, setTransmission] = useState(initialOptions.transmission);
    const [drivetrain, setDrivetrain] = useState(initialOptions.drivetrain);
    const [history, setHistory] = useState(initialOptions.history);
    const [features, setFeatures] = useState(initialOptions.features);
    const [comment, setComment] = useState(initialOptions.comment);

    const [make, setMake] = useState(initialOptions.make);
    const [year, setYear] = useState(initialOptions.year);
    const [model, setModel] = useState(initialOptions.model);
    const [generation, setGeneration] = useState(initialOptions.generation);
    const [modification, setModification] = useState(initialOptions.modification);
    const [characteristicID, setCharacteristicID] = useState(initialOptions.modification);
    const [mechanicalFields, setMechanicalFields] = useState({});
    const [country, setCountry] = useState(initialOptions.country);
    const [city, setCity] = useState(initialOptions.city);

    const [files, setFiles] = useState([]);


    const {isOpen, onOpen, onClose} = useDisclosure();
    const [activeModal, setActiveModal] = useState('');
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const NOT_FOUND = "not found";

    useEffect(() => {
        if (loadingComplete) {
            handleOpenModal('success');
        }

    }, [loadingComplete]);

    const handleOpenModal = (modalName) => {
        setActiveModal(modalName);
        onOpen();
    };

    const changeMake = e => {
        console.log('AddCar changeMake ', e.target.value);
        const make = e.target.value;
        if (make) {

            const matchingMake = makes.find((item) => item.value === make);
            const makeObject = {...matchingMake, visible: true};
            setMake(makeObject);
            console.log('AddCar changeMake matchingMake', makeObject);

        } else {
            setMake(initialOptions.make);
        }
    }

    const _fetchModels = async (make, year) => {

        let models;
        /** manual entry mode */
        if (!profileStore.entryModeAutomatic) {
            models = await modelsRESTConnector.retrieveModelsByID(make.value, undefined);
            /*retrieve models simple is called*/
        } else {
            /** automatic entry mode */
            models = await modelsRESTConnector.retrieveModelsByID(make.value, year);
        }

        const uniqueModelsById = Array.from(new Map(models.map(model => [model.id, {
            value: model.id.toString(), name: model.name, visible: true
        }])).values());

        uniqueModelsById.unshift({value: '', name: '', disabled: true, visible: false});

        console.log('AddCar fetchmodels:', models, uniqueModelsById);

        return {uniqueModels: uniqueModelsById, models: models};
    }

    const changeYear = async e => {
        let year = e.target.value;
        console.log('AddCar changeYear year', year);

        if (year > 0 && make.value) {

            setYear({value: year, name: year, visible: true});
            const {uniqueModels, models} = await _fetchModels(make, year);
            setModels(uniqueModels);
            setAllModels(models);
            console.log('Sell page changeYear models', models, year);
        } else {
            setYear(initialOptions.year);
        }
    }

    const _fetchGenerations = async model => {
        if (!profileStore.entryModeAutomatic) return;

        console.log('AddCar fetchGenerations model', model, allModels);

        /** get generations in auto mode */
        const modelGenerations = allModels
            .filter(mdl => mdl.id === parseInt(model.value))
            .map(gen => {
                return {
                    value: gen.generationID, name: gen.generationName, modelName: gen.name
                }
            })
        modelGenerations.unshift({value: '', name: '', disabled: true, visible: false});
        console.log('modelGenerations', modelGenerations);
        return modelGenerations;
    }

    const changeModel = async e => {
        const model = e.target.value;
        console.log('AddCar changeModel ', model);
        if (model) {

            const matchingModel = models.find(item => item.value === model);
            setModel({...matchingModel, visible: true});
            console.log('AddCar changeModel matchingModel', matchingModel);
            const generations = await _fetchGenerations(matchingModel);
            console.log('AddCar changeModel generations', generations);
            setGenerations(generations);


        } else {
            setModel(initialOptions.model);
        }
    }

    const _fetchModifications = async generation => {
        if (generation.value !== '' && generation.value > 0) {

            console.log('AddCar page useEffect generation', generation);

            const trimsAndMods = await modificationsConnector.retrieveSeriesAndModification(generation.value);

            const upd = trimsAndMods.map(mod => {
                return {
                    value: mod.RowKey, name: mod.ModificationName, visible: true
                }
            });

            upd.unshift({value: '', name: '', disabled: true, visible: false});

            return upd;
        }
    }

    const changeGeneration = async e => {
        const generation = e.target.value;
        console.log('AddCar changeGeneration generation', generation, generations, typeof generation);
        if (generation) {

            const matchingGeneration = generations.find(item => item.value === generation); //find matching gen in the// list
            console.log('AddCar changeGeneration matchingGeneration', matchingGeneration);
            const generationObject = {...matchingGeneration, visible: true};
            setGeneration(generationObject); //set gen as object in the store
            console.log('AddCar changeGeneration generationObject', generationObject);
            const mods = await _fetchModifications(generationObject);
            console.log('AddCar changeGeneration mods', mods);
            setModifications(mods);

        } else {
            setGeneration(initialOptions.generation);
        }
    }

    const _fetchCharacteristics = async modificationID => {
        const lang = i18n.language;

        const characteristic = await characteristicsConnector
            .retrieveCharacteristicsByLanguage(modificationID, lang);

        console.log('AddCar _fetchCharacteristics', characteristic);

        return characteristic;

    }

    const _parseMechanicalFromCharacteristic = characteristic => {

        const translatedAttributes = {
            units: t('characteristics:units', {returnObjects: true}),
            values: t('characteristics:values', {returnObjects: true}),
            suspension: t('characteristics:values', {returnObjects: true})
        }

        const extraFields = processCharacteristicsFields(characteristic, translatedAttributes);

        //prepare for semi-automatic form filling
        const emptyExtraFields = Object.entries(extraFields).reduce((acc, [key, value]) => {
            if (value === undefined) {
                acc[key] = "not found";
            }
            return acc;
        }, {});

        console.log('emptyExtraFields', emptyExtraFields);

        const mechanicalFields = {
            fuel: {value: extraFields.fuel, visible: true},
            transmission: {value: extraFields.transmission, visible: true},
            drivetrain: {value: extraFields.drivetrain, visible: true},
            power: {value: extraFields.power, visible: true},
            displacement: {value: extraFields.displacement, visible: true},
            characteristicError: emptyExtraFields
        }
        console.log('mechanicalFields', mechanicalFields);
        return mechanicalFields;

    }


    const changeModification = async e => {
        const modification = e.target.value;
        console.log('AddCar changeModification ', modification);
        if (modification) {

            /** automatic mode - modification from select */
            if (profileStore.entryModeAutomatic) {
                const matchingModification = modifications.find(item => item.value === modification); //
                setModification({...matchingModification, visible: true});
                const characteristics = await _fetchCharacteristics(modification);
                console.log('AddCar changeModification characteristics', characteristics);
                setCharacteristicID(modification);
                console.log('AddCar changeModification chars', modification, characteristics);
                const mech = _parseMechanicalFromCharacteristic(characteristics);
                console.log('AddCar changeModification mech', mech);
                setMechanicalFields(mech);

            } else {
                /** manual mode - modification from input */
                const modificationFromInput = {value: modification, name: modification, visible: true};
                setModification(modificationFromInput);
            }

        } else {
            setModification(initialOptions.modification);
        }
    }

    const changeColor = e => {
        const color = e.target.value;
        console.log('AddCar changeColor ', color);
        if (color) {

            const matchingColor = sellDictionaryStore.colors.find(item => item.value === color);
            setColor({...matchingColor, visible: true});

            console.log('AddCar changeColor matchingColor', sellStore.color);
        } else {
            setColor({value: '', name: '', disabled: true, selected: false});
        }
    }

    const changeMileage = e => {
        let mileage = e.target.value;
        console.log('AddCar changeMileage ', mileage);
        if (mileage) {

            if (mileage.length > 7) mileage = mileage.slice(0, 7);
            setMileage({value: mileage, visible: true});

        } else {
            setMileage(initialOptions.mileage);
        }
    }

    const changeVIN = e => {
        const vin = e.target.value;
        console.log('AddCar changeVIN ', vin);
        if (vin) {

            setVIN({value: vin.toUpperCase(), visible: true, name: vin});

        } else {
            setVIN(initialOptions.vin);
        }
    }

    const _parseCities = country => {
        if (country.value !== '' && country.cities !== undefined) {
            const cities = country.cities;
            const formattedCities = formatCities(cities);
            console.log('AddCar _parseCities', formattedCities);
            // console.log('A page useEffect [sellStore.country]', sellDictionaryStore.cities);
            return formattedCities;
        }
    }

    const changeCountry = e => {
        const country = e.target.value;
        console.log('AddCar changeCountry ', country);
        if (country) {
            const matchingCountry = sellDictionaryStore.countries.find(item => item.value === country);
            setCountry({...matchingCountry, visible: true});
            const cities = _parseCities(matchingCountry);
            console.log('AddCar changeCountry matchingCountry', cities);
            setCities(cities);

        } else {
            setCountry(initialOptions.country);
            setCity(initialOptions.city);
            setCities([]);
        }
    }

    const changeCity = e => {
        const city = e.target.value;
        console.log('AddCar changeCity ', city);
        if (city) {
            const matchingCity = cities.find(item => item.value === city);
            setCity({...matchingCity, visible: true});

        } else {
            setCity(initialOptions.city);
        }
    }

    const changePrice = e => {
        let price = e.target.value;
        console.log('AddCar changePrice ', price);
        if (price) {
            if (price.length > 7) price = price.slice(0, 7);
            setPrice({value: price, visible: true, name: price});

        } else {
            setPrice(initialOptions.price);
        }
    }

    const changeFile = e => {
        const files = e.target.files;
        console.log('AddCar changeFile ', files);
        if (files) {
            setFiles([...files]);

        } else {
            setFiles([]);
        }
    }


    const changePower = e => {
        let power = e.target.value;
        console.log('AddCar changePower ', power);
        if (power) {

            if (power.length > 4) power = power.slice(0, 4);

            const kw = getKWfromHP(power);

            setPower({value: power, visible: true, name: power});
            setPowerKW({value: kw, visible: true, name: kw});


        } else {
            setPower(initialOptions.power);
            setPowerKW(initialOptions.power);
        }
    }

    const changeDisplacement = e => {
        let displacement = e.target.value;
        console.log('AddCar changeDisplacement ', displacement);
        if (displacement) {
            if (displacement.length > 5) displacement = displacement.slice(0, 5);
            setDisplacement({value: displacement, visible: true, name: displacement});
        } else {
            setDisplacement(initialOptions.displacement);
        }
    }

    const changeFuel = e => {
        const fuel = e.target.value;
        console.log('AddCar changeFuel ', fuel);
        if (fuel) {

            const matchingFuel = appDictionaryStore.fuels.find(item => item.value === fuel);
            setFuel({...matchingFuel, visible: true});

        } else {
            setFuel(initialOptions.fuel);

        }
    }
    const changeTransmission = e => {
        const transmission = e.target.value;
        console.log('AddCar changeTransmission ', transmission);
        if (transmission) {

            const matchingTransmission = appDictionaryStore.transmissions.find(item => item.value === transmission);
            console.log('matchingTransmission', matchingTransmission);
            setTransmission({...matchingTransmission, visible: true});

        } else {
            setTransmission(initialOptions.transmission);

        }
    }

    const changeDrivetrain = e => {
        const drivetrain = e.target.value;
        console.log('AddCar changeDrivetrain ', drivetrain);
        if (drivetrain) {
            const matchingDrivetrain = appDictionaryStore.drivetrains.find(item => item.value === drivetrain);
            setDrivetrain({...matchingDrivetrain, visible: true});

        } else {
            setDrivetrain(initialOptions.drivetrain);

        }
    }

    const changeVATIncluded = e => {
        const vatIncluded = e.target.value;
        // console.log('AddCar changeVATIncluded ', typeof vatIncluded, isEmpty(vatIncluded));

        const vatFromDictionary = sellDictionaryStore.vat[country.value.toLowerCase()];
        console.log('AddCar vat ', vatIncluded, vatFromDictionary);

        if (vatIncluded) {
            const matchingVAT = vatFromDictionary.find(item => item.value === vatIncluded);
            console.log('matching vat', matchingVAT);
            setVatIncluded({...matchingVAT, visible: true})

        } else {
            setVatIncluded(initialOptions.vatIncluded);
        }
    }

    const _clearFields = () => {

        setMake(initialOptions.make);
        setYear(initialOptions.year);
        setModel(initialOptions.model);
        setGeneration(initialOptions.generation);
        setModification(initialOptions.modification);
        setMechanicalFields({});
        setCountry(initialOptions.country);
        setCity(initialOptions.city);
        setMileage(initialOptions.mileage);
        setColor({value: '', name: '', disabled: true, selected: false});
        setVIN(initialOptions.vin);
        setPrice(initialOptions.price);
        setVatIncluded(initialOptions.vatIncluded);
        setPower(initialOptions.power);
        setPowerKW(initialOptions.power);
        setDisplacement(initialOptions.displacement);
        setFuel(initialOptions.fuel);
        setTransmission(initialOptions.transmission);
        setDrivetrain(initialOptions.drivetrain);
        setHistory(initialOptions.history);
        setFeatures(initialOptions.features);
        setComment(initialOptions.comment);
        setFiles([]);
        setModels([]);
        setAllModels([]);
        setGenerations([]);
        setModifications([]);
        setCities([]);
        setCharacteristicID(initialOptions.modification);

    }


    const saveData = async () => {
        console.log('AddCar saveData ', sellStore);

        console.log('AddCar saveData history', history);
        setIsLoading(true);
        try {

            /** reset error messages*/
            const errorMessages = [];
            console.log('errorMessages reset([])', errorMessages);

            /** save userID, container and encoded vin */
            const userID = accounts[0].username;
            const containerID = await getSHA1(userID);
            const vinAsAdler = calculateAdlerCode(vin.value);
            const entryMode = profileStore.entryModeAutomatic ? 'automatic' : 'manual';
            console.log('AddCar userID', userID, '; containerID', containerID, 'vin', vinAsAdler);


            // console.log('AddCar saveData mechanicalFields', mechanicalFields);
            // console.log('AddCar vin', vin);

            const filteredObject = {...mechanicalFields};

            /** add attributes from local state*/
            filteredObject.price = price;
            filteredObject.mileage = mileage;
            filteredObject.vatIncluded = vatIncluded;
            filteredObject.history = history;
            filteredObject.features = features;
            filteredObject.comment = comment;
            filteredObject.color = color;

            filteredObject.make = make;
            filteredObject.year = year;
            filteredObject.model = model;
            filteredObject.generation = generation;
            filteredObject.modification = modification;
            filteredObject.vin = vinAsAdler;
            filteredObject.originalVIN = vin;
            filteredObject.country = country;
            filteredObject.city = city;

            if (entryMode === 'manual') {
                filteredObject.power = power;
                filteredObject.powerKW = powerKW;
                filteredObject.displacement = displacement;
                filteredObject.fuel = fuel;
                filteredObject.transmission = transmission;
                filteredObject.drivetrain = drivetrain;
            }

            console.log('AddCar saveData filteredObject', filteredObject, 'vin', vin);

            /** flatten and correctly set fields */
            const preparedObject = prepareDataForUpload(filteredObject);
            preparedObject.userID = userID;
            preparedObject.containerID = containerID;

            preparedObject.entryMode = entryMode;
            preparedObject.characteristicID = entryMode === 'manual' ? '' : characteristicID;

            console.log('AddCar saveData preparedObject', preparedObject);

            /** check if files are valid - size, orientation and extension */
            const fileErrors = await validateFiles(files);

            /** if there are errors, add to errorMessages */
            if (fileErrors.length > 0) {
                const fileErrorMessages = fileErrors.map(error => error.errorMessage);
                fileErrorMessages.forEach(item => {
                    if (!errorMessages.includes(item)) errorMessages.push(item);
                });
            }

            /** validate fields */
            const fieldsErrorArray = [];
            const fieldsOK = verifyMandatoryFields(preparedObject, fieldsErrorArray);
            console.log('AddCar saveData fieldsOK', fieldsOK);

            /** if fields have errors, add them to errorMessages */
            if (fieldsErrorArray.length > 0) {
                fieldsErrorArray.forEach(item => {
                    if (!errorMessages.includes(item)) errorMessages.push(item);
                });
            }

            /** proceed to upload and insert */
            if (files.length > 0 && errorMessages.length === 0 && fieldsOK) {
                // if (fileErrors.length === 0 && fieldsOK && fieldsErrorArray.length === 0) {

                const token = profileStore.token;
                let canUpload = false;

                /** check container exists */
                const fetchStatus = await blobsConnector.containerExists(containerID, token);
                if (fetchStatus.status === 200) canUpload = true;
                else if (fetchStatus.status === 404) {
                    const createStatus = await blobsConnector.createContainer(containerID, token);
                    if (createStatus.status === 201) canUpload = true;
                    else console.warn(createStatus.text);
                }

                /** if container exists, upload images and insert data */
                if (canUpload) {

                    /** upload images */
                    const response = await blobsConnector.putBlob(containerID, vinAsAdler, files, token);
                    console.log('response after putBlob', response);
                    const urls = response.map(r => r.url);

                    /**  save image urls */
                    if (urls.length > 0) {
                        preparedObject.blobPaths = urls;
                        console.log('json for table', preparedObject);

                        /** prepare for insert: stringify arrays, parse integer values */
                        updateObjectForInsert(preparedObject);
                        lowerCase(preparedObject);
                        console.log("AddCar saveData preparedObject", preparedObject);

                        /** insert into table */
                        try {
                            const response = await advRESTConnector.createAdvRecord(preparedObject, token);
                            if (response.status === 201) {
                                console.log('status success', response.status);
                                setLoadingComplete(true); //useeffect will trigger success modal

                                _clearFields();

                                profileStore.setNeedsRefresh(true);
                                // profileStore.setTabIndex(1);
                                window.scrollTo(0, 0);
                            }
                        } catch (e) {
                            errorMessages.push('insert not successful');
                            console.warn('insert not successful', e);
                        }
                    } else {
                        errorMessages.push('upload error');
                    }
                }
                // console.log('can upload?:', canUpload);// todo remove
            } else {
                errorMessages.unshift('cannot proceed: check empty fields or files');
                console.warn('cannot proceed', fieldsOK, fileErrors);
            }
            console.log('AddCar saveData fieldsOK', fieldsOK);

            /** update store with errorMessages */
            errorStore.setErrors(errorMessages);
        } finally {
            setIsLoading(false);
        }
    }

    const toggleAutomatic = e => {
        console.log('toggleAutomatic', e.target.checked);
        profileStore.setEntryModeAutomatic(e.target.checked);
        _clearFields();
        setLoadingComplete(false);
        // sellStore.updateMultipleOptions({
        //     make: initialOptions.make,
        //     year: initialOptions.year,
        //     model: initialOptions.model,
        //     generation: initialOptions.generation,
        //     modification: initialOptions.modification,
        //     power: initialOptions.power,
        //     displacement: initialOptions.displacement,
        //     fuel: initialOptions.fuel,
        //     transmission: initialOptions.transmission,
        //     drivetrain: initialOptions.drivetrain,
        // })
    }

    const clearFieldsAfterInsert = () => {
        sellStore.updateMultipleOptions({
            make: initialOptions.make,
            year: initialOptions.year,
            model: initialOptions.model,
            generation: initialOptions.generation,
            modification: initialOptions.modification,
            power: initialOptions.power,
            displacement: initialOptions.displacement,
            fuel: initialOptions.fuel,
            transmission: initialOptions.transmission,
            drivetrain: initialOptions.drivetrain,
            color: initialOptions.color,
            mileage: initialOptions.mileage,
            vin: initialOptions.vin,
            country: initialOptions.country,
            city: initialOptions.city,
            price: initialOptions.price,
            vatIncluded: initialOptions.vatIncluded,
            history: initialOptions.history,
            features: initialOptions.features,
            comment: initialOptions.comment
        });
        setFiles([]);
        errorStore.setErrors([]);
    }

    const preventScroll = (e) => {
        // e.preventDefault();
        e.target.blur();
        window.scrollBy(0, e.deltaY);
    }

    console.log('AddCar entryModeAutomatic', profileStore.entryModeAutomatic);

    return <Card w="full" id='add car' maxW={686}>

        <SimpleGrid columns={1} rowGap={6} w="full">
            {/*toggle automatic mode*/}
            <HStack as={HStack} alignItems='center'>
                <Text mb='0' size={'sm'}>
                    {profileStore.entryModeAutomatic ? t('texts:sell.autocomplete-on') : t('texts:sell.autocomplete-off')}
                </Text>
                <Switch id='entry-mode'
                        onChange={toggleAutomatic}
                        defaultChecked={profileStore.entryModeAutomatic}
                        value={profileStore.entryModeAutomatic}/>
            </HStack>

            <OrderedList maxW={350} spacing={5} gap='6'>

                {/*** make **/}
                <ListItem>
                    <SelectFieldSimple2
                        label={t("texts:sell.characteristics.make")}
                        options={makes}
                        field="make"
                        handler={changeMake}
                        fieldFromStore={make}
                        // fieldFromStore={sellStore.make}
                    />
                </ListItem>

                {/*** year **/}
                <ListItem>
                    <SelectFieldSimple2
                        label={t('texts:sell.characteristics.year')}
                        field='start-year'
                        options={!make.visible ? [] : years}
                        fieldFromStore={year}
                        handler={changeYear}/>
                </ListItem>

                {/*** model **/}
                <ListItem>
                    <SelectFieldSimple2
                        label={t('texts:sell.characteristics.model')}
                        field='model'
                        options={!year.visible ? [] : models}
                        fieldFromStore={model}
                        handler={changeModel}/>
                </ListItem>

                {/*** generation **/}
                {profileStore.entryModeAutomatic &&
                    <ListItem>
                        <SelectFieldSimple2
                            label={t('texts:sell.input-fields.generation')}
                            field='generation'
                            options={!model.visible ? [] : generations}
                            fieldFromStore={generation}
                            handler={changeGeneration}/>
                    </ListItem>
                }

                {/*** modification **/}
                {profileStore.entryModeAutomatic ?
                    <ListItem>
                        <SelectFieldSimple2
                            label={t('texts:sell.characteristics.modification')}
                            field='modification'
                            options={!generation.visible ? [] : modifications}
                            fieldFromStore={modification}
                            handler={changeModification}/>
                    </ListItem> :
                    <ListItem>
                        <Input type='text'
                               maxLength={20}
                               placeholder={t('texts:sell.characteristics.modification')}
                               disabled={!model.visible}
                               value={modification.value}
                               onChange={changeModification}
                               variant={modification.value ? 'primaryActive' : 'primary'}
                        />
                    </ListItem>
                }

                {/*** power **/}
                {(!profileStore.entryModeAutomatic || (sellStore.characteristicError && sellStore.characteristicError.power === NOT_FOUND)) &&
                    <ListItem>
                        <Input type='number'
                               placeholder={t('texts:sell.characteristics.power')}
                               disabled={!modification.visible}
                               value={power.value}
                               onChange={changePower}
                               onWheel={preventScroll}
                               variant={power.value ? 'primaryActive' : 'primary'}
                        />
                    </ListItem>
                }

                {/*** displacement **/}
                {(!profileStore.entryModeAutomatic || (sellStore.characteristicError && sellStore.characteristicError.displacement === NOT_FOUND)) &&
                    <ListItem>
                        <Input type='number'
                               placeholder={t('texts:sell.characteristics.displacement')}
                               disabled={!power.visible}
                               value={displacement.value}
                               onChange={changeDisplacement}
                               onWheel={preventScroll}
                               variant={displacement.value ? 'primaryActive' : 'primary'}
                        />
                    </ListItem>
                }

                {/*** fuel **/}
                {(!profileStore.entryModeAutomatic || (sellStore.characteristicError && sellStore.characteristicError.fuel === NOT_FOUND)) &&
                    <ListItem>
                        <SelectFieldSimple2
                            label={t('texts:sell.characteristics.fuel')}
                            field='fuel'
                            options={displacement.visible ? appDictionaryStore.fuels : []}
                            fieldFromStore={fuel}
                            handler={changeFuel}
                        />
                    </ListItem>
                }

                {/*** transmission **/}
                {(!profileStore.entryModeAutomatic || (sellStore.characteristicError && sellStore.characteristicError.transmission === NOT_FOUND)) &&
                    <ListItem>
                        <SelectFieldSimple2
                            label={t('texts:sell.characteristics.transmission')}
                            field='transmission'
                            options={fuel.visible ? appDictionaryStore.transmissions : []}
                            fieldFromStore={transmission}
                            handler={changeTransmission}
                        />
                    </ListItem>
                }

                {/*** drivetrain **/}
                {(!profileStore.entryModeAutomatic || (sellStore.characteristicError && sellStore.characteristicError.drivetrain === NOT_FOUND)) &&
                    <ListItem>
                        <SelectFieldSimple2
                            label={t('texts:sell.characteristics.drivetrain')}
                            field='drivetrain'
                            options={transmission.visible ? appDictionaryStore.drivetrains : []}
                            fieldFromStore={drivetrain}
                            handler={changeDrivetrain}
                        />
                    </ListItem>
                }

                {/*** color **/}
                {/*{console.log('AddCar color', color)}*/}
                <ListItem>
                    <SelectFieldSimple2
                        label={t('texts:sell.characteristics.color')}
                        field='color'
                        options={(profileStore.entryModeAutomatic && modification.visible) ?
                            colors :
                            (!profileStore.entryModeAutomatic && drivetrain.visible) ?
                                colors :
                                []
                        }
                        fieldFromStore={color}
                        handler={changeColor}
                    />
                </ListItem>

                {/*** Mileage **/}
                <ListItem>
                    <Input type='number'
                           placeholder={t('texts:sell.characteristics.mileage')}
                           disabled={!color.visible}
                           value={mileage.value}
                           onChange={changeMileage}
                           onWheel={preventScroll}
                           variant={mileage.value ? 'primaryActive' : 'primary'}
                    />
                </ListItem>

                {/*** VIN **/}
                <ListItem>
                    <Input type='text'
                           maxLength={17}
                           placeholder={'VIN'}
                           disabled={!mileage.visible}
                           value={vin.value}
                           onChange={changeVIN}
                           variant={vin.value ? 'primaryActive' : 'primary'}
                    />
                </ListItem>

                {/*** country **/}
                <ListItem>
                    <SelectFieldSimple2
                        label={t('sell.input-fields.country')}
                        field='country'
                        options={vin.visible ? sellDictionaryStore.countries : []}  //!sellStore.make.visible ? [] : sellDictionaryStore.countries
                        fieldFromStore={country}
                        handler={changeCountry}
                    />
                </ListItem>

                {/** city ***/}
                <ListItem>
                    <SelectFieldSimple2
                        label={t('sell.input-fields.city')}
                        field='city'
                        options={country.visible ? cities : []}
                        fieldFromStore={city}
                        handler={changeCity}
                    />
                </ListItem>

                {/*** price **/}
                <ListItem>
                    <VStack>
                        <Input type='number'
                               placeholder={t('sell.characteristics.price')}
                               disabled={!city.visible}
                               value={price.value}
                               onChange={changePrice}
                               onWheel={preventScroll}
                               variant={price.value ? 'primaryActive' : 'primary'}
                        />
                        <SelectFieldSimple2
                            label={t('vehicle-information.including-vat')}
                            field='vatIncluded'
                            options={price.visible
                                ? sellDictionaryStore.vat[country.value.toLowerCase()]
                                : []}
                            fieldFromStore={vatIncluded}
                            handler={(e => changeVATIncluded(e))}
                        />
                    </VStack>
                </ListItem>

                {/*** file **/}
                <ListItem>
                    <Input type='file'
                           placeholder={t('vehicle-information.images')}
                           disabled={!vatIncluded.visible}
                           multiple={true}
                           onChange={changeFile}
                           accept=".jpeg,.jfif,.jpg"
                    />
                </ListItem>

                {/*** history **/}
                <ListItem>
                    <VStack w='full' gap={0}>
                        <Button variant='outlineSecondary' w='full' textAlign='left'
                                isDisabled={!make.visible}
                                onClick={() => handleOpenModal('history')}>
                            {t('sell.input-fields.history')}
                        </Button>
                        <Flex justify='start' wrap='wrap' w='full'>
                            {history.map(el =>
                                <Tag size='sm' m={1} key={el.value}>
                                    <TagLabel>{el.name}</TagLabel>
                                </Tag>)}
                        </Flex>
                    </VStack>
                </ListItem>

                {/*** features **/}
                <ListItem>
                    <VStack w='full' gap={0}>
                        <Button variant='outlineSecondary' w='full' textAlign='left'
                                isDisabled={!make.visible}
                                onClick={() => handleOpenModal('features')}>
                            {t('vehicle-information.features')}
                        </Button>
                        <Flex justify='start' wrap='wrap' w='full'>
                            {features.map(el =>
                                <Tag size='sm' m={1} key={el.value}>
                                    <TagLabel>{el.name}</TagLabel>
                                </Tag>)}
                        </Flex>
                    </VStack>
                </ListItem>

                {/*** comment **/}
                <ListItem>
                    <VStack gap={0}>
                        <Button variant='outlineSecondary' w='full' textAlign='left'
                                isDisabled={!make.visible}
                                onClick={() => handleOpenModal('comment')}>
                            {t('vehicle-information.comment')}
                        </Button>
                        {comment.value && <Flex justify='start' wrap='wrap' w='full'>
                            <Tag size='sm' m={1}>
                                <TagLabel>{comment.value.substring(0, 25) + '...'}</TagLabel>
                            </Tag>
                        </Flex>}
                    </VStack>
                </ListItem>

                {/*** submit **/}
                <VStack gap={0}>
                    <Button variant='primary' w='full' textAlign='left'
                            isDisabled={!make.visible || loadingComplete}
                            isLoading={isLoading}
                            onClick={saveData}>
                        {t('sell.add-car')}
                    </Button>
                    {errorStore.errors.map((message, index) => (
                        <FormControl isInvalid={true} key={`error-message-${index}`}>
                            <FormErrorMessage>{message}</FormErrorMessage>
                        </FormControl>
                    ))} {loadingComplete && <FormControl>
                    <FormHelperText>Car added successfully!</FormHelperText>
                </FormControl>
                }
                </VStack>

            </OrderedList>

        </SimpleGrid>

        {activeModal === 'history'
            && <HistoryModal isOpen={isOpen}
                             history={history}
                             setHistory={setHistory}
                             onClose={() => {
                                 onClose();
                                 setActiveModal('');
                             }}/>}
        {activeModal === 'features'
            && <FeaturesModal isOpen={isOpen}
                              features={features}
                              setFeatures={setFeatures}
                              onClose={() => {
                                  onClose();
                                  setActiveModal('');
                              }}/>}
        {activeModal === 'comment'
            && <CommentModal isOpen={isOpen}
                             comment={comment}
                             setComment={setComment}
                             onClose={() => {
                                 onClose();
                                 setActiveModal('');
                             }}/>}
        {activeModal === 'success' && <SuccessModal isOpen={isOpen}
                                                    onClose={() => {
                                                        setActiveModal('');
                                                        _clearFields();
                                                        setLoadingComplete(false);
                                                        onClose();
                                                    }}/>
        }

    </Card>
}