import React, {useEffect, useState} from 'react';
import {
    Container,
    Flex,
    HStack,
    VStack,
    Box,
    Text,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Button,
    FormErrorMessage,
    Badge, Stack, Hide
} from "@chakra-ui/react";
import {GiConfirmed} from "react-icons/gi";
import {useTranslation} from "react-i18next";
import {validateEmail, validateTextRange} from "../../../components/Modals/Car/formValidationHelpers";
import {Sidebar} from "../Sidebar";
import {
    composeMessage,
    loadRecaptcha, recaptchaKey,
    submitSupportMessage
} from "../../../integration/email/emailFunctions";

export const Contact = () => {
    const {t} = useTranslation(['support', 'texts', 'errors']);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [firstSubmitAttempt, setFirstSubmitAttempt] = useState(true);
    const [emailStatus, setEmailStatus] = useState(undefined);

    let preMsg = t('texts:search.car-card.contact-form.msg-pre') + ': ' + ' (' + email + ')\n\n';
    let afterMsg = '\n' + t('texts:search.car-card.contact-form.msg-after');

    useEffect(() => {
        loadRecaptcha();
    }, []);

    const validateEmailField = value => {
        const isValid = validateEmail(value);
        setEmailError(!isValid);
    };

    const validateMessage = value => {
        const isValid = validateTextRange(value, 20, 250);
        setMessageError(!isValid);
    };

    const executeRecaptcha = async () => {
        await new Promise(resolve => window.grecaptcha.ready(resolve));
        return await window.grecaptcha.execute(recaptchaKey, {action: 'submit'});
    };

    const handleSend = async () => {
        setFirstSubmitAttempt(false);

        const isEmailValid = validateEmail(email);
        const isMessageValid = validateTextRange(message, 20, 250);

        setEmailError(!isEmailValid);
        setMessageError(!isMessageValid);

        if (isEmailValid && isMessageValid) {
            try {
                setIsSending(true);

                const token = await executeRecaptcha();
                console.log('reCAPTCHA token:', token);

                const preparedMessage = composeMessage(preMsg, message, afterMsg);
                const response = await submitSupportMessage(token, email, preparedMessage);
                console.log('response from support backend', response);

                if (response.emailStatus === 'sent') {
                    setIsSubmitted(true);
                    setEmailStatus('sent');
                } else {
                    setEmailStatus('not sent');
                }

            } catch (error) {
                console.error('Failed to send email:', error);
            } finally {
                setIsSending(false);
            }
        }
    };

    const [active, setActive] = useState('contact');

    return (
        <Container maxW="container.xl" my={[4, 9]}>
            <Stack direction={{base: 'column', md: 'row'}}
                   align='start'
                   spacing={[8, 12]}
                   w="full"
            >
                <Sidebar active={active} setActive={setActive}/>
                <Flex direction="column" w={['full','350px']}>
                    <Box mb={6} w='full'>
                        <Hide below={'md'}>
                            <Heading as="h2" size="md" >{t('company.contact.contact-us')}</Heading>
                        </Hide>
                        <Text mb={2}>+372 5855 7507</Text>
                        <Text mb={4}>info@caruniverse.ee</Text>
                        {!isSubmitted && (
                            <Box mt={6}>
                            <Heading as="h3" size="sm" mb={4} fontWeight='bold'>{t('company.contact.write-a-message')}</Heading>
                            <VStack spacing={4} w='full'>
                                <FormControl isRequired isInvalid={emailError}>
                                    <FormLabel>{t('company.contact.your-email')}</FormLabel>
                                    <Input
                                        variant={email?.length > 0 ? 'primaryActive' : 'primary'}
                                        type='text'
                                        placeholder={t('company.contact.your-email')}
                                        onChange={e => {
                                            setEmail(e.target.value);
                                            if (!firstSubmitAttempt) {
                                                validateEmailField(e.target.value);
                                            }
                                        }}
                                        value={email}
                                    />
                                    <FormErrorMessage>{t('errors:car.email-not-ok')}</FormErrorMessage>
                                </FormControl>
                                <FormControl isRequired isInvalid={messageError}>
                                    <FormLabel>{t('company.contact.your-message')}</FormLabel>
                                    <Textarea
                                        placeholder={t('company.contact.your-message')}
                                        maxLength={250}
                                        resize='vertical'
                                        height='110px'
                                        variant={message?.length > 0 && 'primaryActive'}
                                        onChange={e => {
                                            setMessage(e.target.value);
                                            if (!firstSubmitAttempt) {
                                                validateMessage(e.target.value);
                                            }
                                        }}
                                        value={message}
                                    />
                                    <FormErrorMessage>{t('errors:car.message-not-ok')}</FormErrorMessage>
                                </FormControl>
                                <FormControl>
                                    <HStack w='full' justify='start'>
                                        <Button
                                            w={100}
                                            variant="outlineRed"
                                            boxShadow='md'
                                            onClick={handleSend}
                                            isDisabled={isSending}
                                            isLoading={isSending}
                                        >
                                            {t('company.contact.send')}
                                        </Button>
                                    </HStack>
                                </FormControl>
                            </VStack>
                        </Box>)}
                    </Box>

                    {isSubmitted && (
                        emailStatus === 'sent' ?
                            <Flex w='full' justify='start'>
                                <Badge
                                    variant='subtle'
                                    w='300px'
                                    colorScheme="green"
                                    p={4}
                                    borderRadius="md"
                                    display="flex"
                                    alignItems="center"
                                    gap={2}
                                    fontSize='sm'
                                >
                                    <Text>{t('search.car-card.contact-form.sent-status-sent')}</Text>
                                    <GiConfirmed color="green" size='1rem'/>
                                </Badge>
                            </Flex> : <Flex w='full' justify='start'>
                                <Badge
                                    variant='subtle'
                                    // w='300px'
                                    colorScheme="neutral"
                                    p={4}
                                    borderRadius="md"
                                    display="flex"
                                    alignItems="center"
                                    gap={2}
                                    fontSize='sm'
                                >
                                    <Text>{t('search.car-card.contact-form.sent-status-not-sent')}</Text>
                                </Badge>
                            </Flex>
                    )}
                </Flex>
            </Stack>
        </Container>
    );
};