export const splitIntoTokens = (title, targetLength) => {
    const tokens = title.split(' ');
    let result = '';
    let currentLine = '';

    // console.log('Tokens:', tokens);
    // console.log('Target length:', targetLength);

    for (let token of tokens) {
        // console.log('Current token:', token);
        if ((currentLine + ' ' + token).trim().length <= targetLength) {
            currentLine = (currentLine + ' ' + token).trim();
            // console.log('Current line after adding token:', currentLine);
        } else {
            // console.log('Current line length exceeds target length:', currentLine);
            break;
        }
    }

    result = currentLine;
    // console.log('Final result:', result);
    return result;
};
