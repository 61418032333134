import {Container, Flex, Image} from '@chakra-ui/react';
import {SimpleSearch} from '../SimpleSearch/SimpleSearch';
import image from '../../images/hero/beautiful-highway-wallpaper.jpg';

export const Hero = () => (
    <Flex
        maxW="100vw"
        w="full"
        bg="neutral.500"
        px={0}
        py={{base: 0, sm: '12'}}
        h={{base: '560px', sm: '506px'}}
        pos="relative"
        alignItems="center"
        direction={{base: 'column', sm: 'row'}}
    >
        <Image
            src={image}
            w="full"
            h="full"
            objectFit="cover"
            position={{base: 'relative', sm: 'absolute'}}
        />
        <Container
            maxW="container.xl"
            px={{base: 0, sm: '4'}}
            flexGrow="1"
            zIndex="10"
        >
            <SimpleSearch />
        </Container>
    </Flex>
);
