import React, {useEffect, useMemo, useState} from 'react';
import {Input, VStack, Text, Flex, Box, InputGroup, InputLeftElement, Button} from "@chakra-ui/react";
import {useProfileStore} from "../../state/store";
import {AzureAdvertisementsRESTConnector} from "../../integration/tables/AzureAdvertisementsRESTConnector";
import {parseObjectArrays} from "../../integration/tables/tableUtils";
import {SkeletonStack} from "../SearchResultList/SkeletonStack";
import {CarAdvCard} from "./CarAdvCard";
import {useTranslation} from "react-i18next";
import {capitalize} from "lodash";
import ReactPaginate from 'react-paginate';
import {CaretCircleLeft, CaretCircleRight} from "../../icons";
import {IoSearch} from "react-icons/io5";

export const MyOffers = () => {
    const {t} = useTranslation(['texts']);
    const profileStore = useProfileStore();
    const advRESTConnector = useMemo(() => new AzureAdvertisementsRESTConnector(), []);

    const [cars, setCars] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage] = useState(15); // Number of items per page

    const memoizedCars = useMemo(() => cars, [cars]);
    const [isLoading, setIsLoading] = useState(true); // New loading state

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const sellerCars = await advRESTConnector.retrieveUserAds(profileStore.token);
            const parsed = sellerCars.map(car => parseObjectArrays(car));
            const sorted = parsed.sort((a, b) => new Date(b.Timestamp) - new Date(a.Timestamp));
            setCars(sorted);
            setIsLoading(false);
        };

        if (profileStore.token || (profileStore.token && profileStore.needsRefresh)) {
            fetchData();
        }
        if (profileStore.needsRefresh) {
            profileStore.setNeedsRefresh(false);
        }
        if (profileStore.historyNeedsRefresh) {
            profileStore.setHistoryNeedsRefresh(undefined);
        }
    }, [profileStore.token, profileStore.needsRefresh]);

    useEffect(() => {
        const filtered = memoizedCars.filter(item => {
            const searchFields = [item.PartitionKey, item.RowKey, item.modification, item.makeAndModel].join(' ').toLowerCase();
            return searchFields.includes(searchQuery.toLowerCase());
        });
        setFilteredResults(filtered);
        setCurrentPage(0); // Reset to the first page on search change
    }, [searchQuery, memoizedCars]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlePageClick = ({selected}) => {
        setCurrentPage(selected);
    };

    // Calculate the current items to display
    const offset = currentPage * itemsPerPage;
    const currentItems = filteredResults.slice(offset, offset + itemsPerPage);

    return (
        <VStack spacing={2} w="full" align="start">
            {isLoading
                ? <SkeletonStack numberOfItems={itemsPerPage}/>
                : <VStack w='full' spacing={2} id='my-offers-stack-1'>
                    {cars.length > 0 && <Flex justify='start'
                                              gap={1}
                                              mb={4}
                                              direction='column'
                                              w={'full'}>
                        <InputGroup maxW={{base: 'full', md: '338px'}}>
                            <InputLeftElement pointerEvents="none">
                                <IoSearch color="grey"/>
                            </InputLeftElement>
                            <Input
                                type="text"
                                placeholder={t('texts:sell.my-offers-area.search-by-make-model')}
                                bg="white"
                                fontSize="sm"
                                variant="primary"
                                onChange={handleSearchChange}
                                sx={{
                                    '::placeholder': {
                                        color: 'neutral.600',
                                    }
                                }}
                            />
                        </InputGroup>
                        <Text fontSize="xs"
                              pl={1}
                              w='fit-content'
                              color={'neutral.800'}
                              fontWeight={500}>
                            {capitalize(t("search.car-listing.results"))}
                            {": "}{filteredResults.length}
                        </Text>
                    </Flex>}
                    <VStack spacing={6} w="full">
                        {currentItems.map((car, index) =>
                            <CarAdvCard car={car} key={`car-ad-${index}`}/>
                        )}
                    </VStack>
                    {cars.length > 0 && <Flex justify='center' w='full' mt={5}>
                        <ReactPaginate
                            previousLabel={<CaretCircleLeft/>}
                            nextLabel={<CaretCircleRight/>}
                            breakLabel="…"
                            forcePage={currentPage}
                            pageCount={Math.ceil(filteredResults.length / itemsPerPage)}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            pageClassName="paginationBtn"
                            previousClassName="paginationPrev"
                            nextClassName="paginationNext"
                            activeClassName="paginationBtnActive"
                            containerStyle={{display: 'flex', justifyContent: 'center', width: '100%'}}
                            renderOnZeroPageCount={() => (
                                <Box minH={400}>
                                    <Text fontSize="xs">{t('errors:search.no-results')}</Text>
                                </Box>
                            )}
                        />
                    </Flex>}
                </VStack>
            } {
            !isLoading && cars.length === 0 && <VStack
                minH={{base: 300, sm: 400}}
                w='full'
                display='flex'
                justify='center' alignItems='center'
                pb={16}
            >
                <Text>{t('sell.my-offers-area.no-cars-yet')}</Text>
                <Button variant={'primary'} mt={4} onClick={() => profileStore.setTabIndex(1)}>{t('sell.add-car')}</Button>
            </VStack>
        }
        </VStack>
    );
};