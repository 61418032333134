import {
    VStack,
    Text,
    HStack,
    Button,
    useDisclosure,
    Skeleton, Box, useBreakpointValue, IconButton,
} from "@chakra-ui/react";
import {SearchResultListItem} from "../SearchResultListItem/SearchResultListItem";
import {FilterTags} from "../FilterTags/FilterTags";
import {SearchListSort} from "../SearchListSort/SearchListSort";
import {CaretCircleLeft, CaretCircleRight, Filter} from "../../icons";
import SearchModal from "../Modals/Search/SearchModal";
import {useSearchStore} from "../../state/store";
import {SkeletonStack} from "./SkeletonStack";
import {useTranslation} from "react-i18next";
import ReactPagination from "react-paginate";
import {generateSkip} from "../../integration/search/searchConnectorUtils";
import {initialSearch} from "../../state/initialstate";

export const SearchResultList = () => {

    console.log("SearchResultList");

    const {t} = useTranslation(["texts", "errors"]);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const searchStore = useSearchStore();
    const searchResults = searchStore.searchResults;
    const pageCount = searchResults["@odata.count"] !== undefined
        ? Math.ceil(searchResults["@odata.count"] / searchStore.itemsPerPage)
        : 0;
    // console.log("SearchResultList pageCount", pageCount, searchResults["@odata.count"], searchStore.itemsPerPage);
    const showSearchListSort = useBreakpointValue({base: false, md: true});
    const justifyContent = useBreakpointValue({base: 'space-between', md: 'start'});

    const handlePageChange = e => {
        const skip = generateSkip(e.selected, 7);
        searchStore.setSkip(skip);
        searchStore.setActivePage(e.selected);
    }

    return <>
        <VStack spacing={{base: 3, md: 3, lg: 2}}
                w="full" align="start" id='search-results-stack-1'
                maxW={{base: "full", md: "800px"}}>
            <VStack w="full" spacing={0} id='search-results-stack-2'>
                <HStack w="full" justify="space-between" align="center">
                    <HStack justifyContent={justifyContent}
                            w={{base: 'full', md: 'auto'}}
                            gap={3}
                    >
                        <Button
                            variant="outline"
                            spacing={4}
                            onClick={onOpen}
                            leftIcon={<Filter/>}
                            hideFrom="xl"
                        >
                            {t("search.search-area.search-filters")}
                        </Button>

                        <Skeleton
                            isLoaded={searchStore.isLoaded}
                            startColor="neutral.50"
                            endColor="neutral.200">
                            <Text textStyle="sm" fontWeight={500}>
                                {`${searchResults["@odata.count"]} ${t("search.car-listing.results")}`}
                            </Text>
                        </Skeleton>
                    </HStack>
                    {showSearchListSort && <SearchListSort/>}
                </HStack>

                <FilterTags/>
            </VStack>
            <VStack w="full" align="start" spacing={6} id='search-results-stack'>
                {!searchStore.isLoaded ? (<SkeletonStack numberOfItems={initialSearch.itemsPerPage}/>) : (
                    searchResults.value.map((item) => (
                        <SearchResultListItem resultItem={item} key={item.RowKey}/>
                    ))
                )}
            </VStack>

            <Box id='pagination-box'
                 w={['full', '340px']}
                 mt={[2,3]}
            >
                <ReactPagination
                    containerStyle={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                    previousLabel={<CaretCircleLeft/>}
                    nextLabel={<CaretCircleRight/>}
                    breakLabel="…"
                    forcePage={searchStore.activePage} //controlled from store; arrows state corresponds to page count
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={1}
                    onPageChange={e => handlePageChange(e)}
                    renderOnZeroPageCount={() =>
                        <Box minH={400}>
                            <Text
                                fontSize={'xs'}>{t('errors:search.no-results')}
                            </Text>
                        </Box>}

                    containerClassName="pagination"
                    pageClassName="paginationBtn"
                    previousClassName="paginationPrev"
                    nextClassName="paginationNext"
                    activeClassName="paginationBtnActive"
                />
            </Box>
        </VStack>
        <SearchModal isOpen={isOpen} onClose={onClose}/>
    </>
};