import _ from "lodash";

export const findMatchingModelCount = (selectedModels, modelFacets) => {
    const flattenedModels = _.flatMap(selectedModels, models => models.map(_.toLower));
    console.log('flattenedModels', flattenedModels);
    const modelCount = {};

    /**
     * Iterate through the flattened models array.
     * For each model, split it if it contains '/'.
     * Check each part (or the whole model if not split) against the facets.
     * Sum the counts of matching facets.
     * Return an object based on the first array with the accumulated count values.
     * */

    flattenedModels.forEach(model => {
        const parts = model.includes('/') ? model.split('/') : [model];
        let totalCount = 0;

        parts.forEach(part => {
            const facet = modelFacets.find(f => f.value === part.toLowerCase());
            if (facet) {
                totalCount += facet.count;
            }
        });
        modelCount[model] = {count: totalCount};
    });

    console.log('modelCount', modelCount);
    return modelCount;
}

export const generateOptions = (make, model) => ({
    make: {name: make, value: make, visible: true},
    model: {value: model.toLowerCase(), name: model, visible: true}
});