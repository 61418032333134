const rootApiURI = process.env.REACT_APP_ROOT_API_URI;
const metadataApiKey = process.env.REACT_APP_METADATA_API_KEY;
const apiKeyName = process.env.REACT_APP_OCP_API_NAME;

class AzureAdvertisementsRESTConnector {

    retrieveUserAds = async token => {
        console.log('retrieveByUserID:');

        try {
            const response = await fetch(`${rootApiURI}/metadata/ads`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                        [apiKeyName]: metadataApiKey
                    }
                })

            if (!response.ok) {
                throw new Error('retrieveByUserID error: failed to retrieve ads. !response.ok');
            }

            const json = await response.json();
            const updatedJson = _cleanupRow(json);
            console.log('retrieveByUserID json: ', json, 'updated json', updatedJson);
            return updatedJson;
        } catch (error) {
            console.warn('retrieveAdsByUserID error:', error);
        }
    }


    retrieveAdsByUserIDAndVIN = async (userID, vin,token) => {
        console.log('retrieveByUserID and vin', userID, vin);

        try {
            const response = await fetch(`${rootApiURI}/metadata/ads/${vin}`, {
                headers: {
                    authorization: `Bearer ${token}`,
                    [apiKeyName]: metadataApiKey
                }
            })

            if (!response.ok) {
                throw new Error('retrieveByUserIDAndVIN error: failed to retrieve ads. !response.ok');
            }
            const json = await response.json();
            const updatedJson = _cleanupRow(json)
            console.log('json', updatedJson);
            return updatedJson;
        } catch (error) {
            console.warn('retrieveAdsByUserIDAndVIN error:', error);
        }
    };

    createAdvRecord = async (data, token) => {
        console.log('createAdvertisementRecord');
        try {
            const response = await fetch(`${rootApiURI}/metadata/ads`,
                {
                    method: "post",
                    body: JSON.stringify(data),
                    headers: {
                        authorization: `Bearer ${token}`,
                        [apiKeyName]: metadataApiKey
                    }
                });

            if (!response.ok) {
                const errorDetails = await response.json(); // or response.json() if the response is in JSON format
                console.warn('Error details', errorDetails);
                throw new Error(`createAdvertisementRecord error: failed to create ad. Response status: ${response.status}`);
            }
            return response;
        } catch (error) {
            console.warn('createAdvertisementRecord error:', error);
        }
    }

    updateAdvertisementRecord = async (partitionKey, rowKey, data, token) => {
        console.log('updateAdvertisementRecord by ID', rowKey, partitionKey, data);

        try {
            const response = await fetch(`${rootApiURI}/metadata/ads/${rowKey}&make=${partitionKey}`, {
                method: 'put',
                body: JSON.stringify(data),
                headers: {
                    authorization: `Bearer ${token}`,
                    [apiKeyName]: metadataApiKey
                }
            });

            if (!response.ok) {
                throw new Error('updateAdvertisementRecord error: failed to update ad. !response.ok');
            }
            return response;
        } catch (error) {
            console.warn('updateAdvertisementRecord error:', error);
        }
    }

    deleteAdvertisementRecord = async (partitionKey, rowKey, token) => {
        console.log('deleteAdvertisementRecord by ID', rowKey, partitionKey, [apiKeyName], metadataApiKey);

        try {
            const response = await fetch(`${rootApiURI}/metadata/ads/${rowKey}&make=${partitionKey}`, {
                method: 'delete',
                headers: {
                    authorization: `Bearer ${token}`,
                    [apiKeyName]: metadataApiKey
                }
            });
            if (!response.ok) {
                throw new Error('deleteAdvertisementRecord error: failed to delete ad. !response.ok');
            }

            console.log('deleteAdvertisementRecord response', response);
            return response;
        } catch (error) {
            console.warn('deleteAdvertisementRecord error:', error);
        }
    }

}

const _cleanupRow = json => {
    return json.value.map(data => {
        data['vin'] = data['RowKey'];
        delete data['RowKey'];
        data['make'] = data['PartitionKey'];
        delete data['PartitionKey'];
        return data;
    })
}
export {AzureAdvertisementsRESTConnector}