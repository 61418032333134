import electric from '../../images/cards/tesla.jpg';
import affordable from '../../images/cards/opel.jpg';
import powerful from '../../images/cards/rs6.jpg';
import luxury from '../../images/cards/a8-mtm.jpg';

export const modelsByPrices = {
    prefix: 'vehicle-information.up-to',
    cards: [
        {
            title: 'home.cards.affordable',
            img: affordable,
            amounts: ['1 000', '2 000', '5 000', '10 000', '15 000', '20 000'],
            extraOptions: {power: {value: [0, 200], name: '', visible: true}}
        },
        {
            title: 'home.cards.powerful',
            img: powerful,
            amounts: ['5 000', '10 000', '15 000', '20 000', '30 000', '50 000'],
            extraOptions: {power: {value: [300, 999], name: 'Powerful', visible: true}}
        },
        {
            title: 'home.cards.electric',
            img: electric,
            amounts: ['10 000', '20 000', '30 000', '40 000', '50 000', '75 000'],
            extraOptions: {fuel: {value: 'electric', name: 'Electric', visible: true}}
        },
        {
            title: 'home.cards.premium',
            img: luxury,
            amounts: ['10 000', '20 000', '30 000', '50 000', '75 000', '100 000'],
            extraOptions: {
                make: {
                    name: 'porsche/bmw/audi/mercedes-benz',
                    title: 'Premium',
                    value: '',//not used for make, but is needed for consistency of data model
                    visible: true
                },
                power: {value: [300, 999], visible: true, title: 'Luxury2'}
            }
        },
    ],
};