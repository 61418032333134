import electric from '../../images/cards/taycan.jpg';
import kia from '../../images/cards/kia-2.jpg';
import sport from '../../images/cards/powerful.jpg';
import luxury from '../../images/cards/techart-2.jpg';
import polo from '../../images/cards/vw-polo.jpg';
import suv from '../../images/cards/g63.jpg';

const titles = {
    affordable: 'home.cards.affordable',
    suv: 'home.cards.suv',
    powerful: 'home.cards.powerful',
    hybrid: 'data-for-ui:fuel.hybrid.title',
    luxury: 'home.cards.luxury',
    electric: 'home.cards.electric',
}

//todo - figure out how to use translations

const options = {
    affordable: {price: {value: [0, 5000], name: 'Affordable', visible: true}},
    suv: {
        bodyStyle: {value: 'suv/crossover', name: 'SUV', visible: true},
        drivetrain: {value: 'full', name: '4x4', visible: true},
    },
    powerful: {power: {value: [300, 999], name: 'Powerful', visible: true}},
    hybrid: {fuel: {value: 'hybrid', name: 'Hybrid', visible: true}},
    luxury: {
        make: {
            value: '',//not used for make
            name: 'Porsche/Bentley/Rolls-royce',
            title: 'Luxury',
            visible: true
        },
        power: {value: [400, 999], visible: true, name: ''}
    },
    electric: {fuel: {value: 'electric', name: 'Electric', visible: true}}
}

export const smallCardsList = [
    {type: 'Affordable', img: polo, options: options.affordable, title: titles.affordable},
    {type: 'Electric', img: electric, options: options.electric, title: titles.electric},
    {type: 'SUV', img: suv, options: options.suv, title: titles.suv},
    {type: 'Hybrid', img: kia, options: options.hybrid, title: titles.hybrid},
    {type: 'Luxury', img: luxury, options: options.luxury, title: titles.luxury},
    {type: 'Sport', img: sport, options: options.powerful, title: titles.powerful},
];