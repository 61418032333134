import React, {useState} from 'react';
import {Sidebar} from "../Sidebar";
import {Container, Flex, Heading, Image, Stack, Text, VStack} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import simpleSearch from '../../../images/support/simple_search.png';
import fullSearch from '../../../images/support/full_search.png';

export const HowToSearch = () => {
    const {t} = useTranslation(['support']);
    const [active, setActive] = useState('how-to-search');

    return (
        <Container maxW="container.xl" my={[4, 9]}>
            <Stack direction={{base: 'column', md: 'row'}}
                   align='start'
                   spacing={[8, 12]}
                   w="full"
            >
                <Sidebar active={active} setActive={setActive} linksType='support'/>
                <VStack align="start" maxW={{base: 'full', md: '60%'}} gap={4}>
                    <Heading as='h2'
                             size="md"
                             textTransform='capitalize'>{t('support.search.how-to-search')}
                    </Heading>
                    <Text>
                        {t('support.search.search-is-the-heart')}
                    </Text>
                    <Text>
                        {t('support.search.search-can-be-made-from')}
                    </Text>
                    <Heading as="h3" size="md" mt={2} mb={0}>
                        {t('support.search.search-from-the-home-page')}
                    </Heading>
                    <Image src={simpleSearch} w={['full', '300px']} alt="search" border="1px solid #eee"/>

                    <Heading as="h3" size="md" mt={2} mb={0}>
                        {t('support.search.search-from-the-search-page')}
                    </Heading>
                    <Image src={fullSearch} w={['full', '300px']} alt="search" border="1px solid #eee"/>
                </VStack>
            </Stack>
        </Container>
    );
};
