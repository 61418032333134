import { tagAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys)

const baseStyle = definePartsStyle({
  container: {
    bg: '#f2f2f2',
    fontSize: "xs",
    fontWeight: 400,
    minH: 5,
    color: 'neutral.750',
    lineHeight: 5,
    rounded: "sm",
    px: 3,
    py: 0
  },
})

export const tagTheme = defineMultiStyleConfig({ baseStyle, sizes: {} })