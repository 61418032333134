import React, {useCallback, useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {HStack, Link, SimpleGrid, Text, VStack} from '@chakra-ui/react';
import _ from 'lodash';

import {selectedModels} from "./popularModelsData";
import {findMatchingModelCount, generateOptions} from "./modelListHelper";
import {useOptionsStore, useSearchStore} from "../../state/store";

export const PopularModelsList = () => {

    const navigator = useNavigate();

    const searchStore = useSearchStore();
    const optionsStore = useOptionsStore();

    const modelsFromFacets = searchStore.facets.model;

    console.log('Popular Models List', modelsFromFacets, searchStore.facets);

    const makeNames = Object.keys(selectedModels);
    const [modelsCount, setModelsCount] = useState({});

    useEffect(() => {
        if (!_.isEmpty(modelsFromFacets)) {
            const modelCount = findMatchingModelCount(selectedModels, modelsFromFacets);
            setModelsCount(modelCount);
        }
    }, [modelsFromFacets]);

    const handleModelClick = useCallback(async (make, model, e) => {
        console.log('handleModelClick in PopularModelList', make, model);
        e.preventDefault();
        const options = generateOptions(make, model);
        optionsStore.updateSomeResetOthers(options);
        navigator('/');
    }, [navigator]);


    return (
        <SimpleGrid columns={[2, 4]}
                    spacing={8}
                    w="full">
            {makeNames.map(make => (
                <VStack key={make} align="start" gap={4}>
                    <Text fontWeight={600}>{make}</Text>
                    <VStack w="full" gap={4}> {/**gap was 4*/}

                        {selectedModels[make].map(model => {

                            const modelCountElement = modelsCount[model.toLowerCase()];
                            const count = modelCountElement !== undefined ? modelCountElement.count : 0;

                            return <HStack textStyle={{base: "xs", sm: "sm"}}
                                           w='full'
                                           key={`${make}-${model}`}
                                           spacing={1}
                                           textTransform="uppercase">
                                <Link as={RouterLink}
                                      variant={count > 0 ? 'primary' : 'inactive'}
                                      onClick={e => handleModelClick(make, model, e)}>
                                    <HStack gap={1}>
                                        <Text mb={0}>{model}</Text>
                                        <Text mb={0} opacity='0.8' fontWeight='500'>({count})</Text>
                                    </HStack>
                                </Link>
                            </HStack>
                        })}
                    </VStack>
                </VStack>
            ))
            }
        </SimpleGrid>
    );
}