import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {HStack, Link, SimpleGrid, Text, VStack} from '@chakra-ui/react';
import _ from 'lodash';

import {selectedCountriesForMainPage as selectedCountries} from './modelsByCountryData';
import {useOptionsStore, useSearchStore} from "../../state/store";

export const PopularByCountryList = () => {

    const searchStore = useSearchStore();
    const optionsStore = useOptionsStore();

    const citiesFacets = searchStore.facets.city;

    const {t} = useTranslation(['countries']);
    const navigator = useNavigate();

    const countries_d = t('countries', {returnObjects: true});
    const countryNames = Object.keys(selectedCountries);

    const [cityCounts, setCityCounts] = useState({});

    console.log('PopularByCountryList', citiesFacets);

    useEffect(() => {
        if (!_.isEmpty(citiesFacets)) {
            console.log('useEffect computation PopularByCountryList');
            const flattenedCountries = _.flatMap(selectedCountries, cities => cities.map(_.toLower));
            const matchingCities = _.filter(citiesFacets, city => _.includes(flattenedCountries, city.value));

            const cityCounts = matchingCities.reduce((acc, city) => { //create an array of object to be used in the loop below
                acc[_.capitalize(city.value)] = {count: city.count};
                return acc;
            }, {});
            setCityCounts(cityCounts);
        }
    }, [citiesFacets]);

    const handleCityClick = useCallback((country, city, e) => {
        e.preventDefault();

        const countryTitle = countries_d[country].title;
        const cityTitle = countries_d[country].cities[city].title;

        const options = {
            country: {value: country, title: countryTitle, visible: true},
            city: {value: city, title: cityTitle, visible: true}
        }
        optionsStore.updateSomeResetOthers(options);
        navigator('/');

    }, [navigator]);


    return (<SimpleGrid columns={[2, 4]} spacing={8} w="full">
        {countryNames.map(country => {
            const countryObject = countries_d[country];
            return <VStack key={country} align='start' gap={4}>
                <Text fontWeight={600}>{countryObject.title}</Text>
                <VStack w="full" gap={4}>
                    {selectedCountries[country].map(city => {

                        const cityObject = countryObject.cities[city];
                        const cityCount = cityCounts[city];
                        const count = cityCount !== undefined ? cityCount.count : 0;

                        return <HStack textStyle={{base: "xs", sm: "sm"}}
                                       w='full'
                                       key={`${country}-${city}`}
                                       spacing={1}
                                       textTransform="uppercase">
                            <Link as={RouterLink}
                                  variant={count > 0 ? 'primary' : 'inactive'}
                                  onClick={e => handleCityClick(country, city, e)}
                            >
                                <HStack gap={1}>
                                    <Text mb={0}>{cityObject.title}</Text>
                                    <Text mb={0} opacity='0.8' fontWeight='500'>({count})</Text>
                                </HStack>
                            </Link>
                        </HStack>
                    })}
                </VStack>
            </VStack>
        })}
    </SimpleGrid>);
}