import {
    Button,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Textarea,
    VStack
} from '@chakra-ui/react';
import React, {useState} from 'react';
import {useTranslation} from "react-i18next";

const CommentModal = ({isOpen, onClose, comment, setComment}) => {

    // const sellStore = useSellStore();
    const [text, setText] = useState(comment.value);
    const {t} = useTranslation(['texts']);

    const changeComment = e => setText(e.target.value);

    const save = () => {

        if (text !== undefined && text.length > 0) {
            setComment({value: text, visible: true});
        } else {
            setComment({value: '', visible: false});
        }
        console.log('CommentModal save', comment);
        onClose();
    }

    const clear = () => {
        console.log('Comment modal clear');

        setText('');
        setComment({value: '', visible: false});
        onClose();
    }

    return <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay/>
        <ModalContent maxW={412} w="full">
            <ModalHeader>{t('sell.characteristics.comment')}</ModalHeader>
            <ModalCloseButton/>
            <ModalBody as={VStack} w="full" align="start" spacing={4}>
                <SimpleGrid columns={1} spacing={4} w="full">
                    <Textarea placeholder={t('sell.add-comment')}
                              value={text}
                              variant={text?.length > 0 ? 'primaryActive' : 'primary'}
                              autoFocus
                              onChange={changeComment}/>
                </SimpleGrid>
            </ModalBody>
            <ModalFooter>
                <HStack justifyContent='space-between' w='full' spacing='4'>
                    <Button variant='primary' w='full' onClick={save}>{t('sell.save')}</Button>
                    <Button variant='outline' w='full' onClick={clear}>{t('sell.clear')}</Button>
                </HStack>
            </ModalFooter>
        </ModalContent>
    </Modal>
}

export {CommentModal}