export const tabsStyle = {
    ".chakra-tabs__tablist": {
        color: "neutral.750", // Change the color of the tab text
        borderBottom: "2px solid transparent", // Remove the default bottom border
    },
    ".chakra-tabs__tab": {
        _selected: {
            color: "neutral.900",
            borderBottom: "2px solid red", // Change the underline color when selected
        },
    },
}