import { Box, Checkbox, Tooltip } from "@chakra-ui/react";

const CustomIcon = ({ isChecked, color }) => (
  <Tooltip label={color} hasArrow={true}>
    <Box
      w={4}
      h={4}
      rounded="full"
      bgColor={color}
      border={color === "white" ? "1px solid" : "none"}
      borderColor="neutral.100"
    />
  </Tooltip>
);

export const FilterCheckbox = ({ color, ...props }) => (
  <Checkbox
    icon={<CustomIcon color={color} />}
    size="lg"
    {...props}
    variant="colorFilter"
    data-bdc={color}
    _checked={{
      borderColor: color,
    }}
    _hover={{
      _checked: {
        borderColor: color,
      },
    }}
  />
);
