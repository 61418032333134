import React, {useState} from 'react';
import {
    Button, ButtonGroup,
    FormControl,
    FormErrorMessage,
    HStack, IconButton,
    Input,
    ListItem,
    OrderedList,
    SimpleGrid,
    Text,
} from '@chakra-ui/react';
import SelectFieldSimple2 from '../Search/SelectFieldSimple2';
import {useTranslation} from 'react-i18next';
import {
    checkIsAliasValid,
    checkIsCompanyAddressValid,
    checkIsPhoneValid,
    checkIsURLValid,
    checkRegistrationNumberValid
} from './newSellerFormValidators';
import {AzureSellersRESTConnector} from "../../integration/tables/AzureSellersRESTConnector";
import {useProfileStore} from "../../state/store";
import {FiDelete} from "react-icons/fi";

export const NewSellerForm = ({logout}) => {

    const {t} = useTranslation(['texts', 'data-for-ui', 'errors']);

    const sellerAPIConnector = new AzureSellersRESTConnector();
    const profileStore = useProfileStore();

    const juridicalStatus_d = t('data-for-ui:juridical-status', {returnObjects: true});
    const juridicalStatus = Object.entries(juridicalStatus_d)
        .map(([key, item]) => ({name: item.title, value: key}));
    juridicalStatus.unshift({value: '', name: '', disabled: true, selected: false});

    const [sellerType, setSellerType] = useState('');
    const [alias, setAlias] = useState('');
    const [phone, setPhone] = useState('');
    const [registration, setRegistration] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [website, setWebsite] = useState('');

    const [isTypeInvalid, setIsTypeInvalid] = useState(false);
    const [isAliasInvalid, setIsAliasInvalid] = useState(false);
    const [isPhoneInvalid, setIsPhoneInvalid] = useState(false);
    const [isRegistrationInvalid, setIsRegistrationInvalid] = useState(false);
    const [isCompanyAddressInvalid, setIsCompanyAddressInvalid] = useState(false);
    const [isWebsiteInvalid, setIsWebsiteInvalid] = useState(false);
    const [formInvalid, setFormInvalid] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const sellerTypeHandler = e => {
        const value = e.target.value;
        setSellerType(value);
        setIsTypeInvalid(value === '');
    }

    const aliasHandler = e => {
        const value = e.target.value;
        setAlias(value);
        setIsAliasInvalid(!checkIsAliasValid(value)); // Validate as user types
    }

    const phoneHandler = e => {
        const value = e.target.value;
        setPhone(value);
        setIsPhoneInvalid(!checkIsPhoneValid(value)); // Validate as user types
    }

    const registrationHandler = e => {
        const value = e.target.value;
        setRegistration(value);
        setIsRegistrationInvalid(!checkRegistrationNumberValid(value)); // Validate as user types
    }

    const companyAddressHandler = e => {
        const value = e.target.value;
        setCompanyAddress(value);
        setIsCompanyAddressInvalid(!checkIsCompanyAddressValid(value)); // Validate as user types
    }

    const websiteHandler = e => {
        const value = e.target.value;
        setWebsite(value);
        setIsWebsiteInvalid(!checkIsURLValid(value)); // Validate as user types
    }

    const validateForm = () => {
        const isAliasValid = checkIsAliasValid(alias);
        const isPhoneValid = checkIsPhoneValid(phone);
        const isRegistrationValid = checkRegistrationNumberValid(registration);
        const isCompanyAddressValid = checkIsCompanyAddressValid(companyAddress);
        const isURLValid = checkIsURLValid(website);

        setIsAliasInvalid(!isAliasValid);
        setIsPhoneInvalid(!isPhoneValid);
        setIsRegistrationInvalid(!isRegistrationValid);
        setIsCompanyAddressInvalid(!isCompanyAddressValid);
        setIsWebsiteInvalid(!isURLValid);
        setIsTypeInvalid(sellerType === '');

        if (sellerType === '') {
            setFormInvalid(true);
            return false;
        }

        if (sellerType === 'company') {
            const companyInvalid = !isAliasValid || !isPhoneValid || !isRegistrationValid || !isCompanyAddressValid || !isURLValid;
            setFormInvalid(companyInvalid);
            return !companyInvalid;
        } else {
            const basicInvalid = !isAliasValid || !isPhoneValid;
            setFormInvalid(basicInvalid);
            return !basicInvalid;
        }
    }

    const createRequestObject = (type, name, phone, registrationNumber, companyAddress, website) => {
        const requestObject = {
            type,
            name,
        };

        if (phone) requestObject.phone = phone;

        if (type !== 'private') {
            if (registrationNumber) requestObject.registrationNumber = registrationNumber;
            if (companyAddress) requestObject.companyAddress = companyAddress;
            if (website) requestObject.website = website;
        }

        return requestObject;
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            console.log('Form is invalid');
            return;
        }

        try {
            setIsLoading(true);
            const requestObject = createRequestObject(sellerType, alias, phone, registration, companyAddress, website);
            console.log('handleSubmit requestObject', requestObject);

            const response = await sellerAPIConnector.addSeller(requestObject, profileStore.token);
            console.log('handleSubmit response', response);

            await new Promise(resolve => setTimeout(resolve, 500));

        } finally {
            setIsLoading(false);
            profileStore.setNeedsRefresh(true);
        }
    }

    const clearFields = () => {
        setSellerType('');
        setAlias('');
        setPhone('');
        setRegistration('');
        setCompanyAddress('');
        setWebsite('');

        setIsTypeInvalid(false);
        setIsAliasInvalid(false);
        setIsPhoneInvalid(false);
        setIsRegistrationInvalid(false);
        setIsCompanyAddressInvalid(false);
        setIsWebsiteInvalid(false);
        setFormInvalid(false);
    };


    return (
        <SimpleGrid columns={1} rowGap={6} w="full">
            <Text>{t('texts:sell.my-account-area.register-to-sell')}</Text>

            <OrderedList maxW={350} spacing={5} gap='6'>

                {/* Seller type */}
                <ListItem>
                    <FormControl isRequired isInvalid={isTypeInvalid}>
                        <SelectFieldSimple2
                            label={t('texts:sell.my-account-area.company-or-private')}
                            options={juridicalStatus}
                            fieldFromStore={{value: sellerType}}
                            field="seller-type"
                            handler={sellerTypeHandler}
                        />
                        <FormErrorMessage>{t('errors:my-account.seller-type-not-chosen')}</FormErrorMessage>
                    </FormControl>
                </ListItem>

                {/* Name and Phone */}
                <ListItem>
                    <FormControl isRequired isInvalid={isAliasInvalid}>
                        <Input
                            type='text'
                            autoFocus
                            placeholder={t('texts:vehicle-information.seller-info.alias')}
                            value={alias || ''}
                            disabled={sellerType === ''}
                            onChange={aliasHandler}
                            variant={alias ? 'primaryActive' : 'primary'}
                        />
                        <FormErrorMessage>{t('errors:my-account.name-not-ok')}</FormErrorMessage>
                    </FormControl>
                </ListItem>

                <ListItem>
                    <FormControl isInvalid={isPhoneInvalid} isRequired={sellerType === 'company'}>
                        <Input
                            type='tel'
                            placeholder={t('texts:vehicle-information.seller-info.phone')}
                            value={phone || ''}
                            disabled={alias === ''}
                            onChange={phoneHandler}
                            variant={phone ? 'primaryActive' : 'primary'}
                        />
                        <FormErrorMessage>{t('errors:my-account.phone-not-ok')}</FormErrorMessage>
                    </FormControl>
                </ListItem>

                {sellerType === 'company' && (
                    <>
                        <ListItem>
                            <FormControl isRequired isInvalid={isRegistrationInvalid}>
                                <Input
                                    type='text'
                                    placeholder={t('texts:vehicle-information.seller-info.registrationNumber')}
                                    value={registration || ''}
                                    disabled={phone === ''}
                                    onChange={registrationHandler}
                                    variant={registration ? 'primaryActive' : 'primary'}
                                />
                                <FormErrorMessage>{t('errors:my-account.company-reg-number-not-ok')}</FormErrorMessage>
                            </FormControl>
                        </ListItem>
                        <ListItem>
                            <FormControl isRequired isInvalid={isCompanyAddressInvalid}>
                                <Input
                                    type='text'
                                    placeholder={t('texts:vehicle-information.seller-info.companyAddress')}
                                    value={companyAddress || ''}
                                    disabled={registration === ''}
                                    onChange={companyAddressHandler}
                                    variant={companyAddress ? 'primaryActive' : 'primary'}
                                />
                                <FormErrorMessage>{t('errors:my-account.company-address-not-ok')}</FormErrorMessage>
                            </FormControl>
                        </ListItem>
                        <ListItem>
                            <FormControl isInvalid={isWebsiteInvalid}>
                                <Input
                                    type='text'
                                    placeholder={t('texts:vehicle-information.seller-info.website')}
                                    value={website || ''}
                                    disabled={companyAddress.length < 5}
                                    onChange={websiteHandler}
                                    variant={website ? 'primaryActive' : 'primary'}
                                />
                                <FormErrorMessage>{t('errors:my-account.company-website-not-ok')}</FormErrorMessage>
                            </FormControl>
                        </ListItem>
                    </>
                )}

                <HStack w='full' spacing={[3, 'auto']} justify='space-between'>
                    <ButtonGroup size='sm' isAttached variant='primary'>
                        <Button
                            onClick={handleSubmit}
                            isLoading={isLoading}
                        >{t('sell.save')}</Button>
                        <IconButton
                            variant={'secondary'}
                            onClick={clearFields}
                            aria-label='Clear fields' icon={<FiDelete/>}/>
                    </ButtonGroup>

                    <Button
                        variant='outline'
                        size='sm'
                        onClick={logout}>
                        {t('sell.logout-title')}
                    </Button>
                </HStack>

            </OrderedList>
        </SimpleGrid>
    );
}