const getCountryFromDictionary = (car, dictionary) => {
    // console.log('getCountryFromDictionary');
    const country = Object.values(dictionary).find(c => c.Name.toLowerCase() === car.country);
    return country !== undefined ? country.title : car.country;
}

const getCityFromDictionary = (car, dictionary) => {
    // console.log('getCityFromDictionary');
    const country = Object.values(dictionary).find(c => c.Name.toLowerCase() === car.country);
    let city = car.city;
    if (country !== undefined && country.cities !== undefined) {
        const found = Object.values(country.cities).find(c => c.Name.toLowerCase() === car.city);
        if (found !== undefined) city = found.title;
    }
    return city;
}

const getTitleFromDictionary = (element, dictionary) => {
    // console.log('LanguageUtils: getTitleFromDictionary\n', 'element:', element, 'dictionary[element]', dictionary[element]);

    if (dictionary === undefined) {
        console.warn('LanguageUtils Dictionary missing!');
        return;
    }

    const dictionaryEntry = dictionary[element];

    if (dictionaryEntry === undefined) {
        return element;
    }

    return typeof dictionaryEntry === 'object' ? dictionaryEntry.title : dictionaryEntry;
};

const processCharacteristicsValuesByLanguage = (responseData, values, suspension) => {
    // console.log('_processValuesByLanguage', 'responseData', responseData);
    // const copy = JSON.parse(JSON.stringify(responseData));
    responseData.forEach((el, index) => {
            // console.log('index', index, 'el', el);


            if (el.characteristicID === 41 || el.characteristicID === 42) {
                const arr2 = [];
                // console.log('suspension', el);
                const arr = el.value.split(',').map(el => el.toLowerCase().trim());
                // console.log('arr', arr);

                arr.forEach((el, index) => {
                    Object.keys(suspension).map(key => {
                        if (key.toLowerCase() === el.toLowerCase()) {
                            // console.log(key, '===', el);
                            arr2.push(key)
                        }
                    });
                });
                // console.log('arr2', arr2);
                let str = "";
                arr2.forEach((el, index) => {
                    // console.log('arr2 el', el)
                    if (index === 0) return str += suspension[el].title.charAt(0).toUpperCase()
                        + suspension[el].title.slice(1);
                    else if (index < arr2.length) return str += ', ' + suspension[el].title
                    // return str += ', '+suspension[el].title;
                })
                // console.log('resulting string', str);
                responseData[index].value = str;
            }

            if (el.characteristicID === 1564) {
                el.unit = ''; // hide unit for max torque rpm
            }
            if (el.characteristicID === 15) {
                el.unit = ''; //hide rpm for max torque range
            }

            Object.keys(values).forEach(val => {
                const found = el.value.trim().toLowerCase() === (val.trim().toLowerCase());
                if (found) {
                    // console.log('LanguageUtil val', values[val].title, 'el', el, 'responseData[el].value', responseData[index].value);
                    return responseData[index].value = values[val].title;
                }
            });

            if (el.value.includes('up to')) {
                let str = responseData[index].value;
                // console.log(str, 'values[\'up to\']', values['up to'])
                const newValue = str.replace('up to', values['up to'].title);
                responseData[index].value = newValue;
                // console.log('LanguageUtil newValue (up to)', newValue);
            }
            if (el.value.includes(' to') || el.value.includes('to ')) {
                let str = responseData[index].value;
                // console.log(str, 'values[\'up to\']', values['up to'])
                const newValue = str.replace('to', values['up to'].title);
                responseData[index].value = newValue;
                // console.log('LanguageUtil newValue (to)', newValue);
            }
            if (el.value.includes('from')) {
                let str = responseData[index].value;
                const newValue = str.replace('from', values['from'].title);
                responseData[index].value = newValue;
                // console.log('LanguageUtil newValue (from)', newValue);
            }

        }
    );
    return responseData;
}

const processUnitsByLanguage = (json, units) => {
    console.log('_processUnitsByLanguage', units, json);
    // const copy = JSON.parse(JSON.stringify(json))
    json.forEach((el, index) => {
            // console.log(el.unit);
            Object.keys(units).forEach(unit => {
                const found = el.unit.trim().toLowerCase() === (unit.trim().toLowerCase());
                if (found) {
                    // console.log('unit', units[unit].title, 'el', el, 'json[el].unit', json[index].unit);
                    return json[index].unit = units[unit].title;
                }
            });
            // if(el.toLowerCase().includes(units.))
        }
    );
    return json;
}

const selectLanguageFromLocaleMap = lang => {
    let matchingLanguage = languageLocaleMap[lang];
    console.log('matching language step 1: navigator.language', lang, 'matching Language', lang, matchingLanguage);
    if (matchingLanguage === undefined) {
        console.log('matching language step 2:', lang, 'was not found among extra languages, returning: en');
        return 'en'
    }
    return matchingLanguage;
}

const languageLocaleMap = {
    'ru-RU': 'ru',
    'et-EE': 'ee',
}

export {
    getCityFromDictionary,
    getCountryFromDictionary,
    getTitleFromDictionary,
    processCharacteristicsValuesByLanguage,
    processUnitsByLanguage,
    selectLanguageFromLocaleMap
}