import {create} from 'zustand';
import {initialOptions, initialSearch} from './initialstate';
import {AzureModelsRESTConnector} from '../integration/tables/AzureModelsRESTConnector';
import {sortAndFormatModelsList} from "../components/SimpleSearch/simpleSearchHelper";

const modelsConnector = new AzureModelsRESTConnector();

export const useDictionaryStore = create(set => ({

    makes: [],
    models: [],
    transmissions: [],
    fuels: [],
    history: [],
    drivetrains: [],
    countries: [],
    features: [],
    colors: [],

    setMakes: makes => set(state => ({makes: makes})),

    // fetchAndSetMakes: async () => {
    //     try {
    //         const unsortedMakes = await makesConnector.retrieveMakes();
    //         const sortedMakes = sortAndFormatMakesList(unsortedMakes);
    //         console.log('Store fetch() response', sortedMakes);
    //         set({makes: sortedMakes});
    //         console.log('Dictionary store makes:', sortedMakes);
    //     } catch (error) {
    //         console.error('Error fetching makes:', error);
    //         // Handle error appropriately
    //     }
    // },

    fetchAndSetModels: async makeID => {
        console.log('Store fetchModels()');
        const unsortedModels = await modelsConnector.retrieveModelNames(makeID);
        const sortedModels = sortAndFormatModelsList(unsortedModels);
        console.log('Store fetchModels() response', sortedModels);
        set({models: sortedModels});
    },

    setModels: models => set(state => ({models: models})),
    setTransmissions: transmissions => set(state => ({transmissions: transmissions})),
    setFuels: fuels => set(state => ({fuels: fuels})),
    setHistory: history => set(state => ({history: history})),
    setDrivetrains: drivetrains => set(state => ({drivetrains: drivetrains})),
    setCountries: countries => set(state => ({countries: countries})),
    setFeatures: features => set(state => ({features: features})),
    setColors: colors => set(state => ({colors: colors})),

    updateMultipleOptions: options => set(state => ({
        ...state, ...options
    })),

}));
export const useSellDictionaryStore = create(set => ({

    makes: [],
    models: [],
    modelNames: [],
    generations: [],
    modifications: [],
    transmissions: [],
    fuels: [],
    history: [],
    drivetrains: [],
    countries: [],
    features: [],
    colors: [],
    cities: [],
    vat: [],

    setMakes: makes => set(state => ({makes: makes})),
    setModels: models => set(state => ({models: models})),
    setModelNames: modelNames => set(state => ({modelNames: modelNames})),
    setGenerations: generations => set(state => ({generations: generations})),
    setModifications: modifications => set(state => ({modifications: modifications})),
    setTransmissions: transmissions => set(state => ({transmissions: transmissions})),
    setFuels: fuels => set(state => ({fuels: fuels})),
    setHistory: history => set(state => ({history: history})),
    setDrivetrains: drivetrains => set(state => ({drivetrains: drivetrains})),
    setCountries: countries => set(state => ({countries: countries})),
    setFeatures: features => set(state => ({features: features})),
    setColors: colors => set(state => ({colors: colors})),
    setCities: cities => set(state => ({cities: cities})),

    updateMultipleOptions: options => set(state => ({
        ...state, ...options
    })),

}));
export const useOptionsStore = create(set => ({

    make: {...initialOptions.make},
    year: {...initialOptions.year},
    model: {...initialOptions.model},
    transmission: {...initialOptions.transmission},
    price: {...initialOptions.price},
    vatIncluded: {...initialOptions.vatIncluded},
    ignoreSponsored: {...initialOptions.ignoreSponsored},
    mileage: {...initialOptions.mileage},
    fuel: {...initialOptions.fuel},
    bodyStyle: {...initialOptions.bodyStyle},
    drivetrain: {...initialOptions.drivetrain},
    power: {...initialOptions.power},
    country: {...initialOptions.country},
    city: {...initialOptions.city},
    history: initialOptions.history,
    features: initialOptions.features,
    color: initialOptions.color,

    setMake: make => set(state => {
        return {make: make}
    }),
    setTransmission: transmission => set(state => ({transmission: transmission})),
    setPrice: price => set(state => ({price: price})),
    setVatIncluded: vatIncluded => set(state => ({vatIncluded: vatIncluded})),
    setMileage: mileage => set(state => ({mileage: mileage})),
    setFuel: fuel => set(state => ({fuel: fuel})),
    setModel: model => set(state => ({model: model})),
    setYear: year => set(state => ({year: year})),
    setHistory: history => set(() => ({history: history})),
    setDrivetrain: drivetrain => set(state => ({drivetrain: drivetrain})),
    setPower: power => set(state => ({power: power})),
    setCountry: country => set(state => ({country: country})),
    setCity: city => set(state => ({city: city})),
    setFeatures: features => set(state => ({features: features})),
    setColor: color => set(state => ({color: color})),

    updateMultipleOptions: options => set(state => ({
        ...state, ...options
    })),

    updateOneResetOthers: (field, value) => set(state => ({
        // ...state,
        make: {...initialOptions.make},
        model: {...initialOptions.model},
        year: {...initialOptions.year},
        transmission: {...initialOptions.transmission},
        price: {...initialOptions.price},
        mileage: {...initialOptions.mileage},
        drivetrain: {...initialOptions.drivetrain},
        fuel: {...initialOptions.fuel},
        bodyStyle: {...initialOptions.bodyStyle},
        country: {...initialOptions.country},
        city: {...initialOptions.city},
        [field]: value
    })),

    updateSomeResetOthers: (fieldsObject) => set(() => ({
        make: initialOptions.make,
        model: {...initialOptions.model},
        year: {...initialOptions.year},
        transmission: {...initialOptions.transmission},
        price: {...initialOptions.price},
        mileage: {...initialOptions.mileage},
        drivetrain: {...initialOptions.drivetrain},
        fuel: {...initialOptions.fuel},
        bodyStyle: {...initialOptions.bodyStyle},
        country: {...initialOptions.country},
        city: {...initialOptions.city}, ...fieldsObject,
    })),

    updateOneOption: (field, value) => set(state => ({
        [field]: value
    }))

}));
export const useSellStore = create(set => ({

    make: {...initialOptions.make},
    year: {...initialOptions.year},
    // year: {...initialOptions.make},
    model: {...initialOptions.model},
    generation: {...initialOptions.generation},
    modification: {...initialOptions.modification},
    transmission: {...initialOptions.transmission},
    price: {value: '', visible: false, name: ''},
    vatIncluded: {...initialOptions.vatIncluded},
    ignoreSponsored: {...initialOptions.ignoreSponsored},
    mileage: {...initialOptions.mileage},
    fuel: {...initialOptions.fuel},
    // bodyStyle: {...initialOptions.bodyStyle},
    drivetrain: {...initialOptions.drivetrain},
    power: {...initialOptions.power},
    country: {...initialOptions.country},
    city: {...initialOptions.city},
    characteristic: '',
    vin: {...initialOptions.vin},
    displacement: {...initialOptions.displacement},
    comment: {...initialOptions.comment},
    features: initialOptions.features,
    history: initialOptions.history,
    color: initialOptions.color,
    // color: {value: [], visible: false, name: ''},

    setMake: make => set(state => {
        return {make: make}
    }),
    setTransmission: transmission => set(state => ({transmission: transmission})),
    setPrice: price => set(state => ({price: price})),
    setVatIncluded: vatIncluded => set(state => ({vatIncluded: vatIncluded})),
    setMileage: mileage => set(state => ({mileage: mileage})),
    setFuel: fuel => set(state => ({fuel: fuel})),
    setModel: model => set(state => ({model: model})),
    setYear: year => set(state => ({year: year})),
    setHistory: history => set(() => ({history: history})),
    setDrivetrain: drivetrain => set(state => ({drivetrain: drivetrain})),
    setDisplacement: displacement => set(state => ({displacement: displacement})),
    setPower: power => set(state => ({power: power})),
    setCountry: country => set(state => ({country: country})),
    setCity: city => set(state => ({city: city})),
    setFeatures: features => set(state => ({features: features})),
    setColor: color => set(state => ({color: color})),
    setGeneration: generation => set(state => ({generation: generation})),
    setModification: modification => set(state => ({modification: modification})),
    setCharacteristic: characteristic => set(state => ({characteristic: characteristic})),
    setVIN: vin => set(state => ({vin: vin})),
    setComment: comment => set(state => ({comment: comment})),

    updateMultipleOptions: options => set(state => ({
        ...state, ...options
    })),

    updateOneResetOthers: (field, value) => set(state => ({
        // ...state,
        make: {...initialOptions.make},
        model: {...initialOptions.model},
        year: {...initialOptions.year},
        transmission: {...initialOptions.transmission},
        price: {...initialOptions.price},
        mileage: {...initialOptions.mileage},
        drivetrain: {...initialOptions.drivetrain},
        fuel: {...initialOptions.fuel},
        bodyStyle: {...initialOptions.bodyStyle},
        country: {...initialOptions.country},
        city: {...initialOptions.city},
        [field]: value
    })),

    updateSomeResetOthers: (fieldsObject) => set(() => ({
        make: initialOptions.make,
        model: {...initialOptions.model},
        year: {...initialOptions.year},
        transmission: {...initialOptions.transmission},
        price: {...initialOptions.price},
        mileage: {...initialOptions.mileage},
        drivetrain: {...initialOptions.drivetrain},
        fuel: {...initialOptions.fuel},
        bodyStyle: {...initialOptions.bodyStyle},
        country: {...initialOptions.country},
        city: {...initialOptions.city}, ...fieldsObject,
    })),

    updateOneOption: (field, value) => set(state => ({
        [field]: value
    }))

}));
export const useSearchStore = create(set => ({
    searchResults: [],
    facets: {},
    isLoaded: initialSearch.isLoaded, //for skeleton or loader during search request
    sort: initialSearch.sort, //sort search results: price-asc, price-desc, year-asc, year-desc,
                              // mileage-asc,mileage-desc
    skip: initialSearch.skip,
    activePage: initialSearch.activePage,
    itemsPerPage: initialSearch.itemsPerPage,
    coreSearchString: '',
    setSearchResults: results => set(state => ({searchResults: results})),
    setFacets: facets => set(state => ({facets: facets})),
    setIsLoaded: isLoaded => set(state => ({isLoaded: isLoaded})),
    setSort: sort => set(state => ({sort: sort})),
    setSkip: skip => set(state => ({skip: skip})),
    setActivePage: activePage => set(state => ({activePage: activePage})),
    setCoreSearchString: coreSearchString => set(state => ({coreSearchString: coreSearchString})),
}));

export const useProfileStore = create(set => ({
    token: undefined,
    // entryModeManual: false,
    entryModeAutomatic: true,
    // cars: [],
    setToken: token => set(() => ({token: token})),
    // setEntryModeManual: entryModeManual => set(() => ({entryModeManual: entryModeManual})),
    // setCars: cars => set(() => ({cars: cars})),
    needsRefresh: false,
    setNeedsRefresh: needsRefresh => set(() => ({needsRefresh: needsRefresh})),
    tabIndex: 0, //tabs in Sell page
    setTabIndex: tabIndex => set(() => ({tabIndex: tabIndex})),
    setEntryModeAutomatic: entryModeAutomatic => set(() => ({entryModeAutomatic: entryModeAutomatic})),
}));

export const useErrorStore = create(set => ({
    errors: [],
    setErrors: errors => set(() => ({errors: errors})),
}));

export const useSellerDataStore = create(set => ({
    cars: [],
    setCars: cars => set(() => ({cars: cars})),
    sellerData: [],
    setSellerData: sellerData => set(() => ({sellerData: sellerData}))
}));