export const initialOptions = {
    "price": {"value": [], "visible": false, "name": ""},
    // "year": {"value": [], "visible": false, "name": ""}, //1980, 2023
    "year": {"value": "", "visible": false, "name": ""}, //1980, 2023
    "mileage": {"value": [], "visible": false},
    "power": {"value": [], "visible": false},
    "displacement": {"value": "", "visible": false},
    "fuel": {"value": "", "visible": false, "name": ""},
    "transmission": {"value": "", "visible": false, "name": ""},
    "make": {"value": "", "name": "", "visible": false},
    "model": {"value": "", "name": "", "visible": false},
    "generation": {"value": "", "name": "", "visible": false},
    "drivetrain": {"value": "", "name": "", "visible": false},
    "modification": {"value": "", "id": '0', "visible": false},
    "bodyStyle": {"value": "", "visible": false, "name": ""},
    "country": {"value": "", "visible": false, "name": ""},
    "city": {"value": "", "visible": false, "name": ""},
    "vin": {"value": "", "visible": false, "name": ""},
    "features":[],
    "history":[],
    "color": [],
    "searchResults": [],
    "vatIncluded": {"value": "", "visible": false}, // "ignoreSponsored": {"value": "any", "visible": false}
    "ignoreSponsored": {"value": "true", "visible": false},
    "comment": {"value": "", "visible": false},
};

export const initialSearch = {
    sort: "price-desc",
    skip: "",
    activePage: 0,
    searchResults: [],
    itemsPerPage: 7,
    facets: {},
    isLoaded: false
}