import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {Box, Button, Divider, Flex, GridItem, List, ListItem, SimpleGrid, Spacer, Text} from "@chakra-ui/react";

export const UpgradeModalHistory = ({car, upgradeHistory, setActivateArea, isPublished, upgradePeriod}) => {
    const {t} = useTranslation();

    useEffect(() => {
        console.log('upgradeHistory in history modal', upgradeHistory);
    }, []);

    return <Box>
        <Text>History</Text>
        <List fontSize='xs'>
            {upgradeHistory.values && upgradeHistory.values.map((order, index) =>
                <ListItem key={`order-${index}`} my={4} w='full'>
                    <SimpleGrid w='full'>
                        <GridItem w='full'>
                            <Flex>
                                <Text>VIN</Text>
                                <Spacer/>
                                <Text>{order.originalVIN || order.vin}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex>
                                <Text>id</Text>
                                <Spacer/>
                                <Text>{order.RowKey}</Text>
                            </Flex></GridItem>
                        <GridItem>
                            <Flex>
                                <Text>status</Text>
                                <Spacer/>
                                <Text>{order.advStatus}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex>
                                <Text>amount</Text>
                                <Spacer/>
                                <Text>{order.amount}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex>
                                <Text>end date</Text>
                                <Spacer/>
                                <Text>{order.endDate}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex>
                                <Text>payment date</Text>
                                <Spacer/>
                                <Text>{order.paymentDate}</Text>
                            </Flex>
                        </GridItem>
                    </SimpleGrid>
                    <Divider/>
                </ListItem>
            )}
        </List>
        <Flex w={'full'}>
            <Button variant='outline'
                    mt={1} spacing={4}
                    onClick={() => {
                        console.log('upgradePeriod', upgradePeriod);
                        setActivateArea('upgrade');
                        // onClose();
                    }}>
                Back
                {/*{t('sell.car-ad-card.upgrade-modal.')}*/}
            </Button>
        </Flex>
    </Box>
}