import React from 'react';
import {
    FormControl, Select,
} from '@chakra-ui/react';
import {ChevronDown} from '../../icons/20';

const SelectFieldSimple = ({label, options, handler, fieldFromStore, field}) => {

    // console.log('SelectFieldSimple:', fieldFromStore);

    const startYearAsText = fieldFromStore.value.length >= 1 ? fieldFromStore.value[0] : '';
    const endYearAsText = fieldFromStore.value.length === 2 ? fieldFromStore.value[1] : '';

    return <FormControl>
        <Select
            variant={(fieldFromStore.name && fieldFromStore.name.length > 1) ? 'selected' : 'primary'}
            icon={<ChevronDown/>}
            px={{field: {base: 3, md: 4}}}
            placeholder={label}
            value={field.startsWith('start-') ? startYearAsText : endYearAsText}
            onChange={handler}
            disabled={options.length < 2}
        >
            {options.map(({value, name, disabled, selected}, index) => {

                return <option
                    style={{marginLeft: !disabled && '0.5rem'}}
                    key={`${value}-${index}`}
                    disabled={disabled}>{value}
                </option>
            })}
        </Select>
    </FormControl>
}

export default React.memo(SelectFieldSimple);