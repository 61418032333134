import {tableAnatomy} from '@chakra-ui/anatomy'
import {baseTheme, createMultiStyleConfigHelpers} from '@chakra-ui/react'

const {definePartsStyle, defineMultiStyleConfig} =
    createMultiStyleConfigHelpers(tableAnatomy.keys)

const baseStyle = definePartsStyle({
    tbody: {
        tr: {
            '&:nth-of-type(odd)': {
                backgroundColor: 'neutral.25', // Or 'gray.50' directly
            },
            // border: '1px solid neutral.600',
            border: '1px solid',
            borderColor: 'neutral.200', // Use theme-aware color
            // py: 3,
        },
        td: {
            borderBottom: '1px solid',
            borderColor: 'neutral.200',
            borderRight: '1px solid',
            borderRightColor: 'neutral.200',
            // py: 4,
            '&:first-of-type': {
                color: 'neutral.750',
            },
            '&:last-of-type': {
                color: 'neutral.900',
            }
        }
    },
})

export const tableTheme = defineMultiStyleConfig({baseStyle})