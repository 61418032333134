import {selectAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

const {definePartsStyle, defineMultiStyleConfig} =
    createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({
    field: {
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 'md',
        fontSize: 'md',
    },
});

const primaryVariant = definePartsStyle({
    field: {
        border: '1px solid',
        borderColor: 'neutral.600',
        background: 'white',
        color: 'neutral.700',
        _hover: {
            borderColor: 'neutral.900',
            color: 'neutral.900'
        },
        _active: {
            borderColor: 'neutral.900',
            color: 'neutral.900'
        },
        _focus: {
            borderColor: 'neutral.900',
            color: 'neutral.900'
        },
    },
    icon: {
        color: 'neutral.700',
        w: 5,
    },
});

const selectedVariant = definePartsStyle({
    field: {
        border: '1px solid',
        borderColor: 'neutral.900',
        background: 'white',
        color: 'neutral.900'
    },
    icon: {
        color: 'neutral.700',
        w: 5,
    },
});

export const selectTheme = defineMultiStyleConfig({
    baseStyle,
    variants: {
        primary: primaryVariant,
        selected: selectedVariant
    },
});
