import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack
} from "@chakra-ui/react";
import React from "react";


export const DeleteAdvertisementModal = ({
                                        isOpen, onClose, deleteAdvertisement, isPublished
                                    }) => {

    return <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay>
            <ModalContent maxW={{base: '300px', md: '400px'}}>
                <ModalHeader>
                    <Text>Delete advertisement</Text>
                    <ModalCloseButton position='absolute' top='1rem'/>
                </ModalHeader>
                <ModalBody>
                    <VStack spacing={1} gap={10}>
                        <Text>Are you sure you want to delete this advertisement?</Text>
                        <Flex w='full' spacing='6' justifyContent='space-between'>
                            <Button variant='outline' onClick={onClose}>Cancel</Button>
                            <Button variant='primary'
                                    isDisabled={isPublished}
                                    onClick={deleteAdvertisement}>Delete</Button>
                        </Flex>
                    </VStack>
                </ModalBody>
                <ModalFooter></ModalFooter>
            </ModalContent>
        </ModalOverlay>
    </Modal>
}