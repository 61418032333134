import React, {useCallback} from 'react';
import {Card, CardHeader, Grid, Image, Text} from '@chakra-ui/react';

import {smallCardsList} from './smallCardsList';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useOptionsStore} from '../../state/store';

export const SmallCards = () => {

    const store = useOptionsStore();
    const navigator = useNavigate();
    const {t} = useTranslation(['texts']);

    const handleSmallCardClick = useCallback(async (options, e) => {
        e.preventDefault();
        console.log('normalOptions smallcards', options);

        store.updateSomeResetOthers(options);
        navigator('/');
    }, []);

    return <Grid
        w='full'
        id='small-cards-grid'
        templateColumns={{ base: 'repeat(2, 1fr)', sm: 'repeat(3, 1fr)', md: 'repeat(6, 1fr)' }}
        gap={{ base: 2, sm: 3 }}
        px={0}
    >
        {smallCardsList.map(({ type, img, options, title }, index) => {
            console.log('smallCardsList type', type, 'title', title);
            return (
                <Card
                    key={`${type}-${index}`}
                    variant="outline"
                    direction="column"
                    borderRadius="md"
                    overflow="hidden"
                    cursor="pointer"
                    gap={0}
                    onClick={(e) => handleSmallCardClick(options, e)}
                >
                    <CardHeader
                        px={{ base: 3, sm: 2, md: 3, lg: 6 }}
                        py={{ base: 4, md: 6 }}
                    >
                        <Text
                            textStyle={{ base: 'sm', sm: 'xs', md: 'sm', lg: 'lg' }}
                            fontWeight={500}
                            mb={0}
                            textTransform='capitalize'
                        >
                            {t(title)}
                        </Text>
                    </CardHeader>
                    <Image src={img} alt={`${type} logo`} />
                </Card>
            );
        })}
    </Grid>
}