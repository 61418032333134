export const colors = {
    primary: {
        50: '#FFF2F2',   // Extremely light pink, almost white
        100: '#FFE5E5',  // Very light pink
        200: '#FFCCCC',  // Light pink
        300: '#FFA3A3',  // Medium pink
        400: '#FF7A7A',  // Moderately saturated pink
        500: '#f44545',  // Primary color
        600: '#E23A3A',  // Darker primary
        700: '#CC3F3F',  // Deeper red
        800: '#992626',  // Dark red
        900: '#660D0D',  // Very dark red
    },
    primaryGreen: {
        500: '#A5FDAE',
        600: '#8BEC94',
        700: '#72D979',
        800: '#59C65F',
        900: '#40B345'
    },

    neutral: {
        25: '#F4F4F4',
        50: '#E3E3E3',
        100: '#ddd',
        200: '#d1d1d1',
        300: '#c8c8c8',
        400: '#C6C6C6',
        500: '#C7C7C7',
        // 600: '#b2b2b2',
        600: '#a8a8a8',
        700: '#7e7e7e',
        750: '#6D6D6D',
        800: '#4D4D4D',
        900: '#333',
        // 950:'#
        // 750: '#676767',
        // 800: '#878787',
        // 900: '#333',
    },
    white: '#fff',
};
