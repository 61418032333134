import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box, Button, Flex, HStack, IconButton, Image, Spacer, Text} from "@chakra-ui/react";
import stripe from "../img/stripe_logo.svg";
import paypal from "../img/PayPal.svg";
import {loadStripe} from "@stripe/stripe-js";
import StripeCheckoutForm2 from "./Stripe/StripeCheckoutForm2";
import {Elements} from "@stripe/react-stripe-js";
import {getPrice} from "../../../MyOffers/utils/offersHelper";
import {Paypal} from "./Paypal/Paypal";

export const UpgradeModalPayment = ({car, setActivateArea, upgradePeriod}) => {

    const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
    const stripePromise = loadStripe(stripePublicKey);

    const {t} = useTranslation();
    const [showPaymentOption, setShowPaymentOption] = useState(undefined);

    console.log('PaymentModal stripe_pk:', stripePublicKey);

    const amount = getPrice(upgradePeriod);

    const options = {
        theme: 'stripe'
    };


    return <Box>
        <Flex justifyContent='space-between' px={[3, 10]}>
            {!showPaymentOption &&
                <HStack justifyContent='space-between' h={'105px'} w='full'>
                    <IconButton aria-label={'stripe'} variant='ghost' p={8}>
                        <Image src={stripe} alt={'stripe'} boxSize='100px'
                               onClick={() => setShowPaymentOption('stripe')}
                        />
                    </IconButton>
                    <IconButton aria-label={'paypal'} variant='ghost' p={8}>
                        <Image src={paypal} alt={'paypal'} boxSize='100px'
                               onClick={() => setShowPaymentOption('paypal')}
                        />
                    </IconButton>
                </HStack>
            } {showPaymentOption === 'stripe' &&
            <Box w={'full'} my={5}>
                <Elements stripe={stripePromise} options={options}>
                    <StripeCheckoutForm2 amount={amount} car={car}/>
                </Elements>
            </Box>}
            {showPaymentOption === 'paypal' &&
                <Box w={'full'} my={5}
                     cursor='pointer'
                     userSelect='none'
                >
                    <Paypal amount={amount} car={car}/>
                </Box>}
        </Flex>
        <Flex w={'full'} pt={3}>
            <Button variant='outline'
                    mt={1} spacing={4}
                    onClick={() => {
                        console.log('back');
                        if (showPaymentOption === 'stripe' || showPaymentOption === 'paypal') {
                            setShowPaymentOption(undefined); // hide payment options
                            setActivateArea('payment'); // return to selection of payment options
                        } else setActivateArea('upgrade'); // return to upgrade page
                    }}>
                {t('common.back')}
                {/*{t('sell.car-ad-card.upgrade-modal.history')}*/}
            </Button>
        </Flex>
    </Box>
}