import React, {useCallback, useState, useEffect} from "react";
import {Box, Button, Divider, List, useToast} from "@chakra-ui/react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DraggableItem} from "../DraggableItem/DraggableItem";
import {saveImageResults} from "../MyOffers/utils/imageFunctions";
import {useTranslation} from "react-i18next";
import {useProfileStore} from "../../state/store";
import {useMsal} from "@azure/msal-react";

export const EditPhotos = ({car, onClose, isPublished}) => {
    const {t} = useTranslation(['texts']);
    const profileStore = useProfileStore();
    const {accounts} = useMsal();
    const toast = useToast();

    const [images, setImages] = useState(car.blobPaths || []);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setImages(car.blobPaths || []);
    }, [car.blobPaths]);

    const moveItem = useCallback((dragIndex, hoverIndex) => {
        setImages(prevImages => {
            const newImages = [...prevImages];
            const [removed] = newImages.splice(dragIndex, 1);
            newImages.splice(hoverIndex, 0, removed);
            return newImages;
        });
    }, []);

    const deleteItem = useCallback((index) => {
        setImages(prevImages => prevImages.filter((_, i) => i !== index));
    }, []);

    const handleSave = async () => {
        if (images.length === 0) {
            toast({
                title: t("sell.car-ad-card.edit-modal.no-images"),
                description: t("sell.car-ad-card.edit-modal.no-images-description"),
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        setIsLoading(true);
        try {
            await saveImageResults(car, images, images, accounts, profileStore.token);
            profileStore.setNeedsRefresh(true);
            toast({
                title: t("sell.car-ad-card.edit-images.update-successful"),
                status: "success",
                duration: 3000,
                isClosable: true,
                position: "top",
                autoHide: true,
            });
            onClose();
        } catch (error) {
            console.error("Error saving images:", error);
            toast({
                title: t("errors:edit-images.no-images-error"),
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top",
                autoHide: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const isSaveDisabled = isPublished || images.length === 0;

    return (
        <Box>
            <DndProvider backend={HTML5Backend}>
                <List>
                    {images.map((image, i) => (
                        <Box key={`movable-image-${i}`}>
                            <DraggableItem
                                index={i}
                                id={`image-${i}`}
                                value={image}
                                moveItem={moveItem}
                                deleteItem={deleteItem}
                            />
                            <Divider mt={[2, 0]} mb={4}/>
                        </Box>
                    ))}
                </List>
            </DndProvider>
            <Button
                variant='primary'
                isDisabled={isSaveDisabled}
                isLoading={isLoading}
                onClick={handleSave}
            >
                {t("sell.car-ad-card.edit-modal.footer.save-changes")}
            </Button>
        </Box>
    );
};