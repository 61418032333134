import React, {useState} from 'react';
import {
    TableContainer,
    Td,
    Table,
    Tr,
    Tbody,
    Box,
    Flex,
    Button,
    Text,
    Stack,
    Input,
    VStack, useToast
} from '@chakra-ui/react';
import {getTitleFromDictionary} from '../../integration/i18n/languageUtils';
import {useTranslation} from 'react-i18next';
import {AzureSellersRESTConnector} from "../../integration/tables/AzureSellersRESTConnector";
import {useProfileStore} from "../../state/store";
import {
    checkIsAliasValid,
    checkRegistrationNumberValid,
    checkIsCompanyAddressValid,
    checkIsPhoneValid
} from "./newSellerFormValidators";

export const ExistingSeller = ({sellerInfo, username, logout}) => {

    console.log('ExistingSeller sellerInfo', username, sellerInfo);

    const sellerConnector = new AzureSellersRESTConnector();
    const profileStore = useProfileStore();
    const token = profileStore.token;

    const {t} = useTranslation(['texts']);
    const sellerInfo_d = t('texts:vehicle-information.seller-info', {returnObjects: true});

    const nonEditable = ['email', 'type', 'logo'];
    delete sellerInfo.logo;

    const [editingMode, setEditingMode] = useState(false);
    const [sellerInfoState, setSellerInfoState] = useState(sellerInfo);

    const entriesArray = Object.entries(sellerInfoState);
    entriesArray.unshift(['email', username]);
    console.log('ExistingSeller entriesArray', entriesArray);

    const toast = useToast();

    const toggleEdit = () => {
        console.log('toggleEdit sellerInfoState.type', sellerInfoState.type);

        setEditingMode(async prevEditingMode => {
            setEditingMode(!prevEditingMode);

            if (prevEditingMode === true) {
                let errorMessage = undefined;

                if (!checkIsAliasValid(sellerInfoState.name)) {
                    errorMessage = t('errors:my-account.name-not-ok');
                }

                if (sellerInfoState.type === 'company') {

                    if (!checkIsAliasValid(sellerInfoState.name)) {
                        errorMessage = t('errors:my-account.name-not-ok');
                    }
                    if (!checkRegistrationNumberValid(sellerInfoState.registrationNumber)) {
                        errorMessage = t('errors:my-account.company-reg-number-not-ok');
                    }
                    if (!checkIsCompanyAddressValid(sellerInfoState.companyAddress)) {
                        errorMessage = t('errors:my-account.company-address-not-ok');
                    }
                    if (!checkIsPhoneValid(sellerInfoState.phone)) {
                        errorMessage = t('errors:my-account.phone-not-ok');
                    }
                }

                if (errorMessage) {
                    setEditingMode(true);
                    toast({
                        title: errorMessage,
                        status: 'error',
                        position: 'top',
                        duration: 5000,
                        autoHide: true,
                        isClosable: true,
                    });
                } else {
                    setEditingMode(false);
                    console.log('saving data...');
                    const updated = await sellerConnector.updateSeller(sellerInfoState, token);
                    console.log('saved ', updated);
                    toast(
                        {
                            title: t('sell.my-account-area.data-saved'),
                            status: 'success',
                            position: 'top',
                            duration: 5000,
                            autoHide: true,
                            isClosable: true,
                        }
                    );
                }
            }
        });
    }

    const handleChange = (key, e) => {
        setSellerInfoState(prevSellerInfoState => {
            return {...prevSellerInfoState, [key]: e.target.value}
        });
    }

    return <Box>
        <TableContainer py={4}>
            <Table>
                <Tbody>
                    {entriesArray.map(([key, value]) => {
                        return <Tr key={key}>
                            <Td px={[2, 4]} maxW={['250px', 'auto']}>
                                <Stack direction={['column', 'row']} align={['start', 'center']} w={'full'}>
                                    <Text minW={['auto', '175']}>{getTitleFromDictionary(key, sellerInfo_d)}</Text>
                                    {editingMode && !nonEditable.includes(key) ?
                                        <Input
                                            autoFocus={key === 'name'}
                                            placeholder={getTitleFromDictionary(key, sellerInfo_d)}
                                            value={value}
                                            onChange={(e) => handleChange(key, e)}
                                            variant={value ? 'primaryActive' : 'primary'}
                                        /> : <Text>{getTitleFromDictionary(value, sellerInfo_d)}</Text>
                                    }
                                </Stack>
                            </Td>
                        </Tr>
                    })}
                </Tbody>
            </Table>
        </TableContainer>
        <Flex justify='space-between' my={2}>
            <Button variant='primary'
                    size='sm'
                    onClick={() => toggleEdit()}>
                {editingMode
                    ? <Text>{t('sell.my-account-area.save-data')}</Text>
                    : <Text>{t('sell.car-ad-card.edit-modal.update')}</Text>}
            </Button>
            <VStack>
                <Flex justifyContent='end' w='full'>
                    <Button variant='outline'
                            size='sm'
                            onClick={() => logout()}>
                        {t('sell.logout-title')}
                    </Button>
                </Flex>
                <Text fontSize={10} position='relative'
                      left={0}
                      top={1}>{t('sell.logged-in-as')} {username}</Text>
            </VStack>
        </Flex>
    </Box>
}