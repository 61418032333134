import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys)

const baseStyle = definePartsStyle({
  container: {
    backgroundColor: 'white',
    rounded: "xl",
    gap: 6,
    p: 5,
    shadow: "100",
  },
  header: {
    textStyle: "h2",
    fontWeight: 600,
    p: 0,
  },
  body: {
    p: 0,
  },
  footer: {
    p: 0,
  },
});

const sizes = {
  md: definePartsStyle({
    container: {
      borderRadius: '0px',
    },
  }),
}

const outlineVariant = definePartsStyle({
  container: {
    borderColor: "neutral.100",
    transition: "border-color 200ms ease-in-out",
    shadow: "none",
    borderWidth: "1px",
    p: 0,
  }
})

const variants = {
  outline: outlineVariant,
}

export const cardTheme = defineMultiStyleConfig({ baseStyle, sizes, variants })