import {Box, IconButton, Text, useBreakpointValue} from "@chakra-ui/react";
import React from "react";
import {IoHeart} from "react-icons/io5";

export const FavouriteIcon = ({favourites, hideBelow, hideFrom, handleNavigation, ...props}) => {
    return (
        <IconButton
            hideBelow={hideBelow}
            hideFrom={hideFrom}
            aria-label={'favourites'}
            isRound={true}
            size="sm"
            variant="ghost"
            marginRight={{md: -1}}
            onClick={() => handleNavigation("/favourites")}
            {...props}
            icon={
                <Box position="relative">
                    <IoHeart size={'24px'} fill='#f44545'/>
                    {favourites.length > 0 && (
                        <Text
                            as="span"
                            position="absolute"
                            top="50%"
                            left="50%"
                            transform="translate(-50%, -50%)"
                            color="white"
                            fontSize="8px"
                        >
                            {favourites.length}
                        </Text>
                    )}
                </Box>
            }
        />
    );
};
