import React, { useState } from 'react';
import {
    Container,
    Heading,
    Stack,
    Text,
    VStack,
    HStack,
    Link as ChakraLink,
    OrderedList,
    ListItem
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import { useTranslation } from "react-i18next";

export const HowToPartner = () => {
    const { t } = useTranslation(['support', 'texts']);

    const [active, setActive] = useState('how-to-partner');

    return (
        <Container maxW="container.xl" my={[4, 9]}>
            <Stack direction={{ base: 'column', md: 'row' }} align='start' spacing={[8, 12]} w="full">
                <Sidebar active={active} setActive={setActive} linksType='support' />
                <VStack align="start" maxW={{ base: 'full', md: '60%' }} gap={4}>
                    <Heading as='h2' size="md" textTransform='capitalize'>
                        {t('support.sell.how-to-become-a-partner')}
                    </Heading>
                    <OrderedList align="start" spacing={2} pl={4} styleType="disc">
                        <ListItem>{t('support.sell.use-our-platform-at-your-website')}</ListItem>
                        <ListItem>{t('support.sell.batch-import-of-your-stock')}</ListItem>
                        <ListItem>{t('support.sell.you-are-photographer-or-car-expert')}</ListItem>
                    </OrderedList>
                    <Text>
                        {t('support.sell.we-are-open-for-collaboration')}
                    </Text>
                </VStack>
            </Stack>
        </Container>
    );
};