export const updateFavouriteInLocalStorage = RowKey => {
    // Retrieve the favorites from localStorage
    const favourites = JSON.parse(localStorage.getItem('favourites')) || [];

    // Check if RowKey is already in favorites
    if (favourites.includes(RowKey)) {
        // Remove the RowKey from the favorites
        const updatedFavourites = favourites.filter(fav => fav !== RowKey);

        // Save the updated favorites back to localStorage
        localStorage.setItem('favourites', JSON.stringify(updatedFavourites));

        console.log(`${RowKey} has been removed from your favourites.${localStorage.getItem('favourites')}`);
        window.dispatchEvent(new Event('storage')); // Trigger custom storage event

        return false;
    } else {
        // Add the RowKey to the favorites
        favourites.push(RowKey);

        // Save the updated favorites back to localStorage
        localStorage.setItem('favourites', JSON.stringify(favourites));

        console.log(`${RowKey} has been added to your favourites.${localStorage.getItem('favourites')}`);

        window.dispatchEvent(new Event('storage')); // Trigger custom storage event

        return true;
    }

}
