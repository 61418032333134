export const pricesFormatted = [
    { value: "", name: "", disabled: true, selected: false },
    { value: "90000", name: "90 000" },
    { value: "80000", name: "80 000" },
    { value: "75000", name: "75 000" },
    { value: "65000", name: "65 000" },
    { value: "60000", name: "60 000" },
    { value: "55000", name: "55 000" },
    { value: "50000", name: "50 000" },
    { value: "45000", name: "45 000" },
    { value: "40000", name: "40 000" },
    { value: "35000", name: "35 000" },
    { value: "30000", name: "30 000" },
    { value: "25000", name: "25 000" },
    { value: "20000", name: "20 000" },
    { value: "15000", name: "15 000" },
    { value: "12500", name: "12 500" },
    { value: "10000", name: "10 000" },
    { value: "9000", name: "9 000" },
    { value: "8000", name: "8 000" },
    { value: "7000", name: "7 000" },
    { value: "6000", name: "6 000" },
    { value: "5000", name: "5 000" },
    { value: "1000", name: "1 000" },
];