import React, { useEffect, useState } from 'react';
import {Container, Flex, Box, Text, Heading, Stack} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {Sidebar} from "../Sidebar";

const Section = ({ title, children }) => (
    <Box mb={6}>
        {title && <Heading as="h2" size="md" mb={4}>{title}</Heading>}
        <Text>{children}</Text>
    </Box>
);

export const Legal = () => {
    const { t } = useTranslation(['support', 'texts']);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [active, setActive] = useState('legal');

    return (
        <Container maxW="container.xl" my={[4, 9]}>
            <Stack direction={{base: 'column', md: 'row'}}
                   align='start'
                   spacing={[8, 12]}
                   w="full"
            >
                <Sidebar active={active} setActive={setActive} />
                <Flex direction="column" maxW={{base: '100%', md: '60%'}}>
                    <Section title={t('texts:footer.legal')}>
                        {t('company.legal.the-platform')}
                    </Section>
                    <Section title={t('company.legal.definitions')}>
                        {t('company.legal.caruniverse-platform-system')}
                        <br/><br/>
                        {t('company.legal.users-seek-or-add-cars')}
                        <br/><br/>
                        {t('company.legal.two-categories-of-users')}
                        <br/><br/>
                        {t('company.legal.datastore')}
                        <br/><br/>
                        {t('company.legal.data-includes-photos-and-metadata')}
                    </Section>
                    <Section title={t('company.legal.services')}>
                        {t('company.legal.user-interaction-with-system')}
                        <br/><br/>
                        {t('company.legal.user-data-and-system-provided-data')}
                        <br/><br/>
                        {t('company.legal.listings-are-persisted')}
                        <br/><br/>
                        {t('company.legal.sellers-can-upgrade-ads')}
                    </Section>
                    <Section title={t('company.legal.scope')}>
                        {t('company.legal.technical-enabler')}
                        <br/><br/>
                        {t('company.legal.system-conveys-sellers-data')}
                        <br/><br/>
                        {t('company.legal.not-responsible-for-buyers-misinformation')}
                        <br/><br/>
                        {t('company.legal.not-responsible-for-inaccurate-specs')}
                        <br/><br/>
                        {t('company.legal.not-responsible-for-data-loss')}
                    </Section>
                    <Section title={t('company.legal.responsibility-of-a-user')}>
                        {t('company.legal.users-agree-not-to-misuse-the-system')}
                        <br/><br/>
                        {t('company.legal.users-should-not-copy-data')}
                        <br/><br/>
                        {t('company.legal.visitors-should-not-spam-sellers-via-our-messaging-system')}
                        <br/><br/>
                        {t('company.legal.forbidden-to-upload-non-vehicle-images')}
                    </Section>
                </Flex>
            </Stack>
        </Container>
    );
};