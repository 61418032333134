export const textStyles = {
  h1: {
    fontWeight: '400',
    fontSize: ['2xl', '2xl', '3xl'],
    lineHeight: ['2rem', '2rem', '2.375rem'],
  },
  h2: {
    fontWeight: '600',
    fontSize: ['md', 'md', 'xl'],
    lineHeight: '1.75rem',
  },
  h3: {
    fontWeight: '600',
    fontSize: ['md', 'md', 'lg'],
    lineHeight: '1.75rem',
  },
  lg: {
    fontSize: 'lg',
    lineHeight: '1.75rem',
  },
  base: {
    fontSize: 'md',
    lineHeight: '1.5rem',
  },
  sm: {
    fontSize: 'sm',
    lineHeight: '1.5rem',
  },
  xs: {
    fontSize: 'xs',
    lineHeight: '1.125rem',
  },
  variants: {
    popularBlockTitle: {
      fontWeight: '600',
      fontSize: ['md', 'md', 'xl'],
      lineHeight: '1.75rem',
      mb: [4, 6],
      textTransform: 'uppercase',
    },
    // cardheading: {
    //   fontSize: '20px',
    //   color: 'brand.primaryBlack',
    //   fontWeight: 'bold',
    //   textTransform: 'uppercase',
    // },
    // smallcard: {
    //   fontSize: { base: '12px', sm: '14px' },
    //   fontWeight: '500',
    // },
    // largecard: {
    //   fontSize: '18px',
    //   fontWeight: '500',
    // },
    // modelListSubtitle: {
    //   fontSize: '20px',
    //   fontWeight: 'bold',
    // },
    // modelsList: {
    //   fontSize: '14px',
    //   fontWeight: '400',
    //   textTransform: 'uppercase',
    // },
    // modelsListCount: {
    //   color: 'brand.grey700',
    // },
    // modelsListNone: {
    //   color: 'brand.grey600',
    // },
    footerHeading: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: 'white',
    },
  },
};
