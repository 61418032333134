import { drawerAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  overlay: {
    bg: 'blackAlpha.700',
  },
  header: {
    bg: "white",
    p: 6,
    shadow: 500,
    zIndex: 10
  },
  closeButton: {
    bg: 'transparent',
    color: 'neutral.700',
    border: '0',
    px: 0,
    minW: 0,
    rounded: "sm",
    _hover: {
        bg: 'neutral.50',
    },
    _active: {
        bg: 'neutral.100',
    },
    position: "relative",
    top: "auto",
    right: "auto",
  },
  body: {
    bg: "#f4f4f4",
    p: 6,
  }
})

export const drawerTheme = defineMultiStyleConfig({
  baseStyle,
})