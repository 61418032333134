import {
    Menu,
    MenuButton,
    MenuList,
    Button,
    MenuOptionGroup,
    MenuItemOption,
    Text, Flex, HStack,
} from "@chakra-ui/react";

import {ChevronDown} from "../../icons";

import {useTranslation} from "react-i18next";
import {useSearchStore} from "../../state/store";
import {ImSortAlphaAsc, ImSortAmountDesc, ImSortNumbericDesc} from "react-icons/im";

export const SearchListSort = () => {
    const searchStore = useSearchStore();
    const {t} = useTranslation(["data-for-ui"]);
    const sort_d = t("sort", {returnObjects: true});

    // console.dir('sort_d', sort_d);

    return (
        <Menu isLazy={true}
              placement={'bottom-end'}>
            <MenuButton
                iconSpacing={1}
                variant="menu"
                as={Button}
                rightIcon={<ChevronDown/>}
                hideBelow="md"
            >
                <HStack align='center'>
                    <ImSortAlphaAsc color="grey"/>
                    <Text as="span"
                          color="neutral.900"
                          size={'sm'}
                    >
                        {sort_d[searchStore.sort].title}
                    </Text>
                </HStack>
            </MenuButton>
            <MenuList id='sort-menu-list' boxShadow='lg'>
                <MenuOptionGroup type="radio">
                    {Object.entries(sort_d).map((entry) => {
                        const key = entry[0];
                        const value = entry[1];
                        return (
                            <MenuItemOption
                                key={key}
                                onClick={() => searchStore.setSort(key)}
                                value={searchStore.sort}
                                fontSize='sm'
                                py={2}
                                sx={{
                                    _focus: {
                                        backgroundColor: 'neutral.700',
                                        color: 'white' //
                                    },
                                    _hover: {
                                        backgroundColor: 'neutral.700',
                                        color: 'white'
                                    }
                                }}
                            >
                                {value.title}
                            </MenuItemOption>
                        );
                    })}
                </MenuOptionGroup>
            </MenuList>
        </Menu>
    );
};