import {inputAnatomy} from "@chakra-ui/anatomy";
import {createMultiStyleConfigHelpers} from "@chakra-ui/react";

const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
    field: {
        border: "1px solid",
        borderColor: "neutral.500",
        background: "white",
        color: "neutral.700",
        _hover: {
            borderColor: "neutral.900",
            color: "neutral.900",
        },
        _active: {
            borderColor: "neutral.900",
            color: "neutral.900",
        },
        _focus: {
            borderColor: "neutral.900",
            color: "neutral.900",
        },
        _placeholder: {
            color: "neutral.700",
        },
        _disabled: {
            color: "neutral.400",
            borderColor: "neutral.600",
        },
    },
    icon: {
        color: "neutral.700",
        w: 5,
    },
});

const primaryStyle = definePartsStyle({
    field: {
        border: "1px solid",
        borderColor: "neutral.500",
        background: "white",
        color: "neutral.700",
        _hover: {
            borderColor: "neutral.900",
            color: "neutral.900",
        },
        _active: {
            borderColor: "neutral.500",
            color: "neutral.500",
        },
        _focus: {
            borderColor: "neutral.900",
            color: "neutral.900",
        },
        _placeholder: {
            color: "neutral.700",
        },
        _disabled: {
            color: "neutral.400",
            borderColor: "neutral.600",
        },
    },
    icon: {
        color: "neutral.700",
        w: 5,
    },
});

const primaryActiveStyle = definePartsStyle({
// _active not working in primary,its a workaround
    field: {
        border: "1px solid",
        borderColor: "neutral.900",
        background: "white",
        color: "neutral.900",
        _hover: {
            borderColor: "neutral.900",
            color: "neutral.900",
        },
    },
    icon: {
        color: "neutral.700",
        w: 5,
    },
});

export const inputTheme = defineMultiStyleConfig({
    baseStyle,
    variants: {
        base: baseStyle,
        primary: primaryStyle,
        primaryActive: primaryActiveStyle,
    },
});