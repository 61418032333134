import React, {useEffect, useState} from 'react';
import {SearchResultListItem} from "../SearchResultListItem/SearchResultListItem";
import {Button, SimpleGrid, useDisclosure, VStack} from "@chakra-ui/react";
import {checkIfPublished, processUpgradeOrders, unpublishCarFromSearch} from "./utils/offersHelper";
import {useProfileStore} from "../../state/store";
import {publishDocument} from "../../integration/search/AzureSearchConnector";
import {DeleteAdvertisementModal} from "../Modals/Sell/DeleteAdvertisementModal";
import {AzureAdvertisementsRESTConnector} from "../../integration/tables/AzureAdvertisementsRESTConnector";
import {UpdateAdvertisementModal} from "../Modals/Sell/UpdateAdvertisementModal";
import {UpgradeAdvertisementModal} from "../Modals/Sell/UpgradeAdvertisementModal";
import {AzureUpgradeOrdersRESTConnector} from "../Modals/Sell/UpgradeOrdersRESTConnector";
import {useTranslation} from "react-i18next";


export const CarAdvCard = ({car}) => {

    // console.log('CarAdCard', car);

    const {t} = useTranslation(['texts']);

    const profileStore = useProfileStore();
    const token = profileStore.token;

    const tableConnector = new AzureAdvertisementsRESTConnector();
    const ordersConnector = new AzureUpgradeOrdersRESTConnector();

    const [isPublished, setIsPublished] = useState(false);
    const [upgradeHistory, setUpgradeHistory] = useState([]);

    const [isPublishLoading, setIsPublishLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const handleOpenModal = (modalName) => {
        setActiveModal(modalName);
        onOpen();
    };

    const updateAdvertisement = async () => {
        console.log('updateAdvertisement', car);
    }

    const checkUpgradeOrders = async () => {
        console.log('checkUpgradeOrders', car.vin);
        const orders = await ordersConnector.retrieveUpgradeOrders(car.vin, token);
        const processedOrders = processUpgradeOrders(orders);
        console.log('checkUpgradeOrders processed orders', processedOrders);
        setUpgradeHistory(processedOrders);
    }

    /** initial car adv card load - check if published and history */
    useEffect(() => {
        console.log('CarAdCard useEffect, []', car.vin);
        const checkPublished = async () => {
            const status = await checkIfPublished(car, token);
            setIsPublished(status);
            console.log('checkPublished status', status);
        }
        checkPublished();
        checkUpgradeOrders();
    }, [car]);

    const togglePublish = async () => {
        console.log('togglePublish', car.vin);
        if (isPublished) {
            try {
                setIsPublishLoading(true);
                const copy = {...car};
                const status = await unpublishCarFromSearch(copy.vin, token);
                console.log('status after unpublish', status);
                setIsPublished(false);
            } finally {
                setIsPublishLoading(false);
            }
        } else {
            try {
                setIsPublishLoading(true);
                const copy = {...car};
                const status = await publishDocument(copy, token);
                console.log('status after publish', status.value[0].status);
                setIsPublished(true);
            } finally {
                setIsPublishLoading(false);
            }
        }
    }

    const deleteAdvertisement = async () => {
        try {
            setIsDeleteLoading(true);
            console.log('deleteAdvertisement', car.vin, car.make, token);
            const deletionResult =
                await tableConnector.deleteAdvertisementRecord(car.make, car.vin, token);
            onClose();
            profileStore.setNeedsRefresh(true); //refresh MyOffers, call fetchCars
            console.log('deletion result', deletionResult);
        } finally {
            setIsDeleteLoading(false);
        }
    }


    const {isOpen, onOpen, onClose} = useDisclosure();
    const [activeModal, setActiveModal] = useState('');


    return <><VStack spacing={2}
                     w='full'
                     id='car-ad-card-vstack'
    >
        <SearchResultListItem resultItem={car} isPublished={isPublished}/>
        <SimpleGrid columns={[2, 4]} gap={4} borderRadius={6} w={['full', 'auto']}>
            <Button variant='outline'
                    isLoading={isPublishLoading}
                    onClick={togglePublish}>
                {isPublished
                    ? t('sell.car-ad-card.unpublish')
                    : t('sell.car-ad-card.publish')
                }
            </Button>
            <Button variant='outline'
                    isDisabled={isPublished}
                    onClick={() => handleOpenModal('update')}>
                {t('sell.car-ad-card.edit-modal.update')}
            </Button>
            <Button variant='outline'
                    isDisabled={isPublished}
                    isLoading={isDeleteLoading}
                    onClick={() => handleOpenModal('delete')}>
                {t('sell.car-ad-card.delete')}
            </Button>
            <Button variant='outline'
                    isDisabled={!isPublished}
                    onClick={() => handleOpenModal('upgrade')}>
                {t('sell.car-ad-card.upgrade')}
            </Button>
        </SimpleGrid>
    </VStack>

        {activeModal === 'delete' && <DeleteAdvertisementModal
            isOpen={isOpen}
            deleteAdvertisement={deleteAdvertisement}
            isPublished={isPublished}
            onClose={() => {
                onClose();
                setActiveModal('');
            }}/>}
        {activeModal === 'update' && <UpdateAdvertisementModal
            isOpen={isOpen}
            car={car}
            updateAdvertisement={updateAdvertisement}
            isPublished={isPublished}
            onClose={() => {
                onClose();
                setActiveModal('');
            }}/>
        }
        {
            activeModal === 'upgrade' && <UpgradeAdvertisementModal
                isOpen={isOpen}
                car={car}
                updateAdvertisement={updateAdvertisement}
                upgradeHistory={upgradeHistory}
                isPublished={isPublished}
                onClose={() => {
                    onClose();
                    setActiveModal('');
                }}/>
        }
    </>

}




