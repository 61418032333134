import React from 'react';
import {
    Button,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";
import {useProfileStore} from "../../../state/store";
import {useTranslation} from "react-i18next";

export const SuccessModal = ({isOpen, onClose}) => {

    const {t} = useTranslation(['texts']);

    const profileStore = useProfileStore();


    const handleCreateNewOffer = () => {
        onClose();
    }

    const handleReviewListings = () => {
        profileStore.setTabIndex(0);
        window.scrollTo(0, 0);
        onClose();
    }


    return <Modal isOpen={isOpen}
                  onClose={onClose}
                  isCentered
                  size='lg'
                  motionPreset="slideInBottom"
    >
        <ModalOverlay/>
        <ModalContent>
            <ModalHeader>{t('sell.car-added.successfully-added')}</ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
                {t('sell.car-added.message')}
            </ModalBody>
            <ModalFooter>
                <HStack w='full' justify='space-between'>
                    <Button variant="primary" mr={3} onClick={handleCreateNewOffer}
                    >
                        {t('sell.car-added.add-another')}
                    </Button>
                    <Button variant="outlineSecondary" onClick={handleReviewListings}>
                        {t('sell.car-added.check-my-offers')}
                    </Button>
                </HStack>
            </ModalFooter>
        </ModalContent>
    </Modal>
}