import React from "react";
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerCloseButton,
    DrawerOverlay,
    DrawerHeader,
    HStack,
    Text,
    VStack,
    IconButton,
} from "@chakra-ui/react";
import {Close} from "../../../icons";
import {SearchFilter} from "../../SearchFilter/SearchFilter";
import {useTranslation} from "react-i18next";
import {useOptionsStore} from "../../../state/store";
import {initialOptions} from "../../../state/initialstate";

const SearchModal = ({isOpen, onClose}) => {

    const {t} = useTranslation(["texts"]);
    const optionsStore = useOptionsStore();

    return <Drawer isOpen={isOpen} onClose={onClose} placement="left">
        <DrawerOverlay/>
        <DrawerContent maxW={412} w="full">
            <DrawerHeader as={VStack} spacing={2} align="start" w="full">
                <HStack align="center" w="full" justify="space-between">
                    <Text>{t('search.search-area.search-filters')}</Text>
                    <DrawerCloseButton
                        as={IconButton}
                        icon={<Close/>}
                        variant="ghost"
                        size="xs"
                    />
                </HStack>
                <HStack w="full">
                    <Button id='Clear'
                            variant="outline" w="full"
                            onClick={() => optionsStore.updateMultipleOptions(initialOptions)}
                    >
                        {t('sell.clear')}
                    </Button>
                    <Button id='Search'
                            variant="primary" w="full"
                            onClick={onClose}
                    >
                        {t('nav.search')}
                    </Button>
                </HStack>
            </DrawerHeader>
            <DrawerBody as={VStack} w="full" align="start" spacing={4}>
                <SearchFilter/>
            </DrawerBody>
        </DrawerContent>
    </Drawer>
};

export default SearchModal;