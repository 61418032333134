import {
    HStack, Tag, TagLabel, TagCloseButton, Button,
} from "@chakra-ui/react";
import {useOptionsStore} from '../../state/store';
import {isEmpty} from 'lodash';
import {transformTagValue} from './filterTagsUtils';
import {useTranslation} from 'react-i18next';
import {initialOptions} from '../../state/initialstate';
import {startTransition, useEffect, useState} from "react";

export const FilterTags = () => {

    const optionsStore = useOptionsStore();
    const {t} = useTranslation(['texts']);

    const [normalOptions, setNormalOptions] = useState([]);
    const [multiselectOptions, setMultiselectOptions] = useState([]);

    useEffect(() => {
        console.log('FilterTags useEffect [optionsStore]');

        const normalOptions = Object.entries(optionsStore)
            .filter(([key, value]) => typeof optionsStore[key] !== 'function' && value.visible)
            .map(([key, value]) => transformTagValue(key, value));

        const multiselectOptions = Object.entries(optionsStore)
            .filter(([key, value]) => typeof optionsStore[key] !== 'function' && typeof value === 'object' && value.length > 0)
            .flatMap(item => {
                    return item[1].map(subItem => ({
                        key: item[0],
                        value: subItem.value,
                        name: subItem.name,
                    }))
                }
            );

        // console.log('multiselectOptions', multiselectOptions, normalOptions);

        startTransition(() => {
            setNormalOptions(normalOptions);
            setMultiselectOptions(multiselectOptions);
        });

    }, [optionsStore]);


    if (isEmpty(normalOptions) && isEmpty(multiselectOptions)) { //if no tags are selected, don't show clear all button
        return null;
    } else return <HStack id='filter-tags-stack'
                          // spacing={4}
                          flexWrap="wrap"
                          w="full"
                          pt={1}
                          pb={2}>

        {normalOptions.map(({key, value}) => { //list of normal tags

            return <Tag key={key}>
                <TagLabel>{value}</TagLabel>
                <TagCloseButton onClick={() => {
                    optionsStore.updateOneOption(key, initialOptions[key]);
                }}/>
            </Tag>
        })}
        {multiselectOptions.map(({key, value, name}) => { //list of multiselect tags (history, features, colors)
            return <Tag key={`${key}-${value}`}>
                <TagLabel>{name}</TagLabel>
                <TagCloseButton onClick={() => {
                    const copy = [...optionsStore[key]];
                    const index = copy.findIndex(el => el.value === value);
                    copy.splice(index, 1);
                    optionsStore.updateOneOption(key, copy);
                }}/>
            </Tag>
        })}
        <Button id='clear-all-fields' variant="plainRed"
                size="xs"
                px={2}
                fontSize="xs"
                fontWeight={400}
                onClick={() => optionsStore.updateMultipleOptions(initialOptions)}>
            {t('texts:search.search-area.reset')}
        </Button>
    </HStack>
}