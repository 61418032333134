import {
    Checkbox,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Text,
    VStack
} from '@chakra-ui/react';
import React from 'react';
import {useDictionaryStore} from '../../../state/store';
import {useShallow} from 'zustand/react/shallow';
import {useTranslation} from "react-i18next";

const HistoryModal = ({isOpen, onClose, history, setHistory}) => {


    const histories = useDictionaryStore(useShallow((store) => store.history));
    console.log('History modal', history, histories);
    // const sellStore = useSellStore();

    const {t} = useTranslation(['texts']);
    const changeHistory = (e, historyName) => {

        console.log('History modal changeHistory', e.target.checked, historyName);


        if (e.target.checked) {

            /** Add to the store if checked */
            const copy = [...history];
            if (!copy.includes(historyName)) {
                copy.push(historyName);
                setHistory(copy);
            }



        } else {

            /** Remove if unchecked */
            const copy = [...history];
            const index = copy.indexOf(historyName);
            if (index !== -1) {
                copy.splice(index, 1);
                setHistory(copy);
            }

        }
        console.log('History modal changeHistory 1', history);
    };

    return <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay/>
        <ModalContent maxW={412} w="full">
            <ModalHeader>{t('sell.input-fields.history')}</ModalHeader>
            <ModalCloseButton/>
            <ModalBody as={VStack} w="full" align="start" spacing={4}>
                <SimpleGrid columns={1} spacing={4} w="full">
                    {histories.map((hst, index) => {
                        // console.log('History modal map', hst, history);
                        return (<Checkbox
                            size="lg"
                            spacing="1rem"
                            colorScheme="primary"
                            isChecked={history.includes(hst)}
                            key={'history-' + index}
                            onChange={(e) => {
                                changeHistory(e, hst);
                            }}
                        >
                            <Text fontSize="14px">{hst.name}</Text>
                        </Checkbox>);
                    })}
                </SimpleGrid>
            </ModalBody>
            <ModalFooter></ModalFooter>
        </ModalContent>
    </Modal>
}

export {HistoryModal}