import React, {useEffect, useState} from 'react';
import {useNavigate, useNavigationType, useParams} from "react-router-dom";
import * as AzureSearchConnector from "../integration/search/AzureSearchConnector";
import {Box, Center, Container, Flex, IconButton, Link, useBoolean, useBreakpointValue, VStack} from "@chakra-ui/react";
import {CarDetails} from "../components/Car/CarDetails";
import {extractIdFromUrl} from "../components/Car/carUtils";
import {Gallery2} from "../components/Gallery/Gallery2";
import {CarMetadataArea2} from "../components/Car/CarMetadataArea2";
import {CarPlaceholderBlock} from "../components/Modals/Car/CarPlaceholderBlock";
import {useTranslation} from "react-i18next";
import {IoMdArrowBack} from "react-icons/io";
import {PictureCounterBox} from "../components/PictureCounterBox/PictureCounterBox";

export const Car = () => {

    const {t} = useTranslation(['texts']);
    const {id} = useParams();
    const [car, setCar] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [isError, setIsError] = useState(false);

    const [showGallery, setShowGallery] = useBoolean(false);
    const [initialGalleryIndex, setInitialGalleryIndex] = useState(0);
    const navigate = useNavigate();
    const navigationType = useNavigationType();
    const showBackButton = navigationType !== 'POP';

    useEffect(() => {
        console.log('Car page useEffect [id]', id);

        const carID = extractIdFromUrl(id);

        // const parsedIDObject = parseCarIDfromURL(id);
        // setParams(params);
        console.log('Car page parsedIDObject', carID);

        const fetchCarData = async id => {
            try {
                const response = await AzureSearchConnector.getPublicDocument(id);
                const carData = await response.json();
                if (carData) {
                    setCar(carData);
                    console.log('Car page fetchCarData no carData', carData);
                    setIsLoaded(true);

                }
            } catch (e) {
                console.log('Car page fetchCarData error', e);
                setIsError(true);
                setIsLoaded(true);
            }

        };
        window.scrollTo(0, 0);
        fetchCarData(carID);

    }, [id]);

    const isSmallScreen = useBreakpointValue({base: true, md: false});

    const backLinkHandler = () => navigate(-1);

    return <Container maxW="container.xl" mt={{base: 1, md: 0}} mb={{base: 4, md: 4}}
                      py={0}
                      id="car-container">
        {!isLoaded && <CarPlaceholderBlock/>}

        {isLoaded && !isError
            && <VStack w='full' spacing={{base: 0, md: 4}} id='car-vstack'>
                {showGallery
                    ? <Gallery2 images={car.blobPaths}
                                setShowGallery={setShowGallery} //hide gallery on back button click
                                imageIndex={initialGalleryIndex}// prepare gallery to show image at index
                    />
                    : <Box w='full'>
                        {!isSmallScreen && <Box mt={0} mb={{
                            base: 2,
                            md: 0
                        }}> {/*Back button visible on desktop, not needed on mobile*/}
                            <IconButton aria-label={'Back'}
                                        rounded='full'
                                        icon={<IoMdArrowBack size='24'/>}
                                        colorScheme='gray'
                                        variant='ghost'
                                        onClick={() => navigate(-1)}/>

                        </Box>}
                        {isSmallScreen
                            ? <Gallery2 images={car.blobPaths}
                                        setShowGallery={setShowGallery} /** on mobile, skip CarDetails*/
                                        imageIndex={initialGalleryIndex}
                                        backLink={backLinkHandler} /** on mobile, pass back function from Car*/
                            />
                            : <CarDetails car={car}
                                          setInitialGalleryIndex={setInitialGalleryIndex} //set initial gallery image index on click
                                          setShowGallery={setShowGallery} //show gallery on image click
                            />}
                        <CarMetadataArea2 car={car}/>
                    </Box>}
            </VStack>}

        {isError && <Flex
            id='flex-not-published'
            direction='column'
        >
            {showBackButton && <Box
                justifySelf='flex-start'
                mt={0} mb={{
                base: 2,
                md: 0
            }}
            >
                <IconButton aria-label={'Back'}
                            rounded='full'
                            icon={<IoMdArrowBack size='24'/>}
                            colorScheme='gray'
                            variant='ghost'
                            onClick={() => navigate(-1)}/>
                {/*<Link onClick={() => navigate(-1)}>Back</Link>*/}
            </Box>}
            <Flex minH={200} align='center' justify='center'>
                <Center id='center'>{`${t('car.the-car')} ${id} ${t('car.is-not-published')}`}</Center>
            </Flex>
        </Flex>
        }

    </Container>
}