import React, {useCallback, useEffect} from 'react';
import {
    Box,
    Button,
    Card,
    Center,
    Container,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text, useToast,
    VStack
} from "@chakra-ui/react";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {MyAccount} from '../components/MyAccount/MyAccount';
import {AddCar} from '../components/AddCar/AddCar';
import {MyOffers} from '../components/MyOffers/MyOffers';
import {useProfileStore, useSellDictionaryStore, useSellerDataStore} from '../state/store';
import {useTranslation} from 'react-i18next';
import {
    formatCountries,
    formatDictionaryOfColors,
    formatVAT
} from '../components/SimpleSearch/simpleSearchHelper';
import {isEmpty} from "lodash";
import {tabsStyle} from "../components/Tabs/TabsStyle";
import {InteractionRequiredAuthError} from "@azure/msal-browser";

export const Sell = () => {
    const {instance, accounts} = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const sellDictionaryStore = useSellDictionaryStore();
    const profileStore = useProfileStore();
    const sellersStore = useSellerDataStore();

    const {t, i18n} = useTranslation(["texts", "countries"]);

    const colors_d = t('data-for-ui:extColor', {returnObjects: true});
    const countries_d = t('countries:countries', {returnObjects: true});
    const vat_d = t('data-for-ui:vat', {returnObjects: true});

    const toast = useToast();

    /** fetch access token */
    const fetchAccessToken = useCallback(async () => {
        if (accounts.length === 0) return;

        try {
            const silentResult = await instance.acquireTokenSilent({
                account: accounts[0],
                scopes: [process.env.REACT_APP_MSAL_TOKEN_SCOPE]
            });
            profileStore.setToken(silentResult.accessToken);
        } catch (error) {
            console.error('Error acquiring token:', error);
            profileStore.setToken(undefined);

            if (error instanceof InteractionRequiredAuthError) {
                try {
                    await instance.acquireTokenRedirect({
                        scopes: ['openid', 'profile', 'email'],
                    });
                } catch (redirectError) {
                    console.error('Redirect error:', redirectError);
                    toast({
                        title: "Authentication Error",
                        description: "Failed to authenticate. Please try again.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                        autoHide: true
                    });
                }
            } else {
                toast({
                    title: "Authentication Error",
                    description: "Failed to authenticate. Please try again.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    autoHide: true
                });
            }
        }
    }, [instance, accounts, profileStore, toast]);

    useEffect(() => {
        console.log('Sell useEffect [isAuthenticated]', isAuthenticated);

        if (isAuthenticated) {
            fetchAccessToken();
        }

    }, [isAuthenticated]);

    /** populate dictionaries for colors, countries and vat */
    useEffect(() => {
        console.log('Sell useEffect []');

        const countries = formatCountries(countries_d);

        const SELL_PAGE = 'sell';
        const colors = formatDictionaryOfColors(colors_d, SELL_PAGE);
        // colors.unshift({value: '', name: '', disabled: true, visible: false});

        const vat = formatVAT(vat_d);
        //basic init
        sellDictionaryStore.updateMultipleOptions({
            countries: countries,
            colors: colors,
            vat: vat
        });
    }, []);


    const handleLogin = async () => {
        try {
            await instance.loginRedirect({
                scopes: ['openid', 'profile', 'email'],
                prompt: 'select_account'
            });
        } catch (error) {
            toast({
                title: "Authentication Error",
                description: "Error during login",
                status: "error",
                duration: 5000,
                isClosable: true,
                autoHide: true
            });
        }

    };

    return <Container maxW="container.xl" my={6}>
        <Box maxW={840}>
            {isAuthenticated ? <Tabs index={isEmpty(sellersStore.sellerData) ? 2 : profileStore.tabIndex}
                                     onChange={(index) => {
                                         console.log('Sell page tabIndex', index);
                                         profileStore.setTabIndex(index);
                                     }}
                                     sx={tabsStyle}
                >
                    <TabList>
                        <Tab isDisabled={isEmpty(sellersStore.sellerData)}>{t('sell.my-offers')}</Tab>
                        <Tab isDisabled={isEmpty(sellersStore.sellerData)}>{t('sell.add-car')}</Tab>
                        <Tab>{t('sell.my-account')}</Tab>
                    </TabList>

                    <TabPanels p={[0, 1]}
                               bg='#F4F4F4'
                    >
                        <TabPanel>
                            <MyOffers/>
                        </TabPanel>
                        <TabPanel>
                            <AddCar/>
                        </TabPanel>
                        <TabPanel>
                            <MyAccount/>
                        </TabPanel>
                    </TabPanels>
                </Tabs> :
                <Card>
                    <Center minH={'400'}>
                        <VStack spacing={4}>
                            <Text>{t('sell.login-message')}</Text>
                            <Button variant='outline' onClick={handleLogin}>{t('sell.login-title')}</Button>
                        </VStack>
                    </Center>
                </Card>
            }
        </Box>
    </Container>
}