export const countriesVatDictionary = {
    "estonia": [{'value': 0, 'label': '0% - private'}, {'value': 22, 'label': '22% - company'}],
    "latvia": [{'value': 0, 'label': '0% - private'}, {'value': 21, 'label': '21% - company'}],
    "lithuania": [{'value': 0, 'label': '0% - private'}, {'value': 21, 'label': '21% - company'}],
    "finland": [{'value': 0, 'label': '0% - private'}, {'value': 24, 'label': '24% - company'}],
    "sweden": [{'value': 0, 'label': '0% - private'}, {'value': 25, 'label': '25% - company'}],
    "norway": [{'value': 0, 'label': '0% - private'}, {'value': 25, 'label': '25% - company'}]
}

export const calculatePriceWithoutVat = (price, vat) => {

    //net = gross / (1 + (vat / 100))
    //1000/1.2 = 833.33
    if (price === undefined || vat === undefined) return 0;

    if (typeof price === "string") price = Number.parseInt(price);
    if (typeof vat === "string") vat = Number.parseInt(vat);
    const netPrice = (price / (1 + (vat / 100))).toFixed(0);
    console.log('calculatePriceWithoutVat', price, vat, 'netto:', netPrice, typeof netPrice);
    return netPrice;
}

export const calculateVatFromGross = (price, vat) => {
    const vatValue = (calculatePriceWithoutVat(price, vat) * (vat / 100)).toFixed(0);
    console.log('calculateVatFromGross', price, vat, 'vat value', vatValue);
    return vatValue;
}

export const getCompanyVatByCountry = country => {
    // console.log('getCompanyVATByCountry', country, countriesVatDictionary, countriesVatDictionary[country]);
    if (country !== undefined) {
        const cntry = country.toLowerCase();
        const value = countriesVatDictionary[cntry][1].value;
        // console.log('country vat', value);
        return value;
    }
}

export const getVatByCountryAndCar = (car) => {
    // if (car.country !== undefined && car.vatIncluded !== undefined) {
    if (car.vatIncluded === "true") {
        return countriesVatDictionary[car.country][1].value;
    }
    return 0;
}