import {useEffect, useState} from "react";
import {Image, Skeleton} from "@chakra-ui/react";
import {AzureStorageRESTConnector} from "../../integration/storage/AzureStorageRESTConnector";

const FetchableImage = ({blobPath, ...rest}) => {
    const [imageUrl, setImageUrl] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {

        const fetchImage = async () => {
            await AzureStorageRESTConnector.fetchImage(blobPath, setImageUrl);
            setIsLoaded(true);
            // console.log('fetchImage', blobPath);

        };

        if (blobPath) {
            fetchImage();
        }
    }, [blobPath]);

    return (
        // <Skeleton
        //     isLoaded={isLoaded}
        //     // startColor="neutal.100"
        //     // endColor="neutral.100"
        //     // flexShrink={0}
        //     // h="full"
        //     // objectFit="cover"
        //     {...rest}
        // >
            <Image
                flexShrink={0}
                // h="full"
                objectFit="cover"
                src={imageUrl}
                w="full"
                {...rest} // Pass through all additional props
            />
        // </Skeleton>
    );
};

export {FetchableImage};
