import React, {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from "react-router-dom";
import {GridItem, Link} from "@chakra-ui/react";
import {Card, CardBody, CardHeader, Flex, Image, SimpleGrid, Text} from "@chakra-ui/react";

import {modelsByPrices} from "./modelsByPrices";
import {parseNumber} from "./priceRangeHelper";
import {useOptionsStore} from "../../state/store";

export const LargeCards = ({updateMultipleOptions}) => {

    const store = useOptionsStore();
    const {t} = useTranslation(['texts']);
    const navigator = useNavigate();

    const handleLargeCardClick = useCallback(async ({value, extraOptions, array, currentIndex}, e) => {

        e.preventDefault();
        const prevElement = array[currentIndex - 1] || '0';

        const options = {
            price: {
                value: [parseNumber(prevElement), parseNumber(value)],
                name: '',
                visible: true
            }, ...extraOptions
        };
        store.updateSomeResetOthers(options);
        navigator('/');

    }, [navigator, updateMultipleOptions]);

    return <SimpleGrid columns={{base: 1, sm: 2, lg: 4}} spacing={4} w="full">
        {modelsByPrices.cards.map(({title, amounts, img, extraOptions}) => (
            <Flex justifyContent='center' key={title} w="full">
                <Card variant='outline' direction='column' borderRadius='md'
                      gap={0}
                      overflow='hidden' w="full">
                    <CardHeader pb={0}
                                p={{base: 6, md: 4, xl: 6}}
                                // px={{base: 6, md: 4, xl: 6}}
                                // py={{base: 6, md: 4, lg: 6}}
                    >
                        <Text fontWeight={500} textTransform='capitalize'>{t(title)}</Text>
                    </CardHeader>
                    <CardBody px={{base: 6, md: 4, xl: 6}}
                              // pt={{base: 4, sm: 2}}
                              pb={{base: 5, md: 4, xl: 6}}>
                        <SimpleGrid columns={2} spacing={2}>
                            {amounts.map((value, index) => {
                                const prefix = modelsByPrices.prefix;

                                const rangeObject = {
                                    value: value,
                                    array: amounts,
                                    currentIndex: index,
                                    extraOptions: extraOptions
                                };

                                return <GridItem key={`${value}-${index}`}>
                                    <Link as={RouterLink}
                                          textStyle="sm"
                                          variant="secondary"
                                          onClick={e => handleLargeCardClick(rangeObject, e)}
                                    >{t(prefix)} {value}</Link>
                                </GridItem>;
                            })}
                        </SimpleGrid>
                    </CardBody>
                    <Image src={img} alt={t(title)} objectFit="cover" maxH={{base: "142px", sm: "none"}}/>
                </Card>
            </Flex>)
        )}
    </SimpleGrid>
}