import React, { useState } from 'react';
import {Container, Flex, VStack, Box, Text, Heading, Link as ChakraLink, Stack} from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import { Sidebar } from "../Sidebar";
import { useTranslation } from "react-i18next";

export const Sitemap = () => {
    const { t } = useTranslation(['texts', 'support']);
    const [active, setActive] = useState('sitemap');

    return (
        <Container maxW="container.xl" my={[4, 9]}>
            <Stack direction={{ base: 'column', md: 'row' }} align='start' spacing={[8, 12]} w="full">
                <Sidebar active={active} setActive={setActive} linksType='support' />
                <Flex direction="column" maxW="60%">
                    <Box mb={6}>
                        <Heading as="h2" size="md" mb={4}>{t('support:support.sell.sitemap')}</Heading>
                        <Text mb={3}>{t('support:support.sell.site-from-10k-foot')}</Text>
                        <VStack align="start" spacing={6}>
                            <VStack align="start" spacing={2} fontWeight='bold'>
                                <ChakraLink as={Link} to='/home'>{t('texts:nav.home')}</ChakraLink>
                                <ChakraLink as={Link} to='/'>{t('texts:nav.search')}</ChakraLink>
                                <ChakraLink as={Link} to='/sell'>{t('texts:nav.sell')}</ChakraLink>
                            </VStack>
                            <VStack align="start" spacing={2}>
                                <Text fontWeight='bold'>{t('texts:footer.company')}</Text>
                                <VStack pl={4} align="start" spacing={1}>
                                    <ChakraLink as={Link} to='/company/about'>{t('texts:footer.about')}</ChakraLink>
                                    <ChakraLink as={Link} to='/company/legal'>{t('texts:footer.legal')}</ChakraLink>
                                    <ChakraLink as={Link} to='/company/protection'>{t('texts:footer.data-protection')}</ChakraLink>
                                    <ChakraLink as={Link} to='/company/contact'>{t('texts:footer.contact')}</ChakraLink>
                                </VStack>
                            </VStack>
                            <VStack align="start" spacing={2}>
                                <Text fontWeight='bold'>{t('support:support.support')}</Text>
                                <VStack pl={4} align="start" spacing={1}>
                                    <ChakraLink as={Link} to='/support/how-to-search'>{t('support:support.search.how-to-search')}</ChakraLink>
                                    <ChakraLink as={Link} to='/support/how-to-sell'>{t('support:support.sell.how-to-sell')}</ChakraLink>
                                    <ChakraLink as={Link} to='/support/how-to-partner'>{t('support:support.sell.how-to-become-a-partner')}</ChakraLink>
                                    <ChakraLink as={Link} to='/support/sitemap'>{t('support:support.sell.sitemap')}</ChakraLink>
                                </VStack>
                            </VStack>
                        </VStack>
                    </Box>
                </Flex>
            </Stack>
        </Container>
    );
};