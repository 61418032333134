export const documentsOcpApimKey = process.env.REACT_APP_DOCUMENTS_API_KEY;
const ocpApiKeyName = process.env.REACT_APP_OCP_API_NAME;
const rootApiKey = process.env.REACT_APP_ROOT_API_URI;


export const fetchResults = async (searchString) => {
    let searchQuery = `${rootApiKey}/docs?`;

    if (searchString !== undefined) searchQuery = searchQuery + searchString;
    console.log('searchQuery at fetchResults of AzureSearchConnector', searchQuery);
    try {
        const response = await fetch(searchQuery, {
            headers: {
                [ocpApiKeyName]: documentsOcpApimKey
            }
        });
        const json = await response.json();
        return json;
    } catch (e) {
        console.error(e);
    }

};

export const publishDocument = async (body, token) => {
    try {
        const url = `${rootApiKey}/docs/add-document-with-check`;
        body['@search.action'] = 'upload';

        console.log('publish body', body);
        if (body.RowKey === undefined && body.vin !== undefined) body.RowKey = body.vin;
        if (body.PartitionKey === undefined && body.make !== undefined) body.PartitionKey = body.make;

        if (typeof body.options === 'string' && body.options.length > 0) {
            console.log('body.options before parsing', body.options, 'typeof', typeof body.options);
            body.options = JSON.parse(body.options);
            console.log('body.options after parsing', body.options);
        } else if (Array.isArray(body.options)) {
            console.log('body.options is already an array, parse not needed', body.options);
        }

        if (typeof body.history === 'string' && body.history.length > 0) {
            console.log('body.history before parsing', body.history);
            body.history = JSON.parse(body.history);
            console.log('body.history after parsing', body.history);
        } else if (Array.isArray(body.history)) {
            console.log('body.history is already an array, parse not needed', body.history);
        }
        delete body.vin;
        delete body.originalVIN;
        delete body.make;
        delete body.containerID;
        delete body.userID;
        delete body.entryMode;
        delete body.cars;
        delete body.sellerInfo;
        // delete body.characteristicID;
        delete body.email;

        const val = {value: [body]};

        // console.log('publishDocument val', JSON.stringify(val));
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                [ocpApiKeyName]: documentsOcpApimKey,
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(val)
        });

        const json = await response.json();
        return json;
    } catch (e) {
        console.warn('AzureSearchConnector.publishDocument error', e);
    }
}

export const unpublishDocument = async (vin, token) => {
    try {
        const url = `${rootApiKey}/docs/deleteDocument`;

        console.log('unpublish body before ', vin);

        const response = await fetch(url, {
            method: 'POST', headers: {
                [ocpApiKeyName]: documentsOcpApimKey,
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                value: [{
                    "@search.action": "delete",
                    "RowKey": vin
                }]
            })
        });
        console.log('response after unpublish', response);
        const json = await response.json();
        return json;

    } catch (e) {
        console.log('AzureSearchConnector.unpublishDocument error', e);
    }
}

export const getDocument = async (vin, token) => {

    try {
        const response = await fetch(`${rootApiKey}/docs/${vin}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                [ocpApiKeyName]: documentsOcpApimKey
            }
        });

        console.log('AzureSearchConnector.getDocument', vin, 'response.code:', response.status);

        if (response.status !== 200 && response.status !== 204) {
            console.warn('AzureSearchConnector.getDocument code!==200/204', response.status);
        }
        return response;
    } catch (e) {
        console.log('AzureSearchConnector.getDocument error', e);
    }
}

export const getPublicDocument = async vin => {
    // console.log('public document', vin)
    const response = await fetch(`${rootApiKey}/docs/public/${vin}`, {
        headers: {
            [ocpApiKeyName]: documentsOcpApimKey
        }
    });
    return response;
}