import {useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import {useDrag, useDrop} from "react-dnd";
import {translateErrorMessage, validateFiles} from "../../integration/storage/storageUtils";
import {map} from "lodash";
import {Box, Button, IconButton, Input, Stack} from "@chakra-ui/react";
import {FetchableImage} from "../FetchableImage/FetchableImage";
import {
    PiArrowSquareDownDuotone,
    PiArrowSquareUpDuotone,
    PiMinusSquareDuotone,
    PiPlusSquareDuotone
} from "react-icons/pi";

export const DraggableItem = ({id, index, moveItem, deleteItem, value, file}) => {

    const {t} = useTranslation(['texts', 'errors']);

    const upText = t('sell.car-ad-card.edit-images.move-up');
    const downText = t('sell.car-ad-card.edit-images.move-down');
    const deleteText = t('sell.car-ad-card.edit-images.delete');

    const [fileSize, setFileSize] = useState(0);
    const [errors, setErrors] = useState('');

    const [{isDragging}, drag] = useDrag({
        type: "image",
        item: {id, index},
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });

    const [, drop] = useDrop({
        accept: "image",
        hover(item, monitor) {
            if (item.index !== index) {
                moveItem(item.index, index);
                item.index = index;
            }
        }
    });

    useEffect(() => {
        console.log('useEffect in EditableImages2: file changed');

        const runValidateFiles = async () => {
            const errors = await validateFiles([file]);
            console.log('useEffect in EditableImages2, runValidateFiles: ', errors);

            const translatedErrors = map(errors, fileError => {
                const tr = translateErrorMessage(fileError.status, t);
                console.log('useEffect in EditableImages2, fileError.status: ', tr);
                return tr;
            })
            setErrors(translatedErrors);
        }

        if (file !== undefined && file.size !== undefined) {
            console.log('useEffect in EditableImages2, file not empty: ', file);
            setFileSize(file.size);
            runValidateFiles();
        }

    }, [file]);

    const fileInputRef = useRef(null);
    const triggerFileInput = () => {
        fileInputRef.current.click();
    };


    return <Stack direction={['column', 'row']} align='stretch' rowGap={2} mb={0} columnGap={3}
                  ref={node => drag(drop(node))}
                  style={{opacity: isDragging ? 0 : 1}}
    >
        <Box>
            <FetchableImage blobPath={value}/>
        </Box>
        <Box>
            <Stack direction={['row', 'column']}>
                {/** Move up */}
                <IconButton aria-label={upText}
                            variant={'outline'}
                            icon={<PiArrowSquareUpDuotone size={'38px'}/>}
                            onClick={() => moveItem(index, index - 1)}/>

                {/** Move down */}
                <IconButton aria-label={downText}
                            variant={'outline'}
                            icon={<PiArrowSquareDownDuotone size={'38px'}/>}
                            onClick={() => moveItem(index, index + 1)}/>

                {/** Delete */}
                <IconButton aria-label={deleteText}
                            variant={'outline'}
                            icon={<PiMinusSquareDuotone size={'38px'}/>}
                            onClick={() => deleteItem(index)}/>

                {/** Upload

                 <IconButton aria-label='upload'
                 variant={'outline'}
                 icon={<PiPlusSquareDuotone size={'38px'}/>}
                 onClick={triggerFileInput}/>
                 />
                 <Input
                 ref={fileInputRef}
                 type='file'
                 accept='image/*'
                 multiple
                 hidden
                 onChange={() => console.log('fileInputRef onChange')}
                 />

                 */}

            </Stack>
        </Box>
    </Stack>
};