import {defineStyle, defineStyleConfig} from "@chakra-ui/react";

export const primary = defineStyle({
    color: 'neutral.900',
    textDecoration: 'none',
    _hover: {
        color: 'primary.500',
        textDecoration: 'none'
    },
    _focus: {
        color: 'primary.500',
    },
    _active: {
        color: 'primary.500',
    },
});

export const secondary = defineStyle({
    color: 'neutral.700',
    textDecoration: 'none',
    _hover: {
        color: 'primary.500',
        textDecoration: 'none'
    },
    _focus: {
        color: 'primary.500',
    },
    _active: {
        color: 'primary.500',
    },
});

const inactive = defineStyle({
    color: 'neutral.500',
    textDecoration: 'none',
    _hover: {
        color: 'neutral.500',
        textDecoration: 'none'
    },
    _active: {
        color: 'neutral.500',
    },
    _focus: {
        color: 'neutral.500',
    },
});


export const linkTheme = defineStyleConfig({
    variants: {
        primary,
        secondary,
        inactive
    }
});