import {MsalProvider} from "@azure/msal-react";
import {PublicClientApplication} from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
        authority: process.env.REACT_APP_MSAL_AUTHORITY,
        knownAuthorities: [process.env.REACT_APP_MSAL_KNOWN_AUTHORITIES],
        redirectUri: `${window.location.origin}/sell`,
        postLogoutRedirectUri: `${window.location.origin}/sell`,
        navigateToLoginRequestUrl: false,
    }
};

export const AuthProvider = ({children}) => {

    const msalInstance = new PublicClientApplication(msalConfig);

    return (
        <MsalProvider instance={msalInstance}>
            {children}
        </MsalProvider>
    );
};