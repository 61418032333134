import {validateFiles} from "./storageUtils";

const rootApiPath = process.env.REACT_APP_ROOT_API_URI;
const blobApiKey = process.env.REACT_APP_BLOB_API_KEY;
const apiKeyName = process.env.REACT_APP_OCP_API_NAME;

const baseURL = `${rootApiPath}/images`;

class AzureStorageRESTConnector {
    static async fetchImage(src, setImageUrl) {
        try {
            const response = await fetch(src, {
                headers: {
                    [apiKeyName]: blobApiKey
                }
            });
            const imageBlob = await response.blob();
            const objectUrl = URL.createObjectURL(imageBlob);
            setImageUrl(objectUrl);
            return () => URL.revokeObjectURL(objectUrl);
        } catch (error) {
            console.error(error);
        }
    }

    containerExists = async (name, token) => {
        // console.log('token from localstorage', token);
        console.log('check if containerExists...', name);

        const url = `${baseURL}/${name}`

        const response = await fetch(url, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${token}`,
                [apiKeyName]: blobApiKey
            }
        });
        console.log('response status', response.status, response.statusText);
        if (response.status === 200) {
            console.log('container exists');
        } else console.log('container error', response.status, response.statusText);
        return {status: response.status, text: response.statusText};
    }

    createContainer = async (name, token) => {
        if (name !== undefined) {
            const url = `${baseURL}/${name}`
            console.log('createContainer', url);
            const response = await fetch(url, {
                method: "PUT",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    [apiKeyName]: blobApiKey
                }
            });
            if (response.status === 201) {
                console.log('container created');
            } else console.error('container error', response.status, response.statusText);
            return {status: response.status, text: response.statusText};
        }
    }

    putBlob = async (containerName, vin, files, token) => {

        let errorMap = await validateFiles(files);

        //if errorMap is empty, all files passed validation we can proceed with upload
        if (errorMap.length > 0) {
            console.warn('putBlob: Files failed validation', errorMap);
            return errorMap;
        } else {
            console.log('putBlob: Files passed validation', errorMap);
            const map = await Promise.all(files.map(async (file) => {
                const shortURL = `${baseURL}/${containerName}/${vin}/${Math.random().toString(36).substring(2, 14)}.jpg`;
                const response = await fetch(shortURL, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        [apiKeyName]: blobApiKey
                    },
                    body: file
                });

                console.log('response of putBlob', response.statusText, response.status);
                if (response.status === 201) {
                    return {
                        fileName: file.name,
                        url: shortURL,
                        status: {
                            "code": response.status,
                            "message": 'uploaded'
                        }
                    };
                } else {
                    console.warn('response not successful', response.status, response.body);
                    return {
                        status: {
                            "code": response.status,
                            "message": 'not uploaded'
                        }
                    };
                }
            }));

            console.log('putBlob: end of successful flow;\nmap:', map, 'errorMap:', errorMap);
            return map;
        }
    };

    deleteBlob = async (containerName, vin, blobName) => {
        console.log('deleteBlob:', 'containerName', containerName, 'vin', vin, 'blobName', blobName, [apiKeyName], blobApiKey);
        const url = `${baseURL}/${containerName}/${vin}/${blobName}`;
        const response = await fetch(url,
            {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    [apiKeyName]: blobApiKey
                }
            });
        console.log('after delete', response.status)
        return response;
    }
}

export {AzureStorageRESTConnector};