import React from 'react';
import {
    Card, Drawer, DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    HStack,
    IconButton,
    Text,
    VStack
} from "@chakra-ui/react";
import {Close} from "../../../icons";
import {AskQuestionContent} from "./AskQuestionContent";
import {SellerInfoContent} from "./SellerInfoContent";
import {useTranslation} from "react-i18next";

export const AskQuestionDrawer = ({isOpen, onClose, car, setActiveModal, sellerInfo}) => {
    const {t} = useTranslation(['texts']);

    // console.log("AskQuestionDrawer", sellerInfo, car.RowKey);

    return <Drawer isOpen={isOpen} onClose={onClose}
                   placement="left"
                   size="full"
    >
        <DrawerOverlay/>
        <DrawerContent maxW={412} w="full">
            <DrawerHeader as={VStack} spacing={2} align="start" w="full">
                <HStack align="center" w="full" justify="space-between">

                    <Text>{t('vehicle-information.seller-info.contact-seller')}</Text>
                    {/*<Text>{t('search.search-area.search-filters')}</Text>*/}
                    <DrawerCloseButton
                        as={IconButton}
                        icon={<Close/>}
                        variant="ghost"
                        size="xs"
                    />
                </HStack>
            </DrawerHeader>
            <DrawerBody w="full" align="start" spacing={4}>
                <Card>
                    <AskQuestionContent fontSize={'16px'} car={car}/>
                </Card>
                <Card mt={5}>
                    <SellerInfoContent car={car}
                                       style={{fontSize: '16px'}}
                                       sellerInfo={sellerInfo}
                    />
                </Card>
            </DrawerBody>
        </DrawerContent>
    </Drawer>
}