import {orderBy, isEmpty, pickBy, clone} from 'lodash';
import {initialOptions} from "../../state/initialstate";

export const sortAndFormatMakesList = makes => {
    const sortedList = orderBy(makes, ['rating'], ['asc']);

    let lastRating = null;
    const transformedList = [];
    transformedList.push({value: '', name: '', disabled: true, selected: false});

    sortedList.forEach((item) => {
        if (lastRating !== null && item.rating !== lastRating) {
            transformedList.push({
                value: '', name: '----', disabled: true, selected: false
            });
        }

        transformedList.push({
            value: item.value, name: item.name
        });

        lastRating = item.rating;
    });
    console.log('formatMakesList', transformedList);
    return transformedList;
}

export const sortAndFormatModelsList = models => {
    const transformedList = [];
    transformedList.push({value: '', name: '', disabled: true, selected: false});

    const sorted = orderBy(models, ['name'], ['asc']);

    sorted.forEach((item) => {
        transformedList.push({
            value: item.name, name: item.name
        });
    });

    console.log('transformed model list', transformedList);
    return transformedList;
}

export const formatDictionary = transmissions => {
    const transformed = [];
    transformed.push({value: '', name: '', disabled: true, selected: false});

    Object.entries(transmissions).forEach(([key, value]) => {
        transformed.push({value: key, name: value.title});
    });

    return transformed;
}

export const formatDictionaryForCheckbox = dictionary => {
    const transformed = [];
    Object.entries(dictionary).forEach(([key, value]) => {
        transformed.push({value: key, name: value.title});
    });
    return transformed;
}

export const formatDictionaryOfColors = (colorsDictionary, page) => {


    const transformed = [];
    // transformed.push({value: '', name: '', disabled: true, selected: false});

    Object.entries(colorsDictionary).forEach(([key, value]) => {
        transformed.push({code: value.color, value: key, name: value.title, visible: true});
    });

    if (page !== undefined && page === 'sell') {
        transformed.unshift({value: '', name: '', disabled: true, selected: false});
    }

    console.log('formatDictionaryOfColors', page, transformed);
    return transformed;

}

export const formatVAT = vatDictionary => {
    const transformed = [];


    Object.keys(vatDictionary).forEach(country => {


        transformed[country] = vatDictionary[country].map(item => ({
            name: item.title.split('%')[0].trim() + '%',
            value: item.value.toString(),
            visible: true
        })).sort((a, b) => a.value - b.value); // This sorts the items so the 0% comes after the higher percentage
        transformed[country].unshift({value: '', name: '', disabled: true, selected: false});
    });

    // Object.entries(vatDictionary).forEach(([key, value]) => {
    //     transformed.push({value: key, name: value});
    // });

    console.log('formatVAT', vatDictionary, transformed);

    return transformed;

}

export const formatCountries = countries => {

    const transformed = [];
    transformed.push({value: '', name: '', disabled: true, selected: false});

    Object.entries(countries).forEach((item) => {
        console.log('formatCountries', item);
        const key = item[0];
        const value = item[1];
        transformed.push({value: key, name: value.title, cities: value.cities});
    });
    console.log('formatCountries', transformed);

    return transformed;

}

export const formatCities = cities => {
    const transformed = [];
    transformed.push({value: '', name: '', disabled: true, selected: false});

    Object.entries(cities).forEach((item) => {
        const key = item[0];
        const value = item[1];
        transformed.push({value: key, name: value.title});
    });
    console.log('formatCities', transformed);

    return transformed;

}
export const setMakeAndUpdateState = (make, setMake, setModel, updateMake) => {
    if (!isEmpty(make) && make.indexOf('{') > -1) {
        const parsed = JSON.parse(make);
        setMake({
            value: parsed.name, name: parsed.name, visible: true
        });
    } else {
        setMake({
            value: '', name: '', visible: false
        });
        setModel({
            value: '', name: '', visible: false
        });
    }
}

export const parseAndSet = (field, setter) => {
    if (!isEmpty(field) && field.indexOf('{') > -1) {
        const parsed = JSON.parse(field);
        setter({
            value: parsed.value, name: parsed.name, visible: true
        })
        console.log('parseAndSet', parsed);
    } else {
        setter({
            value: '', name: '', visible: false
        });
    }
}

export const parseAndSetNumeric = (type, field, setter) => {

    if (!isEmpty(field) && field.indexOf('{') > -1) {
        const parsed = JSON.parse(field);
        const number = Number.parseInt(parsed.value);
        let value = [0, number];
        console.log('type', type, 'value', value);
        if (type === 'year') {
            const yearCopy = clone(initialOptions.year.value);
            yearCopy[0] = number; // value = [2021,2022];
            value = yearCopy;

            setter({
                value: value, name: parsed.name, visible: true
            });
        } else {
            setter({
                value: value, name: parsed.name, visible: true
            });
        }
        console.log('parseAndSetNumeric');
    } else {
        setter({
            value: '', name: '', visible: false
        });
    }
}

export const isParseableAsJson = yearFromForm => {
    return !isEmpty(yearFromForm) && yearFromForm.indexOf('{') > -1;
}

export const parseAndSetStart = (startValueFromForm, fieldFromStore, setter) => {

    //check if start from form is empty
    if (isEmpty(startValueFromForm)) {
        console.log('parseAndSetStart start is empty');

        setter({
            value: [], name: '', visible: false
        });

    } else {// if start is not empty
        console.log('parseAndSetStart start not empty', startValueFromForm);

        const startValueAsNumber = Number.parseInt(startValueFromForm);

        const value = [startValueAsNumber];
        const endYear = fieldFromStore.value[1];
        const name = endYear ? `${startValueAsNumber}-${endYear}` : `${startValueAsNumber}`;

        console.log('parseAndSetStart start value', value, 'name', name, 'visible', true);

        setter({
            value, name, visible: true
        });
    }
}


export const parseAndSetEnd = (valueFromForm, fieldFromStore, setter) => {

    const start = fieldFromStore.value[0];
    console.log('parseAndSetEnd typeof', typeof start, start);

    if (isEmpty(valueFromForm)) {
        console.log('parseAndSetEnd is empty', valueFromForm);

        //keep start value, reset end
        if (fieldFromStore.visible && start !== undefined) { //only process if field is visible
            console.log('parseAndSetEnd end empty, but start is not empty', start, valueFromForm, fieldFromStore);

            const value = [start, undefined];//keep start value
            setter({
                value, name: start, visible: true
            });
        }
        //reset start and end
        else if (fieldFromStore.visible) {  //if start is undefined
            console.log('parseAndSetEnd end empty, visible', start, valueFromForm, fieldFromStore);
            setter({
                value: [], name: '', visible: false
            });
        } else console.log('parseAndSetEnd skipping');
    } else {// if end is not empty
        console.log('parseAndSetEnd value not empty', valueFromForm);

        const endAsNumber = Number.parseInt(valueFromForm);
        if (endAsNumber > 0) {
            let name, value;
            if (start === undefined) {
                console.log('parseAndSetEnd start is empty, end is not empty', start, endAsNumber, fieldFromStore);
                name = ` < ${endAsNumber}`;
                value = [undefined, endAsNumber];
            } else if (start >= 0) {
                console.log('parseAndSetEnd start is not empty, end is not empty', start, endAsNumber, fieldFromStore);
                name = `${start}-${endAsNumber}`;
                value = [start, endAsNumber];
            }
            setter({
                value, name, visible: true
            });
        }
    }
}

export const evaluateAllFields = (options) => {

    const aggregate = {...options};
    const filtered = pickBy(aggregate, object => {
        const isempty = !isEmpty(object.value);
        const isNumber = Number.isInteger(object.value);
        console.log('SimpleSearch evaluateAllFields', object, isempty, isNumber);
        return isempty || isNumber;
    });
    return filtered;
}