import {checkboxAnatomy} from '@chakra-ui/anatomy'
import {createMultiStyleConfigHelpers} from '@chakra-ui/react'

const {definePartsStyle, defineMultiStyleConfig} =
    createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = definePartsStyle({
    container: {
        alignItems: "flex-start",
    },
    control: {
        w: 6,
        h: 6,
        borderRadius: "sm",
        borderColor: "neutral.400",
        _checked: {
            bgColor: "primary.500",
            borderColor: "primary.500",
            _hover: {
                bgColor: "primary.500",
                borderColor: "primary.500",
            },
        },
    },
    label: {
        mt: "0.1875rem", //3px
    }
});

const colorFilter = definePartsStyle((props) => {
    // console.log("colorFilter ::", props)
    return {
        control: {
            bg: "transparent",
            borderWidth: "1px",
            borderColor: "neutral.600",
            _checked: {
                bgColor: "transparent",
                borderColor: 'primary.500',
                borderWidth: "2px",
                // borderColor: props["data-bdc"],
                _hover: {
                    bgColor: "transparent",
                    // borderColor: props["data-bdc"],
                    borderColor: 'primary.500',
                    borderWidth: "2px",
                },
            },
            _hover: {
                bgColor: "transparent"
            }
        },
    }
})

const sizes = {
    md: definePartsStyle({
        label: {
            fontSize: "0.875rem",
            lineHeight: "1.125rem",
        },
    }),
}

export const checkboxTheme = defineMultiStyleConfig({baseStyle, sizes, variants: {colorFilter}})