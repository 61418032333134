import React, {useState} from 'react';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    Textarea,
    VStack,
    Text,
    useTheme
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {validateEmail, validateTextRange} from "./formValidationHelpers";
import {getContainerIDfromPath} from "../../../integration/tables/sellerUtils";
import {composeMessage, submitData} from "../../../integration/email/emailFunctions";
import {GiConfirmed} from "react-icons/gi";

export const AskQuestionContent = ({fontSize = '14px', car}) => {
    const {t} = useTranslation(['texts', 'errors']);
    const theme = useTheme();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [emailError, setEmailError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [firstSubmitAttempt, setFirstSubmitAttempt] = useState(true);


    const validateName = value => {
        const isValid = validateTextRange(value, 1, 40);
        setNameError(!isValid);
    };

    const validateEmailField = value => {
        const isValid = validateEmail(value);
        setEmailError(!isValid);
    };

    const validateMessage = value => {
        const isValid = validateTextRange(value, 20, 250);
        setMessageError(!isValid);
    };

    const handleSend = async () => {
        setFirstSubmitAttempt(false);

        const isNameValid = validateTextRange(name, 1, 40);
        const isEmailValid = validateEmail(email); // Use validateEmail for email validation
        const isMessageValid = validateTextRange(message, 20, 250);

        setNameError(!isNameValid);
        setEmailError(!isEmailValid);
        setMessageError(!isMessageValid);

        if (isNameValid && isEmailValid && isMessageValid && car) {
            try {
                setIsSending(true);
                await _sendEmail();
            } finally {
                setIsSending(false);
                setIsSubmitted(true);
            }
        }
    };

    const _sendEmail = async () => {
        const containerID = getContainerIDfromPath(car);
        const vin = car.RowKey;
        let preMsg = t("search.car-card.contact-form.msg-pre") + ': ' + name + ' (' + email + ')\n\n';
        let afterMsg = t("search.car-card.contact-form.msg-after");

        const response = await submitData(
            'temporaryreplacementforatoken',
            vin,
            containerID,
            email,
            name,
            composeMessage(preMsg, message, afterMsg)
        );
    };

    if (isSubmitted) {
        return (
            <HStack w='full' justify='center'>
                <Text>
                    {t('texts:search.car-card.contact-form.sent-status-sent')}
                </Text>
                <GiConfirmed color={theme.colors.primaryGreen[800]} size='1rem'/>
            </HStack>
        );
    }

    return (
        <VStack gap={4}>
            <FormControl isRequired isInvalid={nameError}>
                <FormLabel fontSize={fontSize}>
                    {t('texts:search.car-card.contact-form.full-name')}
                </FormLabel>
                <Input
                    variant={name?.length > 0 ? 'primaryActive' : 'primary'}
                    type='text'
                    // autoFocus={[false, true]}
                    maxLength={40}
                    placeholder={t('texts:search.car-card.contact-form.name-placeholder')}
                    onChange={e => {
                        setName(e.target.value);
                        if (!firstSubmitAttempt) {
                            validateName(e.target.value);
                        }
                    }}
                    fontSize={fontSize}
                    value={name}
                />
                <FormErrorMessage>{t('errors:my-account.name-not-ok')}{': 1 - 40'}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={emailError}>
                <FormLabel fontSize={fontSize}>
                    {t('texts:search.car-card.contact-form.email')}
                </FormLabel>
                <Input
                    type='text'
                    variant={email?.length > 0 ? 'primaryActive' : 'primary'}
                    maxLength={50}
                    placeholder={t('texts:search.car-card.contact-form.email-placeholder')}
                    onChange={e => {
                        setEmail(e.target.value);
                        if (!firstSubmitAttempt) {
                            validateEmailField(e.target.value);
                        }
                    }}
                    fontSize={fontSize}
                    value={email}
                />
                <FormErrorMessage>{t('errors:car.email-not-ok')}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={messageError}>
                <FormLabel fontSize={fontSize}>
                    {t('texts:search.car-card.contact-form.message')}
                </FormLabel>
                <Textarea
                    resize='vertical'
                    height='110px'
                    size={'md'}
                    maxLength={250}
                    variant={message?.length > 0 && 'primaryActive'}
                    // variant={'primaryActive'}
                    placeholder={t('texts:search.car-card.contact-form.msg-placeholder')}
                    onChange={e => {
                        setMessage(e.target.value);
                        if (!firstSubmitAttempt) {
                            validateMessage(e.target.value);
                        }
                    }}
                    fontSize={fontSize}
                    value={message}
                />
                <FormErrorMessage>{t('errors:car.message-not-ok')}{': 20 - 250'}</FormErrorMessage>
            </FormControl>
            <FormControl>
                <HStack w='full' justify='end'>
                    <Button
                        variant="outlineRed"
                        boxShadow='md'
                        onClick={handleSend}
                        isDisabled={isSending}
                        isLoading={isSending}
                        textTransform='capitalize'
                    >
                        {isSending
                            ? t('texts:search.car-card.contact-form.sent-status-checking')
                            : t('search.car-card.contact-form.send-email')}
                    </Button>
                </HStack>
            </FormControl>
        </VStack>
    );
};