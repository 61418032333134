import {
    Checkbox,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Text,
    VStack
} from '@chakra-ui/react';
import React from 'react';
import {useDictionaryStore} from '../../../state/store';
import {useShallow} from 'zustand/react/shallow';
import {useTranslation} from "react-i18next";

const FeaturesModal = ({isOpen, onClose, features, setFeatures}) => {

    const features_d = useDictionaryStore(useShallow((store) => store.features));
    const {t} = useTranslation(['texts']);

    console.log('Features modal features', features);

    const changeFeature = (e, featuresName) => {


        if (e.target.checked) {

            /** Add to the store if checked */
            const filtered = features.filter(el => el.value !== featuresName.value);
            if (!filtered.includes(featuresName.value)) {
                filtered.push(featuresName);
                setFeatures(filtered);
            }

        } else {

            /** Remove if unchecked */
            const filtered = features.filter(el => el.value !== featuresName.value);
            setFeatures(filtered);

        }

    };

    return <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay/>
        <ModalContent maxW={412} w="full">
            <ModalHeader>{t('sell.input-fields.extra-features')}</ModalHeader>
            <ModalCloseButton/>
            <ModalBody as={VStack} w="full" align="start" spacing={4}>
                <SimpleGrid columns={1} spacing={4} w="full">
                    {features_d.map((feature, index) => {
                        return (<Checkbox
                            size="lg"
                            spacing="1rem"
                            colorScheme="primary"
                            isChecked={features.includes(feature)}
                            key={'feature-' + index}
                            onChange={(e) => {
                                changeFeature(e, feature);
                            }}
                        >
                            <Text fontSize="14px">{feature.name}</Text>
                        </Checkbox>);
                    })}
                </SimpleGrid>
            </ModalBody>
            <ModalFooter></ModalFooter>
        </ModalContent>
    </Modal>
}

export {FeaturesModal}