import {Box, Grid, GridItem} from "@chakra-ui/react";
import {PlaceholderImage} from "../../PlaceholderImage/PlaceholderImage";
import React from "react";

export const CarPlaceholderBlock = () => {
    return <Box my={{base: 0, md: 8}}>
        <Grid templateColumns={{base: "1fr", md: "1fr 1fr"}}
              gap={4} h='full' pt={8}>
            <GridItem colSpan={1}>
                <PlaceholderImage
                    roundedTopLeft={'xl'}
                    roundedBottomLeft={'xl'}
                />
            </GridItem>
            <GridItem colSpan={1}>
                <PlaceholderImage
                    roundedTopRight={'xl'}
                    roundedBottomRight={'xl'}
                />
            </GridItem>
        </Grid>
        <Box my={4} h={{base: '200px', md: '480px'}}>
            <Grid py={1} templateColumns={{base: "1fr", md: "7fr 3fr"}}
                  gap={4}
            >
                <GridItem colSpan={1}
                          h={{base: '200px', md: '480px'}}>
                    <PlaceholderImage/>
                </GridItem>
                <GridItem colSpan={1} h={{base: '200px', md: '480px'}}>
                    <PlaceholderImage/>
                </GridItem>
            </Grid>
        </Box>
    </Box>;
}