import React from 'react';
import {
    Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, VStack, Text
} from "@chakra-ui/react";
import LanguageLI from './LanguageListElement';
import {languageData} from './languageData';

export const LanguageModal = ({isOpen, onClose}) =>
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay>
            <ModalContent maxW={{base: '300px', md: '400px'}}>

                <ModalHeader>
                    <Text>Languages</Text>
                    <ModalCloseButton position='absolute' top='1rem'/>
                </ModalHeader>
                <ModalBody>
                    <VStack spacing={1} gap={2}>
                        {Object.entries(languageData)
                            .map((entry) => <LanguageLI key={entry[0]}
                                                        languageCode={entry[0]}
                                                        title={entry[1].title}
                                                        onClose={onClose}
                            />)}
                    </VStack>
                </ModalBody>
                <ModalFooter></ModalFooter>
            </ModalContent>
        </ModalOverlay>
    </Modal>