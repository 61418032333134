import {Box, ChakraProvider, Flex} from "@chakra-ui/react";
import {Route, Routes, BrowserRouter as Router} from "react-router-dom";
import {Navbar} from "./components/Navbar/Navbar";
import {Search} from "./pages/Search";
import {theme} from "./theme";
import {I18nextProvider, useTranslation} from "react-i18next";
import i18n from "./integration/i18n/i18n";
import {Main} from "./pages/Main";
import {Footer} from "./components/Footer/Footer";
import {startTransition, useEffect} from 'react';
import {createSearchString, generateFacets, generateSort, generateTop} from "./integration/search/searchConnectorUtils";
import {useDictionaryStore, useOptionsStore, useSearchStore, useSellDictionaryStore} from "./state/store";
import {initialOptions, initialSearch} from "./state/initialstate";
import {fetchResults} from "./integration/search/AzureSearchConnector";
import {getFieldsFromStore} from "./state/storeUtils";
import {isEmpty} from "lodash";
import {Sell} from './pages/Sell';
import {
    formatCountries, formatDictionary, formatDictionaryForCheckbox, formatDictionaryOfColors, sortAndFormatMakesList
} from './components/SimpleSearch/simpleSearchHelper';
import {AzureMakesRESTConnector} from './integration/tables/AzureMakesRESTConnector';
import {Car} from "./pages/Car";
import {Favourites} from "./pages/Favourites";
import {About} from "./pages/supplementary/company/About";
import {Legal} from "./pages/supplementary/company/Legal";
import {Protection} from "./pages/supplementary/company/Protection";
import {Contact} from "./pages/supplementary/company/Contact";
import {HowToSearch} from "./pages/supplementary/support/HowToSearch";
import {Sitemap} from "./pages/supplementary/support/Sitemap";
import {HowToSell} from "./pages/supplementary/support/HowToSell";
import {HowToPartner} from "./pages/supplementary/support/HowToPartner";
import {silenceConsole} from "./utils/genericHelper";
import {Helmet} from "react-helmet";


const App = () => {

    silenceConsole();
    const {t} = useTranslation(['data-for-ui', 'countries']);

    const optionsStore = useOptionsStore();
    const searchStore = useSearchStore();
    const dictionaryStore = useDictionaryStore();
    const sellDictionaryStore = useSellDictionaryStore();
    const makesConnector = new AzureMakesRESTConnector();

    const transmissions_d = t('transmission', {returnObjects: true});
    const fuels_d = t('fuel', {returnObjects: true});
    const history_d = t('history', {returnObjects: true});
    const drivetrains_d = t('drivetrain', {returnObjects: true});
    const countries_d = t('countries:countries', {returnObjects: true});
    const features_d = t('features', {returnObjects: true});
    const colors_d = t('extColor', {returnObjects: true});

    /**populate dictionaries: makes, transmissions */
    useEffect(() => {
        console.log("App useEffect[]");

        const formattedTransmissions = formatDictionary(transmissions_d);
        dictionaryStore.setTransmissions(formattedTransmissions);
        dictionaryStore.setFuels(formatDictionary(fuels_d));
        dictionaryStore.setHistory(formatDictionaryForCheckbox(history_d));
        dictionaryStore.setDrivetrains(formatDictionary(drivetrains_d));
        dictionaryStore.setCountries(formatCountries(countries_d));
        dictionaryStore.setFeatures(formatDictionaryForCheckbox(features_d));
        dictionaryStore.setColors(formatDictionaryOfColors(colors_d));
        console.log('App useEffect [] colors_d', dictionaryStore.colors);

        async function fetchMakes() {
            const unsortedMakes = await makesConnector.retrieveMakes();
            const sortedMakes = sortAndFormatMakesList(unsortedMakes);
            dictionaryStore.setMakes(sortedMakes);
            sellDictionaryStore.setMakes(sortedMakes);

        }

        isEmpty(dictionaryStore.makes) && fetchMakes();  //fetch makes only if empty


        const fields = getFieldsFromStore(optionsStore);

        const query = createSearchString(fields);
        const top = generateTop(initialSearch.itemsPerPage);
        const sort = generateSort(initialSearch.sort, initialOptions.ignoreSponsored);
        const facetString = generateFacets(query);
        const searchString = query + top + sort + facetString;


        const fetchSearchResults = async () => {

            console.log('App useEffect[] searchString:', searchString);
            const results = await fetchResults(searchString);
            const facets = results['@search.facets'];


            if (!isEmpty(facets)) {
                startTransition(() => {
                    searchStore.setFacets(facets);
                });
            }
        };
        fetchSearchResults();

    }, []);

    /**populate dictionary: models*/
    useEffect(() => {
        console.log('SimpleSearch useEffect [optionsStore.make]', optionsStore.make);

        const fetchModels = async id => {
            await dictionaryStore.fetchAndSetModels(id);
        }

        optionsStore.make.value > 0 && fetchModels(optionsStore.make.value); //fetch models only if make is selected

    }, [optionsStore.make]);
    return <ChakraProvider theme={theme}>
        <Helmet>
            <title>{t('texts:common.html-title')}</title>
            <meta name="description" content={t('texts:common.html-description')}/>
        </Helmet>
        <I18nextProvider i18n={i18n}>
            <Router>
                <Flex flexDirection="column" minHeight="100vh">
                    <Navbar/>
                    <Box flex="1">
                        <Routes>
                            <Route path="/" element={<Search />} />
                            <Route path="/home" element={<Main />} />
                            <Route path="/sell" element={<Sell/>}/>
                            <Route path={"/cars/:id"} element={<Car/>}/> {/*resolve all suffixes*/}
                            <Route path={"/favourites"} element={<Favourites/>}/>

                            <Route path={"/company/about"} element={<About/>}/>
                            <Route path={"/company/legal"} element={<Legal/>}/>
                            <Route path={"/company/protection"} element={<Protection/>}/>
                            <Route path={"/company/contact"} element={<Contact/>}/>

                            <Route path={"/support/how-to-search"} element={<HowToSearch/>}/>
                            <Route path={"/support/how-to-sell"} element={<HowToSell/>}/>
                            <Route path={"/support/how-to-partner"} element={<HowToPartner/>}/>
                            <Route path={"/support/sitemap"} element={<Sitemap/>}/>


                        </Routes>
                    </Box>
                    <Footer/>
                </Flex>
            </Router>
        </I18nextProvider>
    </ChakraProvider>
}

export default App;