export const borderRadius = {
  radii: {
    none: '0',
    sm: '0.25rem',
    md: '0.5rem',
    lg: '0.75rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    full: '999px',
  },
};
