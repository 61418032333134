import {defineStyle, defineStyleConfig} from '@chakra-ui/react';

const baseStyle = defineStyle({
    colorScheme: '',
    borderRadius: 'md',
    border: '1px solid',
    fontWeight: '500',
    fontSize: 'sm',
    lineHeight: '1.5rem',
    position: 'static',
    py: 2,
    textDecor: 'none !important',
    transition: "border-color 200ms ease-in-out, color 200ms ease-in-out, background-color 200ms ease-in-out",
    _disabled: {
        cursor: 'default',
    },
});

const primary = defineStyle({
    bg: 'primary.500',
    color: 'white',
    _hover: {
        bg: 'primary.600',
    },
    _active: {
        bg: 'primary.600',
    },
    _disabled: {
        bg: 'primary.600',
        color: 'white',
        _hover: {
            bg: 'primary.500!important',
            color: 'white',
        }
    }
});

const secondary = defineStyle({
    bg: 'neutral.600',
    color: 'white',
    _hover: {
        bg: 'neutral.800',
    },
    _active: {
        bg: 'neutral.800',
    }
});

const ghost = defineStyle({
    bg: 'transparent',
    color: 'neutral.700',
    border: '0',
    _hover: {
        bg: 'neutral.50',
    },
    _active: {
        bg: 'neutral.100',
    },
});

const red = defineStyle({
    bg: 'transparent',
    color: 'primary.600',
    border: '0',
    _hover: {
        bg: 'neutral.50',
    },
    _active: {
        bg: 'neutral.100',
    },
});

const plain = defineStyle({
    bg: 'transparent',
    color: 'neutral.700',
    border: '0',
    px: 0,
    _hover: {
        color: 'primary.500',
    },
    _active: {
        color: 'primary.500',
    },
    _disabled: {
        color: "neutral.500",
        _hover: {
            color: "neutral.500",
        }
    }
});

const plainRed = defineStyle({
    bg: 'transparent',
    color: 'primary.500',
    border: '0',
    px: 0,
    _hover: {
        color: 'white',
        background: 'primary.500'
    },
    _active: {
        color: 'white',
        background: 'primary.500'
    },
});

const menu = defineStyle({
    bg: 'transparent',
    color: 'neutral.700',
    border: '0',
    fontWeight: 400,
    _hover: {
        color: 'neutral.900',
    },
    _active: {
        color: 'neutral.900',
    },
});

const outline = defineStyle({
    bg: 'white',
    borderColor: '#9e9e9e',
    color: 'neutral.750',
    _hover: {
        borderColor: 'primary.500',
        color: 'primary.500',
    },
    _focus: {
        borderColor: 'primary.500',
        color: 'primary.500',
    },
    _active: {
        borderColor: 'primary.500',
        color: 'primary.500',
    },
});

const outlinePrimary = defineStyle({
    bg: 'white',
    borderColor: '#9e9e9e',
    color: 'neutral.750',
    _hover: {
        borderColor: 'primary.500',
        bg: 'primary.500',
        color: 'white',
    },
    _focus: {
        borderColor: 'primary.500',
        bg: 'primary.500',
        color: 'white',
    },
    _active: {
        borderColor: 'primary.500',
        color: 'primary.500',
    },
    _disabled: {
        borderColor: "primary.600",
        color: "primary.600",
        _hover: {
            borderColor: "primary.700",
            color: "primary.700",
        }
    }
});


const outlineSecondary = defineStyle({
    bg: 'white',
    borderColor: 'neutral.600',
    color: 'neutral.750',
    _hover: {
        borderColor: 'neutral.900',
        color: 'neutral.900'
    },
    _active: {
        borderColor: 'neutral.900',
        color: 'neutral.900'
    },
    _focus: {
        borderColor: 'neutral.900',
        color: 'neutral.900'
    },
});

const outlineRed = defineStyle({
    bg: 'white',
    borderColor: 'neutral.50',
    color: 'primary.500',
    _hover: {
        borderColor: 'neutral.200',
        color: 'primary.600',
    },
    _active: {
        borderColor: 'neutral.200',
        color: 'primary.600',
    },
});

const simpleSearch = defineStyle({
    bg: 'white',
    borderColor: 'neutral.400',
    color: 'neutral.700',
    fontSize: 'md',
    _hover: {
        borderColor: 'neutral.900',
    },
    _focus: {
        borderColor: 'neutral.900',
    },
    _active: {
        borderColor: 'neutral.900',
    },
});

const xsSize = defineStyle(props => {
    const pl = props?.leftIcon ? 1 : 8;
    const pr = props?.rightIcon ? 1 : 8;

    return {
        borderRadius: 'sm',
        fontSize: 'sm',
        h: 6,
        py: 0,
        pl,
        pr,
        minW: 6,
    };
});

const smSize = defineStyle(props => {
    const pl = props?.leftIcon ? 1 : 8;
    const pr = props?.rightIcon ? 1 : 8;

    return {
        fontSize: 'sm',
        h: 9,
        py: 1,
        pl,
        pr,
        minW: 9,
    };
});

const mdSize = defineStyle(props => {
    const pl = props?.leftIcon ? 2 : 6;
    const pr = props?.rightIcon ? 2 : 6;
    return {
        fontSize: 'sm',
        h: 10,
        py: 2,
        pl,
        pr,
    };
});

const lgSize = defineStyle(props => {
    const pl = props?.leftIcon ? 2 : 6;
    const pr = props?.rightIcon ? 2 : 6;
    return {
        fontSize: 'sm',
        h: 12,
        py: 2,
        pl,
        pr,
    };
});

export const buttonTheme = defineStyleConfig({
    baseStyle,
    variants: {
        primary,
        secondary,
        outline,
        ghost,
        simpleSearch,
        red,
        plain,
        plainRed,
        menu,
        outlineSecondary,
        outlinePrimary,
        outlineRed,
    },
    sizes: {
        xs: xsSize,
        sm: smSize,
        md: mdSize,
        lg: lgSize,
    },
});
