import _ from 'lodash';
import adler from 'adler-32';
import {getKWfromHP} from "../../components/AddCar/utils/addCarUtils";


const prepareDataForUpload = data => {
    const preparedObject = {};

    preparedObject.make = data.make.name;
    preparedObject.model = data.model.name;
    preparedObject.year = data.year.value;
    preparedObject.modification = data.modification.name;
    preparedObject.fuel = data.fuel.value;
    preparedObject.transmission = data.transmission.value;
    preparedObject.drivetrain = data.drivetrain.value;
    preparedObject.color = data.color.value;
    preparedObject.mileage = data.mileage.value;
    preparedObject.originalVIN = data.originalVIN.value;
    preparedObject.vin = data.vin;
    preparedObject.country = data.country.value;
    preparedObject.city = data.city.value;
    preparedObject.price = data.price.value;
    preparedObject.power = data.power.value;
    preparedObject.displacement = data.displacement.value;
    preparedObject.characteristicID = data.characteristicID;
    preparedObject.comment = data.comment.value;
    preparedObject.userID = data.userID;

    preparedObject.powerKW = getKWfromHP(data.power.value);

    preparedObject.vatIncluded = data.vatIncluded === '0' ? 'false' : 'true';

    preparedObject.history = data.history.reduce((acc, item) => {
        acc.push(item.value);
        return acc;
    }, []);
    preparedObject.options = data.features.reduce((acc, item) => {
        acc.push(item.value);
        return acc;
    }, []);

    console.log('prepareDataForUpload', preparedObject);
    return preparedObject;
}

const clearFields = data => {
    console.log('clearFields in TableUtils', data);
    if (data !== undefined) {

        const dataCopy = JSON.parse(JSON.stringify(data));

        delete dataCopy['makes'];
        delete dataCopy['models'];
        delete dataCopy['generations'];
        delete dataCopy['generation'];
        // delete dataCopy['characteristicID']; //!do not delete - fetch later
        delete dataCopy['trims'];
        delete dataCopy['trim'];
        delete dataCopy['trims_and_modifications'];
        delete dataCopy['modifications'];
        delete dataCopy['characteristics'];
        delete dataCopy['automaticEntry'];
        delete dataCopy['loadingComplete'];
        delete dataCopy['fullDataToggle'];
        delete dataCopy['files'];
        delete dataCopy['countries'];
        delete dataCopy['cities'];
        delete dataCopy['cars'];
        delete dataCopy['errors'];
        delete dataCopy[''];
        Object.keys(dataCopy).forEach(key => {

            if (dataCopy[key] === undefined ||
                (dataCopy[key] !== undefined && dataCopy[key].length === 0)
                && key !== 'options' && key !== 'history') {
                console.log(key, 'is undefined');
                delete dataCopy[key];
            }
        })
        return dataCopy;
    } else console.error('data is undefined');
    return {};
};

const verifyMandatoryFields = (data, errorMap) => {

    console.log('verifyMandatoryFields', data.userID, data.containerID, data.make, data.model);

    let isOK = true;
    if (isEmpty(data.userID, 'userID')) {
        isOK = false;
        errorMap.push('userID');
    }
    if (isEmpty(data.containerID, 'containerID')) {
        isOK = false;
        errorMap.push('containerID');
    }
    if (isEmpty(data.make, 'make')) {
        isOK = false;
        errorMap.push('make');
    }
    if (isEmpty(data['model'], 'model')) {
        isOK = false;
        errorMap.push('model');
    }
    if (isEmpty(data['year'], 'year')) {
        isOK = false;
        errorMap.push('year');
    }
    // if (isEmpty(data['bodyStyle'], 'bodyStyle')) isOK = false;
    if (isEmpty(data['transmission'], 'transmission')) {
        isOK = false;
        errorMap.push('transmission');
    }
    if (isEmpty(data['drivetrain'], 'drivetrain')) {
        isOK = false;
        errorMap.push('drivetrain');
    }
    if (isEmpty(data['price'], 'price')) {
        isOK = false;
        errorMap.push('price');
    }
    if (isEmpty(data['mileage'], 'mileage')) {
        isOK = false;
        errorMap.push('mileage');
    }
    if (isEmpty(data['originalVIN'], 'originalVIN')) {
        isOK = false;
        errorMap.push('vin');
    }
    if (isEmpty(data['country'], 'country')) {
        isOK = false;
        errorMap.push('country');
    }
    if (isEmpty(data['city'], 'city')) {
        isOK = false;
        errorMap.push('city');
    }
    if (isEmpty(data['vatIncluded'], 'vatIncluded')) {
        isOK = false;
        errorMap.push('vatIncluded');
    }
    if (!_.isEmpty(errorMap)) {
        console.log('verifyMandatoryFields: some are empty; errorMap:', errorMap);
    }
    return isOK;
}

const verifyBlobPaths = data => {
    let isOK = true;
    if (isEmpty(data['blobPaths'])) isOK = false;
    return isOK;
}

const isEmpty = (fieldValue, fieldName) => {
    const empty = fieldValue === undefined || fieldValue.length === 0;
    empty && console.log('IsEmpty===true', fieldName);
    return empty;
}

const flatten = data => {
    console.log('flatten')
    if (data !== undefined && typeof data === 'object') {
        Object.keys(data).forEach(key => {
            // console.log(key, data[key]);
            if (data[key].name !== undefined) data[key] = data[key].name;
            if (data[key].length === 0) data[key] = JSON.stringify(data[key]);
        });
    }
    return data;
}

const verifyTechnicalFields = (data, errorMap) => {
    let isOK = true;
    if (isEmpty(data['make'], 'make')) {
        isOK = false;
        errorMap.push('make');
    }
    if (isEmpty(data['model'], 'model')) {
        isOK = false;
        errorMap.push('model');
    }
    if (isEmpty(data['year'], 'year')) {
        isOK = false;
        errorMap.push('year');
    }
    // if (isEmpty(data['bodyStyle'], 'bodyStyle')) isOK = false;
    if (isEmpty(data['transmission'], 'transmission')) {
        isOK = false;
        errorMap.push('transmission');
    }
    if (isEmpty(data['drivetrain'], 'drivetrain')) {
        isOK = false;
        errorMap.push('drivetrain');
    }
    if (isEmpty(data['fuel'], 'fuel')) {
        isOK = false;
        errorMap.push('fuel');
    }
    // if (isEmpty(data['power (hp)'], 'power')) {
    //     isOK = false;
    //     errorMap.push('power');
    // }
    if (errorMap.length === 0) {
        console.log('verifyTechnicalFields: isOK', isOK);
    } else console.log('verifyTechnicalFields: some are empty; errorMap:', errorMap);
}

const lowerCase = data => {
    Object.keys(data).forEach(key => {
            if (typeof data[key] === 'string'
                && (key !== 'RowKey' && key !== 'vin' && key !== 'blobPaths'
                    && key !== 'comment' && key !== 'modification')) {
                data[key] = data[key].toLowerCase();
            } else {
                console.log('forbidden to lower; typeof key', key);
            }
        }
    )
    return data;
}

export const calculateAdlerCode = text => {
    const crc = adler.str(text).toString(16);
    console.log('CRC (adler):', crc);
    return crc;
}

const updateObjectForInsert = data => {
    console.log('prepareForInsert initial data', data);
    data.RowKey = data.vin;
    data.PartitionKey = data.make;
    if (Array.isArray(data.blobPaths)) data.blobPaths = JSON.stringify(data.blobPaths);
    // data.modification.id !== undefined && (data.characteristicID = data.modification.id);
    data.power = Number.parseInt(data.power);
    data.price = Number.parseInt(data.price);
    data.year = Number.parseInt(data.year);
    data.mileage = Number.parseInt(data.mileage);
    data.displacement !== undefined ? data.displacement = Number.parseInt(data.displacement) : data.displacement = 0;
    if (data.characteristicID !== undefined)
        data.characteristicID = Number.parseInt(data.characteristicID);
    data.options = JSON.stringify(data.options);
    data.history = JSON.stringify(data.history);
    // data.vatIncluded = data.vatIncluded !== 0 ? "true" : "false";   //dont need conversion, already string

    delete data.powerKW; //todo update search index


    //facets: makeAndModel, countryAndCity
    if (data.makeAndModel === undefined) {
        if (_.isObject(data.make)) data.makeAndModel = data.make.name + ' ' + data.model.name;
        else data.makeAndModel = data.PartitionKey + ' ' + data.model;
    }

    console.log('prepare makeAndModel', data.make, data.model, data.makeAndModel);
    data.countryAndCity = data.city + ', ' + data.country; //facets

    delete data.vin;
    delete data.make;
    // delete data.entryMode;
    // delete data.sellerInfo;
    console.log('prepareForInsert updated data', data);
}

const parseObjectArrays = (obj) => {
    const parsedObject = {...obj};
    parsedObject.blobPaths = JSON.parse(parsedObject.blobPaths);
    parsedObject.history = JSON.parse(parsedObject.history);
    parsedObject.options = JSON.parse(parsedObject.options);
    return parsedObject;
};

export {
    clearFields,
    flatten,
    verifyMandatoryFields,
    verifyBlobPaths,
    updateObjectForInsert,
    lowerCase,
    verifyTechnicalFields,
    prepareDataForUpload,
    parseObjectArrays
};