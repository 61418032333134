import {VStack, Text} from "@chakra-ui/react"
import {Link as RouterLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const FooterList = ({data}) => {
    const {t} = useTranslation(['support', 'texts']);

    return <VStack align="start" spacing={1}>
        <Text fontWeight="bold" textTransform="uppercase" mb={2}>
            {t(data.title)}
        </Text>
        {data.links.map(({href, title}) => <RouterLink to={href} key={title}>{t(title)}</RouterLink>)}
    </VStack>
}