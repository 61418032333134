import {Image} from "@chakra-ui/react";
import React from "react";
import placeholderSVG from '../../images/placeholder/placeholder.svg';

export const PlaceholderImage = (props) => {
    return <Image src={placeholderSVG}
                  alt={`Car placeholder image`}
                  objectFit='cover'
                  w='full'
                  h='full'
                  {...props}
    />;
}