import {
    AspectRatio,
    Box,
    Circle,
    Flex,
    HStack,
    IconButton, Link,
    Stack,
    Text, useBreakpointValue
} from '@chakra-ui/react';
import React, {useEffect, useRef, useState} from 'react';
import {IoChevronBackOutline, IoChevronForwardOutline} from 'react-icons/io5';
import {Carousel, CarouselSlide, useCarousel} from './Carousel';
import {FetchableImage} from "../FetchableImage/FetchableImage";
import {PlaceholderImage} from "../PlaceholderImage/PlaceholderImage";
import {TfiFullscreen} from "react-icons/tfi";
import {IoMdArrowBack} from "react-icons/io";
import {PictureCounterBox} from "../PictureCounterBox/PictureCounterBox";

export const Gallery2 = ({
                             images,
                             aspectRatio = 4 / 3,
                             rootProps,
                             setShowGallery,
                             imageIndex = 0,
                             backLink = setShowGallery.toggle
                         }) => {
    const [currentSlide, setCurrentSlide] = useState(imageIndex);
    const [loaded, setLoaded] = useState(new Array(images.length).fill(false)); // Proper initialization of loaded state

    const [ref, slider] = useCarousel({
        slideChanged: (slider) => setCurrentSlide(slider.track.details.rel),
    });

    const THUMBNAILS_PER_VIEW = 8;
    const VISIBLE_THUMBNAILS = 4;

    const [thumbnailRef, thumbnailSlider] = useCarousel(
        {
            initial: 0,
            slides: {
                perView: THUMBNAILS_PER_VIEW,
                spacing: 10,
            },
        }
    );

    useEffect(() => {
        if (thumbnailSlider.current) {
            const targetIndex = Math.floor(currentSlide / VISIBLE_THUMBNAILS) * VISIBLE_THUMBNAILS;
            thumbnailSlider.current.moveToIdx(targetIndex);
        }
    }, [currentSlide, thumbnailSlider]);

    console.log('Gallery2 imageIndex', imageIndex)

    const hasPrevious = currentSlide !== 0;
    const hasNext = currentSlide < images.length - 1;

    const initialMaxH = useBreakpointValue({base: '400px', md: '500px', lg: '600px', xl: '100vh'});
    const [maxH, setMaxH] = useState(initialMaxH);

    useEffect(() => { // Reset maxH to initialMaxH when the breakpoint changes
        setMaxH(initialMaxH);
    }, [initialMaxH]);

    const toggleMaxH = () => setMaxH(maxH === initialMaxH ? '100vh' : initialMaxH);
    const showFullScreenButton = useBreakpointValue({base: false, md: true, xl: false});
    const showThumbnails = useBreakpointValue({base: false, md: true});
    const isDesktop = useBreakpointValue({base: false, md: true});

    useEffect(() => {
        if (slider.current) {
            console.log('Gallery2 useEffect currentSlide', currentSlide);
            slider.current.moveToIdx(currentSlide);
        }
    }, [imageIndex, slider]);


    useEffect(() => {
        setLoaded(prevLoaded => {
            const newLoaded = [...prevLoaded];
            newLoaded[currentSlide] = true;
            return newLoaded;
        });
    }, [currentSlide]);

    const stackRef = useRef(null);
    useEffect(() => {
        if (stackRef.current) {
            stackRef.current.focus(); // Focus the Stack component
        }
    }, []);

    const onKeyDown = (e) => {
        console.log('Gallery2 onKeyDown', e);
        if (e.key === 'ArrowLeft') {
            slider.current?.prev();
        } else if (e.key === 'ArrowRight') {
            slider.current?.next();
        }
    };

    return (
        <Stack
            id='stack-gallery2'
            gap={[1,2]}
            {...rootProps}
            ref={stackRef}
            tabIndex={0}
            onKeyDown={onKeyDown}
            w='full'
            sx={{'&:focus': {outline: 'none'}}}
        >
            <Box id='box1-gallery2'>
                <HStack spacing="2" justifyContent='space-between'>
                    {/*{isDesktop &&*/}
                    <IconButton aria-label={'Back'}
                                rounded='full'
                                icon={<IoMdArrowBack size='24'/>}
                                colorScheme='gray'
                                variant='ghost'
                                onClick={() => backLink()}/>

                    {/*<Link onClick={() => backLink()}>Back</Link>*/}

                    <HStack alignItems='center' d='flex' gap={6}>
                        {showFullScreenButton && <TfiFullscreen onClick={toggleMaxH} cursor='pointer'/>}
                        {/*<Text>{currentSlide + 1}{'/'}{images.length}</Text>*/}
                    </HStack>
                </HStack>
            </Box>
            <Box
                id='box2-gallery2'
                sx={boxSX}
                position='relative'
                pt={0}
            >
                <Carousel ref={ref} style={{height: '100%'}}>
                    {images.map((image, i) => {
                        return (
                            <CarouselSlide key={i} style={carouselStyle}>
                                <AspectRatio
                                    ratio={aspectRatio}
                                    transition="all 200ms"
                                    opacity={currentSlide === i ? 1 : 0.4}
                                    _hover={{opacity: 1}}
                                    width={`calc(${maxH} * ${aspectRatio})`}
                                >

                                    {loaded[i]
                                        ? <Box><FetchableImage blobPath={image}
                                            // fallback={<PlaceholderImage/>}
                                        />
                                            <PictureCounterBox currentImage={i + 1} totalImages={images.length}/>
                                        </Box>
                                        : <PlaceholderImage/>}

                                </AspectRatio>
                            </CarouselSlide>
                        );
                    })}
                </Carousel>

                {/*Navigation Buttons*/}
                {hasPrevious && (
                    <IconButton
                        {...iconButtonStyles}
                        pos="absolute"
                        left="3"
                        top="50%"
                        transform="translateY(-50%)"
                        onClick={() => slider.current?.prev()}
                        icon={<IoChevronBackOutline/>}
                        aria-label="Previous Slide"
                    />
                )}

                {hasNext && (
                    <IconButton
                        {...iconButtonStyles}
                        pos="absolute"
                        right="3"
                        top="50%"
                        transform="translateY(-50%)"
                        onClick={() => slider.current?.next()}
                        icon={<IoChevronForwardOutline/>}
                        aria-label="Next Slide"
                    />
                )}

            </Box>

            {/*Slide Indicators and thumbnails*/}

            {isDesktop && (
                showThumbnails
                    ? <Flex
                        id='gallery2-thumbnails'
                        spacing="4"
                        mt={2}
                        overflow="hidden"
                        justifyContent={images.length < 8 ? 'center' : 'start'}
                        ref={thumbnailRef}
                    >
                        {images.map((image, index) => (
                            <Box id={`slide-thumbnail-${index}`}
                                 key={index}
                                 className='chakra-carousel__slide'
                                 onClick={() => slider.current.moveToIdx(index)}
                                 opacity={currentSlide === index ? 1 : 0.6}
                                 transition="all 100ms"
                                 cursor='pointer'
                                 mx={1}
                                 _hover={{opacity: 1}}
                            >
                                <FetchableImage blobPath={image} fallback={<PlaceholderImage/>}/>
                            </Box>
                        ))}
                    </Flex>

                    : <HStack position="absolute" width="full" justify="center" bottom="0" py="4">
                        {images.map((_, index) => (
                            <Circle key={index} size="2" bg={currentSlide === index ? 'white' : 'whiteAlpha.400'}/>
                        ))}
                    </HStack>)}
        </Stack>
    );
};

const iconButtonStyles = {
    display: 'none',
    fontSize: 'lg',
    isRound: true,
    boxShadow: 'base',
    color: 'gray.600',
    bg: 'white',
    _hover: {bg: 'gray.100',},
    _active: {bg: 'gray.200',},
    _focus: {boxShadow: 'inherit'},
    _focusVisible: {boxShadow: 'outline'},
};
const carouselStyle = {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};
const boxSX = {
    _hover: {
        '> button': {
            display: 'inline-flex',
        },
    },
};