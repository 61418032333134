import React from 'react';
import {
    FormControl, Select,
} from '@chakra-ui/react';
import {ChevronDown} from '../../icons/20';

const SelectFieldSimple2 = ({label, options, handler, fieldFromStore, field}) => {

    const isScalar = (value) => {
        return (typeof value === 'string' || typeof value === 'number');
    };

    const isValueScalar = isScalar(fieldFromStore.value);
    // if (!isValueScalar) {
    //     console.log('SelectFieldSimple2 isValueScalar', field, 'FALSE', fieldFromStore.value, options);
    // } else {
    //     console.log('SelectFieldSimple2 isValueScalar is true', field, fieldFromStore.value, options);
    // }

    // console.log('SelectFieldSimple2 selectValue:', field, isValueScalar, fieldFromStore.value);

    return <FormControl>
        <Select
            variant={(fieldFromStore.name && fieldFromStore.name.length > 1) ? 'selected' : 'primary'}
            icon={<ChevronDown/>}
            px={{field: {base: 3, md: 4}}}
            placeholder={label}
            value={fieldFromStore.value || ''} //select value
            onChange={handler}
            disabled={options.length < 2}
            multiple={!isValueScalar} //prevent warning in console
        >
            {options.map(({name, value, disabled}, index) => {

                return <option
                    style={{marginLeft: !disabled && '0.5rem'}}
                    key={`${name}-${index}`}
                    value={value ? value : ''} //option value
                    disabled={disabled}>
                    {name}
                </option>
            })}
        </Select>
    </FormControl>
}

export default React.memo(SelectFieldSimple2);