import React from 'react';
import {
    Box, Container, Flex, Grid, GridItem, IconButton, Text, useDisclosure, VStack,
} from '@chakra-ui/react';

import {Globe} from '../../icons/20';
import {FooterList} from './components/FooterList';
import {LanguageModal} from "../Modals/Language/LanguageModal";
import {footerLinks} from './components/footerLinks';
import {useTranslation} from "react-i18next";

export const Footer = () => {

    const {t} = useTranslation(['texts'])
    const {isOpen, onOpen, onClose} = useDisclosure()

    // console.log('Footer', footerLinks);

    return <>
        <Container bg="neutral.900" maxW="vw100" py={8} px={0} as='footer'>
            <Container maxW="container.xl" color="white" textStyle="xs">
                <Grid
                    templateColumns={['repeat(6, 2fr)', 'repeat(10, 1fr)']}
                    gap={[4]}
                    rowGap={6}
                >
                    {/** Company */}
                    <GridItem colSpan={2}>
                        <FooterList data={footerLinks[0]}/>
                    </GridItem>

                    {/** Support */}
                    <GridItem colSpan={2}>
                        <FooterList data={footerLinks[1]}/>
                    </GridItem>

                    {/** Social Media */}
                    <GridItem colSpan={2}>
                        <FooterList data={footerLinks[2]}/>
                    </GridItem>

                    {/** Vision */}
                    <GridItem colSpan={[6, 4]}>
                        <VStack align="start" spacing={1}>
                            <Text fontWeight="bold" textTransform="uppercase" mb={2}>
                                {t('texts:footer.vision')}
                            </Text>
                            <Box>
                                {t('texts:footer.vision-text')}
                            </Box>
                        </VStack>
                    </GridItem>
                </Grid>
                <Flex
                    justifyContent="space-between"
                    w="inherit"
                    alignItems="end"
                    mt={[4, 1]}
                >
                    <IconButton
                        aria-label="Change language"
                        variant="ghost"
                        icon={<Globe height='18px' width='18px' color='#f4f4f4'/>}
                        isRound={true}
                        onClick={onOpen}
                    />
                    <Text fontSize="10px">
                        &copy; {new Date().getFullYear()} Caruniverse. All rights reserved
                    </Text>
                </Flex>
            </Container>
        </Container>
        <LanguageModal isOpen={isOpen} onClose={onClose} onOpen={onOpen}/>
    </>
};