import {Text, VStack} from '@chakra-ui/react';
import {LargeCards} from "./LargeCards";
import {useTranslation} from "react-i18next";

export const PopularPriceRanges = () => {

    const {t} = useTranslation(['texts']);

    return <VStack align="start" spacing={{base: 4, lg: 6}} w="full">
        <Text as="h3" textStyle="h2" textTransform="uppercase" mb={0}>
            {t('texts:home.popular-price-ranges')}
        </Text>
        <LargeCards />
    </VStack>
}