import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, Card, CardBody} from '@chakra-ui/react';
import {NewSellerForm} from './NewSellerForm';
import {ExistingSeller} from './ExistingSeller';
import {useProfileStore, useSellerDataStore} from "../../state/store";
import {AzureSellersRESTConnector} from "../../integration/tables/AzureSellersRESTConnector";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {getSHA1} from "../../integration/storage/storageUtils";
import {isEmpty} from "lodash";
import {useNavigate} from "react-router-dom";
import {PlaceholderImage} from "../PlaceholderImage/PlaceholderImage";

export const MyAccount = () => {

    console.log('MyAccount');

    const [isExisting, setIsExisting] = useState(false); //later will be derived from sellerInfo after auth
    const [isLoading, setIsLoading] = useState(false);

    const {instance, accounts} = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const profileStore = useProfileStore();
    const sellerStore = useSellerDataStore();

    const [sellerInfo, setSellerInfo] = useState({});


    const sellersConnector = useMemo(() => new AzureSellersRESTConnector(), []);

    const [username, setUsername] = useState('');

    const memoizedSellerInfo = useMemo(() => sellerInfo, [sellerInfo]);
    const memoizedUsername = useMemo(() => username, [username]);

    const getSellerInfo = useCallback(async () => {
        const username = accounts[0].username;
        const usernameAsSHA1 = await getSHA1(username);
        const info = await sellersConnector.retrieveSellerInfo(usernameAsSHA1);
        setUsername(username);
        setIsExisting(!isEmpty(info));
        sellerStore.setSellerData(info);
        // setSellerInfo(info);
    }, [accounts, sellersConnector]);

    useEffect(() => {
        const fetchSellerInfo = async () => {
            if (accounts.length > 0 && isAuthenticated) {
                setIsLoading(true);
                try {
                    await getSellerInfo();
                } catch (e) {
                    console.error('MyAccount useEffect', e);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchSellerInfo();
    }, [accounts, isAuthenticated, getSellerInfo, profileStore.needsRefresh]);


    const logout = useCallback(async () => {
        if (isAuthenticated) {
            await instance.logout({});
            navigate("/");
        }
    }, [isAuthenticated, instance, navigate]);

    return <Card w="full" id='new user' maxW={686}>
        <CardBody px={0}>
            <Box mt={2}>
                {isLoading && <PlaceholderImage rounded={'xl'} maxH='220px' bg='neutral.25'/>}
                {!isLoading && <Box>
                    {isExisting
                        ? <ExistingSeller
                            sellerInfo={sellerStore.sellerData}
                            // sellerInfo={memoizedSellerInfo}
                            username={memoizedUsername}
                            logout={logout}
                        />
                        : <NewSellerForm logout={logout}/>
                    }
                </Box>}
            </Box>
        </CardBody>
    </Card>
}