import React from 'react';
import {Box, HStack, Icon, Text} from '@chakra-ui/react';
import {PiImageFill} from 'react-icons/pi';

export const PictureCounterBox = ({
                                      h = '20px',
                                      w = 'auto',
                                      pos = 'absolute',
                                      top,
                                      bottom = '0px',
                                      right = '0px',
                                      left,
                                      currentImage,
                                      totalImages = 0
                                  }) => (
    <Box
        aria-label="Camera Icon"
        pos={pos}
        top={top}
        bottom={bottom}
        right={right}
        left={left}
        h={h}
        w={w}
        borderRadius="none"
        borderColor="transparent"
        bg="rgba(34, 34, 34, 0.65)"
        paddingX={1}
    >
        <HStack
            spacing="5px"
            height="full!important"
            width="full!important"
            justifyContent="center"
            px={'1px'}
            display="flex"
            alignItems="center"
        >
            <Icon as={PiImageFill} height="14px" color="white" px={0}/>
            {currentImage === undefined && < Text fontSize="11px" color="neutral.25">
                {totalImages}
            </Text>}
            {currentImage !== undefined && totalImages !== undefined && (
                <Text fontSize="11px" color="neutral.25">
                    {currentImage} / {totalImages}
                </Text>
            )}
        </HStack>
    </Box>
);