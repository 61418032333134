import {useTranslation} from "react-i18next";
import {AzureAdvertisementsRESTConnector} from "../../integration/tables/AzureAdvertisementsRESTConnector";
import {useDictionaryStore, useProfileStore, useSellDictionaryStore} from "../../state/store";
import {useMsal} from "@azure/msal-react";
import React, {useEffect, useState} from "react";
import {initialOptions} from "../../state/initialstate";
import {formatCities, formatDictionary} from "../SimpleSearch/simpleSearchHelper";
import {capitalize, truncate} from "lodash";
import {
    clearFields,
    lowerCase,
    updateObjectForInsert,
    verifyMandatoryFields
} from "../../integration/tables/tableUtils";
import {
    Box,
    Flex,
    Input,
    TableContainer,
    Table,
    Tbody,
    Tr,
    Td,
    Text,
    Tag,
    Textarea,
    Wrap, Button
} from "@chakra-ui/react";
import SelectFieldSimple2 from "../Search/SelectFieldSimple2";
import {CheckboxList} from "../CheckboxList/CheckboxList";
import {LuClipboardCheck, LuClipboardEdit, LuFileLock} from "react-icons/lu";
import {getTitleFromDictionary} from "../../integration/i18n/languageUtils";

export const EditMetadata = ({car, onClose, isPublished}) => {

    const {t} = useTranslation(['extended-dictionary', 'data-for-ui']);
    const advConnector = new AzureAdvertisementsRESTConnector();
    const profileStore = useProfileStore();
    const {accounts} = useMsal();

    /** store dictionaries */
    const appDictionaryStore = useDictionaryStore();
    const sellDictionaryStore = useSellDictionaryStore();
    console.log('EditMetadata appDictionaryStore', appDictionaryStore);

    /** dropdowns - empty values */
    const [fuel, setFuel] = useState(initialOptions.fuel);
    const [transmission, setTransmission] = useState(initialOptions.transmission);
    const [drivetrain, setDrivetrain] = useState(initialOptions.drivetrain);
    const [country, setCountry] = useState(initialOptions.country);
    const [city, setCity] = useState(initialOptions.city);
    const [cities, setCities] = useState([]);
    const [vatIncluded, setVatIncluded] = useState(initialOptions.vatIncluded);
    const [color, setColor] = useState({value: '', name: '', visible: false});
    const [colors, setColors] = useState(initialOptions.color);


    /** inputs */
    const [modification, setModification] = useState(car.modification);
    const [year, setYear] = useState(car.year);
    const [mileage, setMileage] = useState(car.mileage);
    const [price, setPrice] = useState(car.price);
    const [power, setPower] = useState(car.power);
    const [displacement, setDisplacement] = useState(car.displacement);
    const [comment, setComment] = useState(car.comment);

    /** options and history */
    const [options, setOptions] = useState(car.options);
    const [history, setHistory] = useState(car.history);

    /** vats simple dictionary, dont need country-specific values here */
    const vats = [
        {value: '', name: '', visible: "false", disabled: "true"},
        {value: "true", name: 'included', visible: "true"},
        {value: "false", name: 'no vat', visible: "true"}
    ];

    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        /** set initial field values to car */

        /** fuel */
        /* 1. we get extended dictionary for display of all possible values */
        /* 2. in a dropdown for user selection, we use normal appDictionary */
        const fuels_d = t('fuels', {returnObjects: true});
        const extended_fuels = formatDictionary(fuels_d);
        const matchingFuel = extended_fuels.find(item => item.value === car.fuel);
        setFuel({...matchingFuel, visible: true});

        /** transmission */
        const transmissions_d = t('transmissions', {returnObjects: true});
        const extended_transmissions = formatDictionary(transmissions_d);
        const matchingTrns = extended_transmissions.find(item => item.value === car.transmission);
        setTransmission({...matchingTrns, visible: true});

        /** drivetrain */
        const drivetrains_d = t('drivetrains', {returnObjects: true});
        const extended_drivetrains = formatDictionary(drivetrains_d);
        const matchingDrivetrain = extended_drivetrains.find(item => item.value === car.drivetrain);
        setDrivetrain({...matchingDrivetrain, visible: true});

        /** color */
        const matchingColor = appDictionaryStore.colors.find(item => item.value === car.color); //set color from advertisement
        setColor({...matchingColor, visible: true});

        /** colors */
        const colors = appDictionaryStore.colors;
        // colors.unshift({value: '', name: '', visible: false, disabled: true});
        setColors(colors);

        /** country */
        const matchingCountry = sellDictionaryStore.countries.find(item => item.value.toLowerCase() === car.country);
        setCountry({...matchingCountry, visible: true});

        /** transform cities into array with proper properties */
        const formattedCities = formatCities(matchingCountry.cities);
        setCities(formattedCities);

        /** city */
        const matchingCity = matchingCountry['cities'][capitalize(car.city)];
        setCity({
            value: car.city,
            name: matchingCity ? capitalize(matchingCity.title) : capitalize(car.city),
            visible: true
        });

        /** options */
        const matchingOptions = appDictionaryStore.features.filter(item => car.options.includes(item.value));
        setOptions(matchingOptions);

        /** history */
        const matchingHistory = appDictionaryStore.history.filter(item => car.history.includes(item.value));
        setHistory(matchingHistory);

        /** vat */
        if (car.vatIncluded) {
            const vat = vats.find(item => item.value.toString() === car.vatIncluded);
            setVatIncluded(vat)
        } else {
            const vat = vats.find(item => item.value.toString() === 'false');
            setVatIncluded(vat);
        }

        console.log('EditMetadata useEffect', car.vin);
    }, []);


    const [editModification, setEditModification] = useState(false);
    const [editYear, setEditYear] = useState(false);
    const [editMileage, setEditMileage] = useState(false);
    const [editPrice, setEditPrice] = useState(false);
    const [editVAT, setEditVAT] = useState(false);
    const [editFuel, setEditFuel] = useState(false);
    const [editTransmission, setEditTransmission] = useState(false);
    const [editDrivetrain, setEditDrivetrain] = useState(false);
    const [editPower, setEditPower] = useState(false);
    const [editDisplacement, setEditDisplacement] = useState(false);
    const [editColor, setEditColor] = useState(false);
    const [editCountry, setEditCountry] = useState(false);
    const [editCity, setEditCity] = useState(false);
    const [editComment, setEditComment] = useState(false);
    const [editOptions, setEditOptions] = useState(false);
    const [editHistory, setEditHistory] = useState(false);


    const changeModification = e => setModification(e.target.value);

    const saveModification = () => setEditModification(false);

    const changeYear = e => setYear(e.target.value);

    const saveYear = () => setEditYear(false);

    const changeMileage = e => setMileage(e.target.value);

    const saveMileage = () => setEditMileage(false);

    const changePrice = e => setPrice(e.target.value);

    const savePrice = () => setEditPrice(false);

    const changeFuel = e => {
        const fuel = e.target.value;
        console.log('AddCar changeFuel ', fuel);
        if (fuel) {
            const matchingFuel = appDictionaryStore.fuels.find(item => item.value === fuel);
            console.log('AddCar changeFuel matchingFuel', matchingFuel);
            setFuel({...matchingFuel, visible: true});
        }
    }

    const saveFuel = () => setEditFuel(false);

    const changeTransmission = e => {
        const transmission = e.target.value;
        console.log('AddCar changeTransmission ', transmission);
        if (transmission) {
            const matchingTrns = appDictionaryStore.transmissions.find(item => item.value === transmission);
            console.log('AddCar changeTransmission matchingTrns', matchingTrns);
            setTransmission({...matchingTrns, visible: true});
        }
    }
    const saveTransmission = () => setEditTransmission(false);

    const changeDrivetrain = e => {
        const drivetrain = e.target.value;
        console.log('AddCar changeDrivetrain ', drivetrain);
        if (drivetrain) {
            const matchingDrivetrain = appDictionaryStore.drivetrains.find(item => item.value === drivetrain);
            console.log('AddCar changeDrivetrain matchingDrivetrain', matchingDrivetrain);
            setDrivetrain({...matchingDrivetrain, visible: true});
        }
    }

    const saveDrivetrain = () => setEditDrivetrain(false);

    const changeColor = e => {
        const color = e.target.value;
        console.log('AddCar changeColor ', color);
        if (color) {
            const matchingColor = appDictionaryStore.colors.find(item => item.value === color);
            console.log('AddCar changeColor matchingColor', matchingColor);
            setColor({...matchingColor, visible: true});
        }
    }

    const saveColor = () => setEditColor(false);

    const changeCountry = e => {
        const country = e.target.value;
        console.log('UpdateAdvertisementModal changeCountry ', country);
        if (country) {
            const matchingCountry = sellDictionaryStore.countries.find(item => item.value.toLowerCase() === country.toLowerCase());
            console.log('UpdateAdvertisementModal changeCountry matchingCountry', matchingCountry);
            setCountry({...matchingCountry, visible: true});

            /** transform cities into array with proper properties */
            const formattedCities = formatCities(matchingCountry.cities);
            setCities(formattedCities);

            /** reset city, if country was changed */
            setCity(initialOptions.city);
        }
    }
    const saveCountry = () => setEditCountry(false);


    const changeCity = e => {
        const city = e.target.value;
        console.log('AddCar changeCity ', city);
        if (city) {
            const matchingCity = country['cities'][capitalize(city)];
            console.log('AddCar changeCity matchingCity', matchingCity);
            setCity({
                value: city,
                name: matchingCity ? capitalize(matchingCity.title) : capitalize(city),
                visible: true
            });
        }
    }

    const saveCity = () => setEditCity(false);

    const changePower = e => setPower(e.target.value);

    const savePower = () => setEditPower(false);

    const changeDisplacement = e => setDisplacement(e.target.value);

    const saveDisplacement = () => setEditDisplacement(false);

    const changeOptions = (e, feature) => {
        if (e.target.checked) {
            const item = appDictionaryStore.features.find(item => item.value === feature.value);
            setOptions([...options, item]);
        } else {
            setOptions(options.filter(item => item.value !== feature.value));
        }
    };

    const saveOptions = () => setEditOptions(false);

    const changeHistory = (e, entry) => {
        if (e.target.checked) {
            const item = appDictionaryStore.history.find(item => item.value === entry.value);
            setHistory([...history, item]);
        } else {
            setHistory(history.filter(item => item.value !== entry.value));
        }
    };

    const saveHistory = () => setEditHistory(false);

    const changeVAT = e => {
        const vat = e.target.value;
        if (vat) {
            const matchingVAT = vats.find(item => item.value === vat);
            console.log('UpdateAdvertisementModal changeVAT matchingVAT', matchingVAT, 'vats', vats, vat, 'typeof vat', typeof vat);
            setVatIncluded(matchingVAT);
        } else {
            const matchingVAT = vats.find(item => item.value === car.vatIncluded); //initial value
            console.log('UpdateAdvertisementModal changeVAT matchingVAT', matchingVAT, 'vats', vats, vat, 'typeof vat', typeof vat);
            setVatIncluded(matchingVAT);
        }
    }

    const saveVAT = () => setEditVAT(false);

    const changeComment = e => setComment(e.target.value);

    const saveComment = () => setEditComment(false);

    const saveAdvertisement = async () => {

        const authenticatedUser = accounts[0].username;

        console.log('UpdateAdvertisementModal saveAdvertisement: vat', vatIncluded);

        const data = {
            userID: car.userID === authenticatedUser ? authenticatedUser : undefined, //check if user is authenticated
            make: car.make,
            model: car.model,
            originalVIN: car.originalVIN || car.vin,
            modification,
            year,
            mileage,
            price,
            vatIncluded: vatIncluded.value,
            fuel: fuel.value,
            transmission: transmission.value,
            drivetrain: drivetrain.value,
            power,
            displacement,
            color: color.value,
            country: country.value,
            city: city.value,
            options: options.map(item => item.value),
            history: history.map(item => item.value),
            comment,
            containerID: car.containerID,
            vin: car.vin,
        };

        const errors = [];
        const isReady = verifyMandatoryFields(data, errors);

        console.log('vin', data.vin);

        if (isReady && errors.length === 0) {
            const cleared = clearFields(data);
            const lowered = lowerCase(data);
            lowered.blobPaths = car.blobPaths;
            updateObjectForInsert(lowered);
            console.log('UpdateAdvertisementModal saveAdvertisement: cleared', cleared, lowered);


            try {
                setIsLoading(true);
                const response = await advConnector.updateAdvertisementRecord(car.make, car.vin, lowered, profileStore.token);
                console.log('UpdateAdvertisementModal saveAdvertisement: response', response);

                profileStore.setNeedsRefresh(true); //fetch ads in MyOffers useEffect

                onClose();
            } finally {
                setIsLoading(false);
            }

        } else {
            console.log('UpdateAdvertisementModal saveAdvertisement: errors found: ', errors);
        }
    }

    return <Box bg={'white'}>
        <TableContainer py={4}>
            <Table>
                <Tbody>
                    {/** Make */}
                    <Tr>
                        <Td w={['130px', 'auto']} pr={2}>{t("texts:sell.characteristics.make")}</Td>
                        <Td pl={2}>{capitalize(car.make)}</Td>
                        <Td w={['40px', 'auto']} pl={[2, 4]} pr={5}><Flex justifyContent={'center'}><LuFileLock
                            stroke={'grey'}/></Flex></Td>
                    </Tr>

                    {/** Model */}
                    <Tr>
                        <Td w={['130px', 'auto']} pr={2}>{t("texts:sell.characteristics.model")}</Td>
                        <Td pl={2}>{capitalize(car.model)}</Td>
                        <Td w={['40px', 'auto']} pl={[2, 4]} pr={5}><Flex justifyContent={'center'}><LuFileLock
                            stroke={'grey'}/></Flex></Td>
                    </Tr>

                    {/** VIN */}
                    <Tr>
                        <Td w={['130px', 'auto']} pr={2}>VIN</Td>
                        <Td pl={2} textTransform='uppercase'
                            maxW={['100px', 'auto']}>{(car.originalVIN || car.vin)}</Td>
                        <Td w={['40px', 'auto']} pl={[2, 4]} pr={5}><Flex justifyContent={'center'}><LuFileLock
                            stroke={'grey'}/></Flex></Td>
                    </Tr>


                    {/** Modification */}
                    <Tr>
                        <Td w={['130px', 'auto']} pr={2}>{t("texts:sell.characteristics.modification")}</Td>
                        <Td pl={2}>
                            {editModification
                                ? <Input type='text' value={modification} onChange={changeModification} autoFocus/>
                                : <Text>{modification}</Text>
                            }
                        </Td>
                        <Td w={['40px', 'auto']} pl={[2, 4]} pr={5}
                            onClick={editModification ? saveModification : () => setEditModification(true)}>
                            <Flex justifyContent={'center'}>
                                {editModification
                                    ? <LuClipboardCheck stroke='limegreen'/>
                                    : <LuClipboardEdit stroke='red'/>
                                } </Flex>
                        </Td>
                    </Tr>


                    {/** Year */}
                    <Tr>
                        <Td w={['130px', 'auto']} pr={2}>{t("texts:sell.characteristics.year")}</Td>
                        <Td pl={2}>
                            {editYear
                                ? <Input type='number' value={year} onChange={changeYear} autoFocus/>
                                : <Text>{year}</Text>}
                        </Td>
                        <Td w={['40px', 'auto']} pl={[2, 4]} pr={5}
                            onClick={editYear ? saveYear : () => setEditYear(true)}>
                            <Flex justifyContent={'center'}>
                                {editYear
                                    ? <LuClipboardCheck stroke='limegreen'/>
                                    : <LuClipboardEdit stroke='red'/>
                                }
                            </Flex>
                        </Td>
                    </Tr>


                    {/** Mileage */}
                    <Tr>
                        <Td w={['130px', 'auto']} pr={2}>{t("texts:sell.characteristics.mileage")}</Td>
                        <Td pl={2}>
                            {editMileage
                                ? <Input type='number' value={mileage} onChange={changeMileage} autoFocus/>
                                : <Text>{mileage}</Text>}
                        </Td>
                        <Td w={['40px', 'auto']} pl={[2, 4]} pr={5}
                            onClick={editMileage ? saveMileage : () => setEditMileage(true)}>
                            <Flex justifyContent={'center'}>
                                {editMileage
                                    ? <LuClipboardCheck stroke='limegreen'/>
                                    : <LuClipboardEdit stroke='red'/>}
                            </Flex>
                        </Td>
                    </Tr>


                    {/** Price */}
                    <Tr>
                        <Td w={['130px', 'auto']} pr={2}>{t("texts:sell.characteristics.price")}</Td>
                        <Td pl={2}>
                            {editPrice
                                ? <Input type='number' value={price} onChange={changePrice} autoFocus/>
                                : <Text>{price}</Text>}
                        </Td>
                        <Td w={['40px', 'auto']} pl={[2, 4]} pr={5}
                            onClick={editPrice ? savePrice : () => setEditPrice(true)}>
                            <Flex justifyContent={'center'}>
                                {editPrice
                                    ? <LuClipboardCheck stroke='limegreen'/>
                                    : <LuClipboardEdit stroke='red'/>}
                            </Flex>
                        </Td>
                    </Tr>


                    {/** VAT */}
                    <Tr>
                        <Td w={['130px', 'auto']} pr={2}>VAT</Td>
                        <Td pl={2}>
                            {editVAT
                                ? <SelectFieldSimple2
                                    label={'VAT'}
                                    field='vat'
                                    options={vats}
                                    fieldFromStore={vatIncluded}
                                    handler={changeVAT}
                                />
                                : <Text>{vatIncluded.name}</Text>}
                        </Td>
                        <Td w={['40px', 'auto']} pl={[2, 4]} pr={5}
                            onClick={editVAT ? saveVAT : () => setEditVAT(true)}>
                            <Flex justifyContent={'center'}>
                                {editVAT
                                    ? <LuClipboardCheck stroke='limegreen'/>
                                    : <LuClipboardEdit stroke='red'/>}
                            </Flex>
                        </Td>
                    </Tr>


                    {/** Fuel */}
                    <Tr>
                        <Td w={['130px', 'auto']} pr={2}>{t("texts:sell.characteristics.fuel")}</Td>
                        <Td pl={2}>
                            {editFuel
                                ? <SelectFieldSimple2
                                    label={'Fuel'}
                                    field='fuel'
                                    options={appDictionaryStore.fuels}
                                    fieldFromStore={fuel}
                                    handler={changeFuel}
                                />
                                : <Text>{fuel.name}</Text>}
                        </Td>
                        <Td w={['40px', 'auto']} pl={[2, 4]} pr={5}
                            onClick={editFuel ? saveFuel : () => setEditFuel(true)}>
                            <Flex justifyContent={'center'}>
                                {editFuel
                                    ? <LuClipboardCheck stroke='limegreen'/>
                                    : <LuClipboardEdit stroke='red'/>}
                            </Flex>
                        </Td>
                    </Tr>


                    {/** Transmission */}
                    <Tr>
                        <Td w={['130px', 'auto']} pr={2}>{t("texts:sell.characteristics.transmission")}</Td>
                        <Td pl={2}>
                            {editTransmission
                                ? <SelectFieldSimple2
                                    label={'Transmission'}
                                    field='transmission'
                                    options={appDictionaryStore.transmissions}
                                    fieldFromStore={transmission}
                                    handler={changeTransmission}
                                />
                                : <Text>{transmission.name}</Text>}
                        </Td>
                        <Td w={['40px', 'auto']} pl={[2, 4]} pr={5}
                            onClick={editTransmission ? saveTransmission : () => setEditTransmission(true)}>
                            <Flex justifyContent={'center'}>
                                {editTransmission
                                    ? <LuClipboardCheck stroke='limegreen'/>
                                    : <LuClipboardEdit stroke='red'/>}
                            </Flex>
                        </Td>
                    </Tr>


                    {/** Drivetrain */}
                    <Tr>
                        <Td w={['130px', 'auto']} pr={2}>{t("texts:sell.characteristics.drivetrain")}</Td>
                        <Td pl={2}>
                            {editDrivetrain
                                ? <SelectFieldSimple2
                                    label={'Drivetrain'}
                                    field='drivetrain'
                                    options={appDictionaryStore.drivetrains}
                                    fieldFromStore={drivetrain}
                                    handler={changeDrivetrain}
                                />
                                : <Text>{drivetrain.name}</Text>}
                        </Td>
                        <Td w={['40px', 'auto']} pl={[2, 4]} pr={5}
                            onClick={editDrivetrain ? saveDrivetrain : () => setEditDrivetrain(true)}>
                            <Flex justifyContent={'center'}>
                                {editDrivetrain
                                    ? <LuClipboardCheck stroke='limegreen'/>
                                    : <LuClipboardEdit stroke='red'/>}
                            </Flex>
                        </Td>
                    </Tr>


                    {/** Power */}
                    <Tr>
                        <Td w={['130px', 'auto']} pr={2}>{t("texts:sell.characteristics.power")}</Td>
                        <Td pl={2}>
                            {editPower
                                ? <Input type='number' value={power} onChange={changePower} autoFocus/>
                                : <Text>{power}</Text>}
                        </Td>
                        <Td w={['40px', 'auto']} pl={[2, 4]} pr={5}
                            onClick={editPower ? savePower : () => setEditPower(true)}>
                            <Flex justifyContent={'center'}>
                                {editPower
                                    ? <LuClipboardCheck stroke='limegreen'/>
                                    : <LuClipboardEdit stroke='red'/>}
                            </Flex>
                        </Td>
                    </Tr>


                    {/** Displacement */}
                    <Tr>
                        <Td w={['130px', 'auto']} pr={2}>{t("texts:sell.characteristics.displacement")}</Td>
                        <Td pl={2}>
                            {editDisplacement
                                ? <Input type='number' value={displacement} onChange={changeDisplacement} autoFocus/>
                                : <Text>{displacement}</Text>}
                        </Td>
                        <Td w={['40px', 'auto']} pl={[2, 4]} pr={5}
                            onClick={editDisplacement ? saveDisplacement : () => setEditDisplacement(true)}>
                            <Flex justifyContent={'center'}>
                                {editDisplacement
                                    ? <LuClipboardCheck stroke='limegreen'/>
                                    : <LuClipboardEdit stroke='red'/>}
                            </Flex>
                        </Td>
                    </Tr>


                    {/** Color */}
                    <Tr>
                        <Td w={['130px', 'auto']} pr={2}>{t("texts:sell.characteristics.color")}</Td>
                        <Td pl={2}>
                            {editColor
                                ? <SelectFieldSimple2
                                    label={t("texts:sell.characteristics.color")}
                                    field='color'
                                    options={colors}
                                    fieldFromStore={color}
                                    handler={changeColor}
                                />
                                : <Text>{color.name}</Text>}
                        </Td>
                        <Td w={['40px', 'auto']} pl={[2, 4]} pr={5}
                            onClick={editColor ? saveColor : () => setEditColor(true)}>
                            <Flex justifyContent={'center'}>
                                {editColor
                                    ? <LuClipboardCheck stroke='limegreen'/>
                                    : <LuClipboardEdit stroke='red'/>}
                            </Flex>
                        </Td>
                    </Tr>


                    {/** Country */}
                    <Tr>
                        <Td w={['130px', 'auto']} pr={2}>{t("texts:sell.input-fields.country")}</Td>
                        <Td pl={2}>
                            {editCountry
                                ? <SelectFieldSimple2
                                    label={'Country'}
                                    field='country'
                                    options={sellDictionaryStore.countries}
                                    fieldFromStore={country}
                                    handler={changeCountry}
                                />
                                : <Text>{country.name}</Text>}
                        </Td>
                        <Td w={['40px', 'auto']} pl={[2, 4]} pr={5}
                            onClick={editCountry ? saveCountry : () => setEditCountry(true)}>
                            <Flex justifyContent={'center'}>
                                {editCountry
                                    ? <LuClipboardCheck stroke='limegreen'/>
                                    : <LuClipboardEdit stroke='red'/>}
                            </Flex>
                        </Td>
                    </Tr>


                    {/** City */}
                    <Tr>
                        <Td w={['130px', 'auto']} pr={2}>{t("texts:sell.input-fields.city")}</Td>
                        <Td pl={2}>
                            {editCity
                                ? <SelectFieldSimple2
                                    // label={'City'}
                                    field='city'
                                    options={cities}
                                    fieldFromStore={city}
                                    handler={changeCity}
                                />
                                : <Text>{city.name}</Text>}
                        </Td>
                        <Td w={['40px', 'auto']} pl={[2, 4]} pr={5}
                            onClick={editCity ? saveCity : () => setEditCity(true)}>
                            <Flex justifyContent={'center'}>
                                {editCity
                                    ? <LuClipboardCheck stroke='limegreen'/>
                                    : <LuClipboardEdit stroke='red'/>}
                            </Flex>
                        </Td>
                    </Tr>


                    {/** Options */}
                    <Tr>
                        <Td w={['130px', 'auto']} pr={2}>{t("texts:sell.input-fields.extra-features")}</Td>
                        <Td pl={2}>
                            {editOptions
                                ? <CheckboxList
                                    dictionary={appDictionaryStore.features}
                                    selectedItems={options}
                                    onChange={changeOptions}
                                />
                                : <Wrap>
                                    {options.map((opt, i) => (
                                        <Tag key={`tag-options-${i}`} bg={'white'} mr={2}>{opt.name}</Tag>
                                    ))}
                                </Wrap>}
                        </Td>
                        <Td w={['40px', 'auto']} pl={[2, 4]} pr={5}
                            onClick={editOptions ? saveOptions : () => setEditOptions(true)}>
                            <Flex justifyContent={'center'}>
                                {editOptions
                                    ? <LuClipboardCheck stroke='limegreen'/>
                                    : <LuClipboardEdit stroke='red'/>}
                            </Flex>
                        </Td>

                    </Tr>


                    {/** History */}
                    <Tr>
                        <Td w={['130px', 'auto']} pr={2}>{t("texts:sell.input-fields.history")}</Td>
                        <Td pl={2}>
                            {editHistory
                                ? <CheckboxList
                                    dictionary={appDictionaryStore.history}
                                    selectedItems={history}
                                    onChange={changeHistory}
                                />
                                : <Wrap>
                                    {history.map((hst, i) =>
                                        <Tag key={`tag-hst-${i}`} bg={'white'} mr={2}>{hst.name}</Tag>)
                                    }
                                </Wrap>}
                        </Td>
                        <Td w={['40px', 'auto']} pl={[2, 4]} pr={5}
                            onClick={editHistory ? saveHistory : () => setEditHistory(true)}>
                            <Flex justifyContent={'center'}>
                                {editHistory
                                    ? <LuClipboardCheck stroke='limegreen'/>
                                    : <LuClipboardEdit stroke='red'/>}
                            </Flex>
                        </Td>
                    </Tr>


                    {/** Comment */}
                    <Tr>
                        <Td w={['130px', 'auto']} pr={2}>{t("texts:sell.characteristics.comment")}</Td>
                        <Td pl={2}>
                            {editComment
                                ? <Textarea
                                    variant={comment?.length > 0 ? 'primaryActive' : 'primary'}
                                    value={comment} onChange={changeComment} autoFocus/>
                                : <Text>{truncate(comment, {length: 20})}</Text>}
                        </Td>
                        <Td w={['40px', 'auto']} pl={[2, 4]} pr={5}
                            onClick={editComment ? saveComment : () => setEditComment(true)}>
                            <Flex justifyContent={'center'}>
                                {editComment
                                    ? <LuClipboardCheck stroke='limegreen'/>
                                    : <LuClipboardEdit stroke='red'/>}
                            </Flex>
                        </Td>
                    </Tr>
                </Tbody>
            </Table>
        </TableContainer>

        {/** Save button */}
        <Button id='save-metadata-button'
                variant='primary'
                isDisabled={isPublished}
                isLoading={isLoading}
                onClick={saveAdvertisement}>
            {t("sell.car-ad-card.edit-modal.footer.save-changes")}
        </Button>
    </Box>
}