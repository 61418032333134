import React, {useState} from 'react';
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import PaymentConfirmation from "../PaymentConfirmation";
import {AzureUpgradeOrdersRESTConnector} from "../../UpgradeOrdersRESTConnector";
import {useProfileStore} from "../../../../../state/store";
import {Box} from "@chakra-ui/react";

const upgradeOrdersConnector = new AzureUpgradeOrdersRESTConnector();

export const Paypal = ({car, amount}) => {

    const [isComplete, setIsComplete] = useState(false);
    const paypalClientID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
    const profileStore = useProfileStore();
    const token = profileStore.token;

    return <Box w={'full'} justifyContent={'center'} id='paypal-payment-div'>
        <Box>
            <PayPalScriptProvider
                options={
                    {
                        "client-id": paypalClientID,
                        "intent": "capture",
                        "currency": "EUR"
                    }}>
                {!isComplete && <PayPalButtons
                    style={{layout: "horizontal"}}

                    createOrder={
                        async () => {
                            console.log('createOrder props', amount, car);
                            const orderID = await upgradeOrdersConnector.createOrderPayPal(amount, car, token);
                            console.log('createOrder orderID', orderID);
                            return orderID;

                        }}

                    onApprove={
                        async orderData => {
                            console.log('approveOrder', orderData);
                            const json = await upgradeOrdersConnector.approveOrderPayPal(orderData, token);
                            console.log("approveOrder json", json);
                            setIsComplete(true);
                        }}
                />}
                {isComplete && <PaymentConfirmation car={car} isComplete={isComplete}/>}
            </PayPalScriptProvider>
        </Box>
    </Box>
}