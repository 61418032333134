import React, {useEffect, useState} from 'react';
import {Container, Flex, HStack, Box, Text, Heading, UnorderedList, ListItem, Stack} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {Sidebar} from "../Sidebar";

const Section = ({title, children}) => (
    <Box mb={6}>
        {title && <Heading as="h2" size="md" mb={4}>{title}</Heading>}
        <Text>{children}</Text>
    </Box>
);

export const Protection = () => {
    const {t} = useTranslation(['support', 'texts']);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [active, setActive] = useState('protection');

    return (
        <Container maxW="container.xl" my={[4, 9]}>
            <Stack direction={{base: 'column', md: 'row'}}
                   align='start'
                   spacing={[8, 12]}
                   w="full"
            >
                <Sidebar active={active} setActive={setActive}/>
                <Flex direction="column" maxW={{base: '100%', md: '60%'}}>
                    <Section title={t('texts:footer.data-protection')}>
                        {t('company.protection.the-platform-to-connect-buyers-and-sellers')}
                    </Section>
                    <Section>
                        {t('company.protection.comfortable-and-secure-experience')}
                    </Section>
                    <Section>
                        {t('company.protection.we-collect-minimum-data')}
                    </Section>
                    <Section title={t('company.protection.seller-needs-to-provide-some-data')}>
                        <UnorderedList>
                            <ListItem>{t('company.protection.private-seller-data-requirements')}</ListItem>
                            <ListItem>{t('company.protection.corporate-seller-data-requirements')}</ListItem>
                        </UnorderedList>
                        <br/>
                        <Text>{t('company.protection.vin-is-public')}</Text>
                    </Section>
                    <Section title={t('company.protection.username-password')}>
                        {t('company.protection.email-is-a-username')}
                        <br/><br/>
                        {t('company.protection.password-security')}
                    </Section>
                    <Section title={t('company.protection.payment-info')}>
                        {t('company.protection.payment-providers')}
                    </Section>
                </Flex>
            </Stack>
        </Container>
    );
};