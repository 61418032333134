import React, {useCallback} from 'react';
import {useTranslation} from "react-i18next";
import {Button, GridItem, SimpleGrid, Text, VStack} from '@chakra-ui/react';
import {useShallow} from "zustand/react/shallow";
import {useNavigate} from "react-router-dom";

import {
    parseAndSetEnd, parseAndSetStart
} from "./simpleSearchHelper";
import {years} from '../../data/years';
import {mileages} from "../../data/mileages";
import {pricesFormatted as prices} from "../../data/prices";
import {useDictionaryStore, useOptionsStore} from "../../state/store";
import {initialOptions} from '../../state/initialstate';
import {getFieldsFromStore} from '../../state/storeUtils';
import {createSearchString} from '../../integration/search/searchConnectorUtils';
import SelectFieldSimple from '../Search/SelectFieldSimple';
import SelectFieldSimple2 from '../Search/SelectFieldSimple2';
import {isEmpty} from "lodash";

export const SimpleSearch = () => {


    const optionsStore = useOptionsStore();

    const models = useDictionaryStore(useShallow(store => store.models));
    const makes = useDictionaryStore(useShallow(store => store.makes));
    const transmissions = useDictionaryStore(useShallow(store => store.transmissions));

    const make = useOptionsStore(useShallow(store => store.make));
    const model = useOptionsStore(useShallow(store => store.model));

    const year = useOptionsStore(useShallow(store => store.year));
    const mileage = useOptionsStore(useShallow(store => store.mileage));
    const price = useOptionsStore(useShallow(store => store.price));
    const transmission = useOptionsStore(useShallow(store => store.transmission));

    console.log('SimpleSearch props', optionsStore);

    const {t} = useTranslation(['data-for-ui', 'texts']);
    const navigate = useNavigate();

    const changeMake = useCallback(e => {
        const make = e.target.value;

        if (make) {
            const matchingMake = makes.find(item => item.value === make);
            optionsStore.updateMultipleOptions({
                make: {...matchingMake, visible: true}, model: initialOptions.model
            });
        } else {
            optionsStore.setMake(initialOptions.make);
        }
    }, [makes, optionsStore.setMake, optionsStore.updateMultipleOptions]);


    const changeModel = useCallback(e => {
        const model = e.target.value;

        if (model) {
            const matchingModel = models.find(item => item.value === model);
            optionsStore.setModel({...matchingModel, visible: true});
        } else {
            optionsStore.setModel(initialOptions.model);
        }

    }, [optionsStore.setModel, models]);

    const changeYear = useCallback(e => {

        const year = e.target.value;
        // console.log('SimpleSearch before changeYear', year);
        parseAndSetEnd(year, initialOptions.year, optionsStore.setYear);
        // console.log('SimpleSearch after changeYear', optionsStore)

    }, [optionsStore.setYear]);


    const changeMileage = useCallback(e => {

        const mileage = e.target.value;
        parseAndSetEnd(mileage, initialOptions.mileage, optionsStore.setMileage);

    }, [optionsStore.setMileage]);


    const changePrice = useCallback(e => {

        const price = e.target.value;
        parseAndSetEnd(price, initialOptions.price, optionsStore.setPrice);

    }, [optionsStore.setPrice]);

    const changeTransmission = useCallback(e => {
        let transmission = e.target.value;

        if (isEmpty(transmission)) {
            optionsStore.setTransmission(initialOptions.transmission);
        } else {
            const match = transmissions.find((item) => item.value === transmission);
            optionsStore.setTransmission({
                ...match, visible: true,
            });
        }

    }, [optionsStore.setTransmission, transmissions]);

    const submitSearchRequest = async () => {

        const storeMap = getFieldsFromStore(optionsStore);
        console.log('SimpleSearch submitSearchRequest keys', storeMap);

        const searchString = createSearchString(storeMap);
        console.log('SimpleSearch submitSearchRequest searchString', searchString);

        await optionsStore.updateMultipleOptions(storeMap);
        navigate('/');
    }

    return (<VStack
        align="start"
        spacing={5}
        rounded={{base: 0, sm: 'md'}}
        bg="white"
        px={{base: 4, md: 6}}
        py={{base: 5, md: 8}}
        maxW="lg"
        minW={280}
    >
        <Text as={'h1'} textStyle="h1">
            {t('texts:home.find-dream-car')}
        </Text>
        <SimpleGrid columns={2} columnGap={4} rowGap={6} w="full">
            <SelectFieldSimple2
                label={t('texts:sell.characteristics.make')}
                options={makes}
                field='make'
                handler={changeMake}
                fieldFromStore={make}/>
            <SelectFieldSimple2
                label={t('texts:sell.characteristics.model')}
                field='model'
                options={!make.name ? [] : models}
                fieldFromStore={model}
                handler={changeModel}/>
            <SelectFieldSimple
                label={t('texts:sell.characteristics.year')}
                field='end-year'
                options={years}
                fieldFromStore={year}
                handler={changeYear}/>
            <SelectFieldSimple
                label={t('texts:sell.characteristics.mileage')}
                field='end-mileage'
                options={mileages}
                fieldFromStore={mileage}
                handler={changeMileage}/>
            <SelectFieldSimple
                label={t('texts:sell.characteristics.price')}
                field='end-price'
                options={prices}
                fieldFromStore={price}
                handler={changePrice}/>
            <SelectFieldSimple2
                label={t('texts:sell.characteristics.transmission')}
                field='transmission'
                fieldFromStore={transmission}
                options={transmissions}
                handler={changeTransmission}
            />
            <GridItem colSpan={[2, 1]} mt={0}>
                <Button variant="primary" w="full" size={{base: "lg", sm: "md"}}
                        onClick={submitSearchRequest}>
                    {t('texts:nav.search')}
                </Button>
            </GridItem>
        </SimpleGrid>
    </VStack>);
};