import React, {useEffect, useState} from 'react';
import {
    Text,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {UpgradeModalHistory} from "./UpgradeModal/UpgradeModalHistory";
import {UpgradeModalContent} from "./UpgradeModal/UpgradeModalContent";
import {UpgradeModalPayment} from "./UpgradeModal/UpgradeModalPayment";

export const UpgradeAdvertisementModal = ({isOpen, onClose, car, isPublished, upgradeHistory}) => {

    const {t} = useTranslation();
    const [upgradePeriod, setUpgradePeriod] = useState(7);

    const [activeArea, setActiveArea] = useState('upgrade');

    useEffect(() => {
        console.log('upgradeHistory changed', upgradeHistory);
    }, [upgradeHistory]);

    console.log('upgradeHistory in modal', upgradeHistory);

    return <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay>
            <ModalContent
                // maxW={{base: '300px', md: '400px'}}
            >
                <ModalHeader>
                    <Text>{t('sell.car-ad-card.upgrade-modal.upgrade-offering')}</Text>
                    <ModalCloseButton position='absolute' top='1rem'/>
                </ModalHeader>
                <ModalBody>
                    {activeArea === 'upgrade' &&
                        <UpgradeModalContent car={car}
                                             upgradePeriod={upgradePeriod}
                                             setUpgradePeriod={setUpgradePeriod}
                                             isPublished={isPublished}
                                             setActivateArea={setActiveArea}
                        />
                    }
                    {activeArea === 'history' &&
                        <UpgradeModalHistory car={car}
                                             upgradeHistory={upgradeHistory}
                                             setActivateArea={setActiveArea}
                        />
                    }
                    {activeArea === 'payment' &&
                        <UpgradeModalPayment car={car}
                                             upgradePeriod={upgradePeriod}
                                             setActivateArea={setActiveArea}
                        />
                    }
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </ModalOverlay>
    </Modal>
}