export const transformTagValue = (key, value) => {

    console.log('transformTagValue', key, value);

    if (key === 'price' || key === 'mileage' || key === 'power') {
        const formattedValues = value.value
            .filter(v => v !== undefined)
            .map(v => v.toLocaleString('fr-FR'))
            .join(' - ');

        const unit = key === 'price' ? ' €' : key === 'mileage' ? ' KM' : ' HP';

        return {key, value: formattedValues + unit};

    } else if (key === 'year') {

        const formattedYear = value.value.filter(v => v !== undefined).join(' - ');
        return {key, value: formattedYear};

    } else if (value.title) { // if property has title, use it - {make: {name: 'Porsche/Bentley/Rolls-royce', title: 'Luxury'}}
        return {
            key: key,
            value: value.title
        };
    } else return {key: key, value: value.name};
}