import React, {useState} from 'react';
import {Box, Container, Stack, Text, VStack, Image, Heading} from "@chakra-ui/react";
import {Sidebar} from "../Sidebar";
import {useTranslation} from "react-i18next";

export const HowToSell = () => {

    const {t} = useTranslation(['support']);
    const [active, setActive] = useState('how-to-sell');

    return <Container maxW="container.xl" my={[4, 9]}>
        <Stack direction={{base: 'column', md: 'row'}}
               align='start'
               spacing={[8, 12]}
               w="full"
        >
            <Sidebar active={active} setActive={setActive} linksType='support'/>
            <VStack align="start" maxW={{base: 'full', md: '60%'}} gap={4}>
                <Heading as='h2' size="md" textTransform='capitalize'>
                    {t('support:support.sell.how-to-sell')}
                </Heading>
                <Text>{t('support.sell.add-car-for-sale')}</Text>
                <Box as="ul" pl={4}>
                    <Box as="li" my={4}>
                        <Text as="span" fontWeight="bold">
                            {t('support.sell.sign-up-or-login')}
                        </Text>
                        {/*<Image src={loginToSell} w={['full', '300px']}*/}
                        {/*       rounded={'lg'}*/}
                        {/*       alt="login" border="1px solid #eee"/>*/}
                        <VStack align="start" mt={2} spacing={4}>
                            {/*<Text>{t('support.sell.click-login-button')}</Text>*/}
                            <Text>{t('support.sell.modal-window-on-login')}</Text>
                            {/*<Text>{t('support.sell.email-registration')}</Text>*/}
                        </VStack>
                    </Box>
                    <Box as="li" my={4}>
                        <Text as="span" fontWeight="bold">
                            {t('support.sell.fill-in-sellers-info')}
                        </Text>
                        <VStack align="start" mt={2} spacing={4}>
                            {/*<Text>{t('support.sell.sellers-info')}</Text>*/}
                            <Text>{t('support.sell.first-sellers-login')}</Text>
                        </VStack>
                    </Box>
                    <Box as="li" my={4}>
                        <Text as="span" fontWeight="bold">
                            {t('support.sell.add-a-car')}
                        </Text>
                        <VStack align="start" mt={2} spacing={4}>
                            <Text>{t('support.sell.adding-car-needs-account-info')}</Text>
                            {/*<Text>{t('support.sell.automatic-mode')}</Text>*/}
                            {/*<Text>{t('support.sell.select-make-model-generation')}</Text>*/}
                        </VStack>
                    </Box>
                    <Box as="li" my={4}>
                        <Text as="span" fontWeight="bold">
                            {t('support.sell.publish-to-search')}
                        </Text>
                    </Box>
                </Box>
            </VStack>
        </Stack>
    </Container>
}