export const mileages = [
    {value: "", name: "", disabled: true, selected: false},
    {value: "1000", name: "1 000"},
    {value: "2500", name: "2 500"},
    {value: "5000", name: "5 000"},
    {value: "10000", name: "10 000"},
    {value: "30000", name: "30 000"},
    {value: "50000", name: "50 000"},
    {value: "75000", name: "75 000"},
    {value: "100000", name: "100 000"},
    {value: "150000", name: "150 000"},
    {value: "200000", name: "200 000"}
];
