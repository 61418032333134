import React, {useCallback} from 'react';
import {Box, Card, Center, SimpleGrid, Stack} from '@chakra-ui/react';
import {useNavigate} from "react-router-dom";
import {popularMakesList} from "./makes/popularMakesList";
import {useOptionsStore} from '../../state/store';
import {capitalize} from "lodash";

export const MakesGrid = ({updateMultipleOptions}) => {
    const store = useOptionsStore();
    const navigate = useNavigate();

    const handleScrollableItemClick = useCallback(make => {
        return async () => {
            store.updateOneResetOthers('make', {value: make, name: capitalize(make), visible: true});
            navigate('/');
        };
    }, [updateMultipleOptions, navigate]);

    return <Box w='full'
                borderRadius="lg"
    >
        <Stack spacing="1" w='full'>
            <SimpleGrid
                w='full'
                gap={{base: 4, md: 4, xl: 3}}
                columns={{base: 3, sm: 5, md: 6, lg: 8, xl: 10}}
                justifyContent="center"
                alignItems="center"
                px={0}
            >
                {popularMakesList.map(({logo, make}) => (
                    <Center key={make} cursor="pointer" onClick={handleScrollableItemClick(make)}>
                        <Card
                            justifyContent='center'
                            align="center"
                            variant="outline"
                            w={120}
                            h={76}
                            rounded='md'>
                            {logo}
                        </Card>
                    </Center>
                ))}
            </SimpleGrid>
        </Stack>
    </Box>;
};