import React, {useEffect, useState} from "react";
import {
    Button,
    Container,
    HStack,
    IconButton,
    useDisclosure,
} from "@chakra-ui/react";

import {Logo} from "../Logo/Logo";
import {Globe} from "../../icons/20";
import {LanguageModal} from "../Modals/Language/LanguageModal";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import {FavouriteIcon} from "../FavouriteIcon/FavouriteIcon";
import {useOptionsStore} from "../../state/store";
import {initialOptions} from "../../state/initialstate";

export const Navbar = () => {
    const {t} = useTranslation(["texts"]);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const navigate = useNavigate();
    const location = useLocation();

    const {instance} = useMsal();
    const isAuthenticated = useIsAuthenticated();
    // console.log('NavBar isAuthenticated', isAuthenticated, 'instance', instance, 'accounts', accounts);

    const searchText = t("nav.search");
    const sellText = t("nav.sell");

    const optionsStore = useOptionsStore();

    const [favourites, setFavourites] = useState(() => {
        const storedFavourites = localStorage.getItem('favourites');
        return storedFavourites ? JSON.parse(storedFavourites) : [];
    });

    useEffect(() => {
        const handleStorageChange = () => {
            const storedFavourites = localStorage.getItem('favourites');
            setFavourites(storedFavourites ? JSON.parse(storedFavourites) : []);
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);


    const handleLogin = async () => {
        if (!isAuthenticated) {

            try {
                const loginResult = await instance.loginRedirect({
                    scopes: ['openid', 'profile', 'email'], prompt: 'select_account'
                });
                navigate("/sell"); //remove if redirect to /sell is fixed on api side
            } catch (error) {
                console.log('NavBar handleLogin error', error);
            }
        } else {
            navigate("/sell"); //logged in user is redirected to sell page
        }
    };

    const handleNavigation = (path) => { //prevent navigating to the same page on multiple clicks
        if (location.pathname !== path) {
            navigate(path);
            optionsStore.updateMultipleOptions(initialOptions);
        }
    };

    return <Container maxW="container.xl" py={4} as='nav'
                      borderBottom={['1px solid #eee', 'none']}
    >
        <HStack
            w="full"
            align="center"
            justify="space-between"
            wrap={{base: "wrap", sm: "nowrap"}}
        >
            <IconButton aria-label={'home'}
                        icon={<Logo/>}
                        h='36px'
                        onClick={() => handleNavigation("/")}
            />

            <HStack>
                <FavouriteIcon
                    hideFrom={'md'}
                    favourites={favourites}
                    handleNavigation={handleNavigation}/>
                <IconButton
                    hideFrom="sm"
                    aria-label="Change language"
                    icon={<Globe height='18px' width='18px'/>}
                    isRound={true}
                    variant="ghost"
                    onClick={onOpen}
                />

            </HStack>

            <HStack alignItems="center" spacing={[5, 4]} flex={{base: 1, sm: 0}}>
                <Button
                    variant="outline"
                    flex={{base: 1, md: "auto"}}
                    size="sm"
                    minW='130px'
                    onClick={() => handleNavigation("/")}
                >
                    {searchText}
                </Button>
                <Button
                    variant="primary"
                    flex={{base: 1, md: "auto"}}
                    size="sm"
                    minW='130px'
                    onClick={handleLogin}
                    // onClick={() => navigate("/sell")}
                >
                    {" "}
                    {sellText}
                </Button>

                <FavouriteIcon favourites={favourites}
                               hideBelow={'md'}
                               marginLeft={'-7px'}
                               handleNavigation={handleNavigation}/>
                <IconButton
                    hideBelow="sm"
                    ml={'-11px'}
                    aria-label="Change language"
                    icon={<Globe/>}
                    // icon={<IoIosGlobe size={24}/>}
                    isRound={true}
                    size="sm"
                    variant="ghost"

                    onClick={onOpen}
                />
            </HStack>
        </HStack>
        <LanguageModal isOpen={isOpen} onClose={onClose} onOpen={onOpen}/>
    </Container>
};