const validateTextRange = (text, minLength, maxLength) => {
    return text.trim() !== '' && text.length > minLength && text.length <= maxLength;
}

const validateEmail = text => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(text);
}


export {validateTextRange, validateEmail};