import _, { isEmpty } from 'lodash';
import { initialOptions } from "../../state/initialstate";

const handleTransmission = (value) => {
    if (value === 'automatic') return "transmission ne 'manual'";
    if (value === 'manual') return "transmission eq 'manual'";
    return '';
};

const handleFuel = (value) => {
    if (value === 'gasoline' || value === 'petrol') return "(fuel eq 'gasoline' or fuel eq 'petrol')";
    if (value === 'electro' || value === 'electric') return "(fuel eq 'electro' or fuel eq 'electric')";
    return '';
};

const handleDrivetrain = (value) => {
    if (value === 'full') return "(drivetrain ne 'front' and drivetrain ne 'front wheel drive' and drivetrain ne 'rear' and drivetrain ne 'rear wheel drive')";
    if (value === 'front') return "(drivetrain eq 'front' or drivetrain eq 'front wheel drive')";
    if (value === 'rear') return "(drivetrain eq 'rear' or drivetrain eq 'rear wheel drive')";
    return '';
};

const handleSpecialCases = (key, value) => {
    const handlers = {
        transmission: handleTransmission,
        fuel: handleFuel,
        drivetrain: handleDrivetrain
    };
    return handlers[key] ? handlers[key](value) : null;
};

const createFilterString = (key, value) => {
    const specialCase = handleSpecialCases(key, value);
    if (specialCase) return specialCase;

    if (value.indexOf('/') !== -1) {
        const values = value.split('/').map(_.trim);
        return `(${values.map(v => `${key} eq '${v}'`).join(' or ')})`;
    }
    return `${key} eq '${value}'`;
};

const handleRangeFilter = (key, [val1, val2], limits) => {
    if (val1 === limits[0] && val2 !== limits[1]) return `(${key} le ${val2})`;
    if (val1 !== limits[0] && val2 === limits[1]) return `(${key} ge ${val1})`;
    if (val1 !== limits[0] && val2 !== limits[1]) return `(${key} ge ${val1} and ${key} le ${val2})`;
    return '';
};

const handleArrayFilter = (key, values) => {
    if (key === 'features') key = 'options';
    if (key === 'options' || key === 'history') {
        return values.map(el => `${key}/any(t: t eq '${el}')`).join(' and ');
    }
    if (key === 'color') {
        return `(${values.map(el => `${key} eq '${el}'`).join(' or ')})`;
    }
    return values.map(el => `${key} eq '${el}'`).join(' or ');
};

export const createSearchString = (options) => {
    console.log('SearchUtils.createSearchString options', options);

    const strings = [];
    const arrays = [];
    const ranges = [];

    Object.entries(options).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0 && key !== 'makes') {
            const filteredValues = value.filter(el => el.visible).map(el => {
                if (key === 'drivetrain') {
                    if (el.value === 'all wheel drive') return 'full';
                    if (el.value === 'front wheel drive') return 'front';
                    if (el.value === 'rear wheel drive') return 'rear';
                }
                return el.value;
            });
            arrays.push({ [key]: filteredValues });
        } else if (typeof value.value === 'string' && value.visible) {
            if (key === 'make') {
                strings.push({ PartitionKey: value.name.toLowerCase() });
            } else if (key !== 'ignoreSponsored') {
                strings.push({ [key]: value.value.toLowerCase() });
            }
        } else if (typeof value.value === 'object' && value.visible) {
            const limits = initialOptions[key].value;
            if (value.value[0] !== limits[0] || value.value[1] !== limits[1]) {
                ranges.push({ [key]: value.value });
            }
        } else if (typeof value.value === 'string' && !isEmpty(value?.model?.value)) {
            if (!isEmpty(value.value) && value.value !== 'any') {
                strings.push({ PartitionKey: value.value });
                if (value.model.visible) strings.push({ model: value.model.value.toLowerCase() });
            }
        }
    });

    const filters = [
        ...strings.map(obj => createFilterString(Object.keys(obj)[0], Object.values(obj)[0])),
        ...ranges.map(obj => {
            const [key, value] = Object.entries(obj)[0];
            return handleRangeFilter(key, value, initialOptions[key].value);
        }),
        ...arrays.map(obj => {
            const [key, value] = Object.entries(obj)[0];
            return handleArrayFilter(key, value);
        })
    ].filter(Boolean).join(' and ');

    const filtersPrefix = '&$filter=';
    const countPrefix = '&$count=true';

    return filters.length > 0 ? `${countPrefix}${filtersPrefix}${filters}` : countPrefix;
};

export const generateTop = items => `&$top=${items}`;

export const generateSkip = (activePageIndex, itemsPerPage) =>
    activePageIndex !== undefined ? `&$skip=${activePageIndex * itemsPerPage}` : '';

export const generateSort = (sort, ignoredSponsored) => {
    if (sort !== 'undefined') {
        const baseSort = sort.replace('-', ' ');
        return ignoredSponsored.value === 'true'
            ? `&$orderby=${baseSort}`
            : `&$orderby=rating desc,${baseSort}`;
    }
    return ignoredSponsored.value !== 'true' ? '&$orderby=rating desc' : '';
};

export const generateFacets = query =>
    query.indexOf('&$filter=') === -1 ? '&facet=city,count:20 &facet=model,count:60' : '';