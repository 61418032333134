export const getFieldsFromStore = store => {

    console.log('getFieldsFromStore', store);


    const storeMap = Object.entries(store).reduce((acc, [key, value]) => {
        if (typeof value !== 'function') {
            acc[key] = value;
        }
        return acc;
    }, {});
    return storeMap;
}

