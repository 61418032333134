import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import {getInitialLanguage} from './languageHelper';


i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        debug: true,
            // lng: 'en',  // set the language on initialization
        lng: getInitialLanguage(),
        fallbackLng: 'en',
        whitelist: ['en', 'ru', 'ee', 'lt', 'lv', 'fi', 'se'],
        ns: ['texts', 'data-for-ui', 'locations', 'characteristics', 'support', 'errors', 'extended-dictionary', 'countries'],
        defaultNS: 'texts',
        fallbackNS: 'texts',
        interpolation: {escapeValue: false},
        backend: {loadPath: '../locales/{{lng}}/{{ns}}.json',},
    });

export default i18n;