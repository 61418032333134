import React from 'react';
import {Skeleton, VStack} from '@chakra-ui/react';
import {initialSearch} from "../../state/initialstate";

export const SkeletonStack = ({numberOfItems = 1}) => {

    // const numberOfItems = initialSearch.itemsPerPage;

    return <VStack w="full" spacing={6}>
        {
            Array.from({length: numberOfItems}, (_, index) => (
                <Skeleton key={index} height="268px" startColor="neutral.50" endColor="neutral.200" rounded="xl"
                          w="full"/>
            ))
        }
    </VStack>
};