export const footerLinks = [
    {
        title: 'texts:footer.company',
        links: [
            {
                title: 'texts:footer.company',
                href: '/company/about',
            },
            {
                title: 'texts:footer.about',
                href: '/company/legal',
            },
            {
                title: 'texts:footer.data-protection',
                href: '/company/protection',
            },
            {
                title: 'texts:footer.contact',
                href: '/company/contact',
            }
        ]
    },
    {
        title: 'support.support',
        links: [
            {
                title: 'support:support.search.how-to-search',
                href: '/support/how-to-search',
            },
            {
                title: 'support:support.sell.how-to-sell',
                href: '/support/how-to-sell',
            },
            {
                title: 'support:support.sell.how-to-partner',
                href: '/support/how-to-partner',
            },
            {
                title: 'support:support.sell.sitemap',
                href: '/support/sitemap',
            }
        ]
    },
    {
        title: 'texts:footer.social-media',
        links: [
            {
                title: 'texts:footer.facebook',
                href: 'https://www.facebook.com/caruniverse.ee',
            },
            {
                title: 'texts:footer.instagram',
                href: 'https://www.instagram.com/caruniverse.ee/',
            }
        ]
    }
]
