export const extractIdFromUrl= url => {
    const urlParts = url.split('/');
    return urlParts[urlParts.length - 1];
}


export const parseCarIDfromURL = (carURL) => {
    // use such structure: makeModel: makeModel,
    //         year: year,
    //         id: id,
    //         error: null

    const defaultValues = {
        makeModel: '',
        year: '',
        id: '',
        error: '',
    };

    const parts = carURL.split('-');
    console.log('parseCarIDfromURL parts', parts, carURL);

    //Early return on length
    if (carURL.length <= 10 || carURL.length > 120) {
        return {...defaultValues, error: 'Invalid URL length'};
    }

    //use only last 2 segments for validation
    if (parts.length < 3 && parts.length > 10) { // 4-6 segments: mercedes-benz s-class-amg
        return {...defaultValues, error: 'Incorrect number of URL segments'};
    }

    const id = parts.pop(); // Last element is ID
    const year = parts.pop(); // Second last element is year
    const makeModel = parts.join('-');

    // Validate Year
    const yearPattern = /^\d{4}$/;
    // const yearPattern = /^\d{4}$/;
    const currentYear = new Date().getFullYear();
    if (!yearPattern.test(year) || parseInt(year) < 1900 || parseInt(year) > currentYear + 1) {
        return {...defaultValues, error: "Invalid year"};
    }
    // Validate ID with a general pattern (any alphanumeric string of adequate length)
    const idPattern = /^[A-Za-z0-9]{5,20}$/; // Example: At least 10 alphanumeric characters
    if (!idPattern.test(id)) {
        return {...defaultValues, error: "Invalid ID"};
    }

    // Return the parsed data
    const parsedCarObject = {
        makeModel: makeModel,
        year: year,
        id: id,
        error: null
    };
    return parsedCarObject;
};