import * as searchConnector from "../../../integration/search/AzureSearchConnector";
import {cloneDeep, isEmpty, orderBy} from "lodash";
import {updateObjectForInsert} from "../../../integration/tables/tableUtils";


export const checkIfPublished = async (car, token) => {
    console.log('checkIfPublished', car.vin);
    try {
        const response = await searchConnector.getDocument(car.vin, token);
        console.log('response status', response, car.vin);
        if (response !== undefined) {
            if (response.status === 200) {
                return true;
            } else if (response.status === 204) {
                return false
            }
        } else {
            console.log('checkIfPublished response undefined');
        }
    } catch (error) {
        console.warn('checkIfPublished error', error);
    }
}


export const publishCarToSearch = async (car, token) => {
    console.log('publishCarToSearch');
    const copy = cloneDeep(car);
    updateObjectForInsert(copy);
    const paths = JSON.parse(car.blobPaths);
    // const options = JSON.parse(car.options);
    // const history = JSON.parse(car.history);

    delete copy.powerKW;
    copy.blobPaths = paths;
    // copy.history = history;
    // copy.options = options;
    // console.log('copy after preparation with paths', copy);
    const response = await searchConnector.publishDocument(copy, token);
    console.log('response from publish', response);
    return true;
    // setIsPublished(true);
}

export const unpublishCarFromSearch = async (car, token) => {
    console.log('unpublishCarFromSearch');
    const response = await searchConnector.unpublishDocument(car, token);
    console.log('response after unpublish', response);
    return false;
    // setIsPublished(false);
}

export const getPrice = (period) => {
    let price = 0;
    if (period !== undefined) {
        const prices = {
            7: "7eur",
            14: "14eur",
            21: "20eur",
            28: "25eur"
        };
        price = prices[period];
        console.log('getPrice', period, price);
    }
    return price;
}

export const getURL = amount => {
    const urls = {
        '7': 'https://buy.stripe.com/test_eVa3e6flVdg6fqU6oo',
        '14': 'https://buy.stripe.com/test_7sI4ia7Ttb7Y6Uo145',
        '20': 'https://buy.stripe.com/test_3cscOGb5F0tk5QkbIL',
        '25': 'https://buy.stripe.com/test_3cs9CugpZ7VM1A47su'
    };
    return urls[amount];
}

/**
 * Orders
 * */
export const processUpgradeOrders = orders => {
    if (!isEmpty(orders)) {
        const cars = orders.values;
        let sortedCarOrders = orderBy(cars, car => Date.parse(car.endDate), 'desc');
        return {values: sortedCarOrders};
    }
    return {values: []};
}