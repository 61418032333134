import {Text, VStack} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {MakesGrid} from "./MakesGrid";

export const PopularMakes = () => {

    const {t} = useTranslation(['texts']);

    return <VStack spacing={{base: 4, lg: 6}} align="start" w="full">
        <Text as="h3" textStyle="h2" textTransform="uppercase">
            {t('texts:home.popular-makes')}
        </Text>
        <MakesGrid />
    </VStack>
}