import React from 'react';
import {Box, Container, Spacer, VStack} from "@chakra-ui/react";
import {Hero} from "../components/Hero/Hero";
import {PopularMakes} from "../components/PopularMakes/PopularMakes";
import {PopularTypes} from "../components/PopularTypes/PopularTypes";
import {PopularModels} from "../components/PopularModels/PopularModels";
import {PopularByCountry} from "../components/PopularByCountry/PopularByCountry";
import {PopularPriceRanges} from '../components/PopularPriceRanges/PopularPriceRanges';

export const Main = (props) => {

    console.log('Main2', props);

    return <>
        <Hero/>
        <Container
            id='main-container-1'
            maxW="container.xl" my={[4, 9]}>
            <VStack align="start" w="full" gap={{base: 8, md: 9}}>

                <PopularMakes/>

                <PopularTypes/>

                <PopularPriceRanges/>

                <PopularModels/>

                <PopularByCountry/>

            </VStack>
        </Container>
    </>;
}