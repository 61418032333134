import React, {useState} from "react";
import {useProfileStore} from "../../state/store";
import {useMsal} from "@azure/msal-react";
import {Box, Button, Divider, Image, Input, List, ListItem, Stack, Text} from "@chakra-ui/react";
import {handleFileChangeAndResize} from "../MyOffers/utils/fileUtils";
import {upload} from "../MyOffers/utils/imageFunctions";
import {useTranslation} from "react-i18next";

export const AddPhoto = ({car, onClose, isPublished, handleTabsChange}) => {

    const {t} = useTranslation(['texts']);

    const [files, setFiles] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [progress, setProgress] = useState(0);
    const profileStore = useProfileStore();
    const {accounts} = useMsal();
    const [errors, setErrors] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    /** error object {status: 'error', file: files[i].name, message: 'Error processing image'} */


    return <Box maxW={['100%', '50%']}>
        <Stack spacing={3}>
            <Input type={'file'}
                   accept='image/*'
                   multiple
                   onChange={async event => {
                       const resizedFiles = await handleFileChangeAndResize(event, setProgress, setErrors);
                       setFiles(resizedFiles);
                       console.log('resized files:', resizedFiles);
                       setDisableSubmit(false);
                   }}
            />
            {errors.length === 0
                ? <><Text>Progress: {`${progress ? progress + '%' : ''}`}</Text>
                    <Text>Files: {files.length}</Text>
                </> :
                <><Text color='red'>Errors with files:</Text>
                    <List>
                        {errors.map((er, i) =>
                            <ListItem key={`e-${i}`} color='red' my={2}>
                                <Text fontSize='sm'>{er.file}</Text>
                                <Text fontSize='sm'>{er.message}</Text>
                            </ListItem>)
                        }
                    </List>
                </>

            }
            <Divider/>
            <List>
                {files.map((file, i) =>
                    <ListItem key={`image-to-add-${i}`} mb={4}>
                        <Image src={URL.createObjectURL(file)}/>
                        <Text>{`${file.name} (${Math.round(file.size / 1000)}kB)`}</Text>
                    </ListItem>
                )}
            </List>
        </Stack>
        <Button variant='outline'
                mt={5}
                isDisabled={disableSubmit || isPublished}
                isLoading={isUploading}
                onClick={async () => {
                    console.log('files', files);
                    try {
                        setIsUploading(true);
                        const response = await upload(files, car, accounts, profileStore.token);
                        console.log('response', response);
                        if (response && response.status === 201) {
                            console.log('files uploaded');
                            profileStore.setNeedsRefresh(true);
                            handleTabsChange(1);
                            onClose();
                        } else {
                            const fileNames = files.map(f => f.name).join(', ');
                            setErrors([{
                                status: 'error',
                                message: 'Error uploading',
                                file: fileNames
                            }]);
                        }
                    } catch (e) {
                        console.log('error', e);
                        setErrors([{message: 'Error uploading'}]);
                    } finally {
                        setIsUploading(false);
                    }
                }}>{t('sell.car-ad-card.edit-modal.footer.upload-images')}</Button>
    </Box>;
}