import React from 'react';
import {Box, Text} from '@chakra-ui/react';
import {changeLanguage} from 'i18next';

const LanguageListElement = ({languageCode, onClose, title}) => {

    return <Box as='li'
                w='200px' h='45px'
                border='2px solid #ddd'
                borderRadius='22.5px'
                cursor='pointer'
                userSelect='none'
                display='flex'
                alignItems='center'
                justifyContent='center'
                onClick={async () => {
                    await changeLanguage(languageCode);
                    localStorage.setItem('user-selected-language', languageCode);
                    onClose();
                }}>
        <Text>
            {title}
        </Text>
    </Box>
};
export default LanguageListElement;