import _ from "lodash";

const rootApiURI = process.env.REACT_APP_ROOT_API_URI;
const metadataApiKey = process.env.REACT_APP_METADATA_API_KEY;
const apiKeyName = process.env.REACT_APP_OCP_API_NAME;

class AzureMakesRESTConnector {

    retrieveMakes = async rating => {
        console.log('retrieveMakes',apiKeyName,apiKeyName);
        try {
            const response = await fetch(`${rootApiURI}/metadata/makes`, {
                headers: {
                    [apiKeyName]: metadataApiKey
                }
            });
            const makes = await response.json();
            // console.log('makes from API', makes, [apiKeyName], metadataApiKey);
            return _processMakes(makes.value);
        } catch(e) {
            console.error(e);
        }
    }
}

const _processMakes = makes => {
    // makes.forEach(el => console.log('el', el));
    const array = makes.map(el => (
        {
            name: el.Name,
            value: el.RowKey,
            rating: el.Rating
            // rating: el.PartitionKey
        }));
    const sorted = _.orderBy(array, ['name'], ['asc']);
    return sorted;
}

export {AzureMakesRESTConnector}