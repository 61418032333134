import React from 'react';
import {Box, SimpleGrid, GridItem, Grid} from "@chakra-ui/react";
import {FetchableImage} from "../FetchableImage/FetchableImage";
import {PlaceholderImage} from "../PlaceholderImage/PlaceholderImage";
import {PictureCounterBox} from "../PictureCounterBox/PictureCounterBox";

export const CarDetails = ({car, setShowGallery, setInitialGalleryIndex}) => {

    console.log('CarDetails, car: ', car);

    const imagesToShow = car.blobPaths ? car.blobPaths.slice(0, 5) : [];
    const placeholdersNeeded = 5 - imagesToShow.length;

    const handleImageClick = (index) => {
        setInitialGalleryIndex(index);
        setShowGallery.toggle();
    };

    return <Box h={{base: '200px', md: '480px'}} py={{base: 0, md: 2}} id='cardetails-box'>
        <Grid templateColumns={{base: "1fr", lg: "1fr 1fr"}} gap={4} h='full'>
            <GridItem colSpan={1} h={{base: '200px', md: '480px'}}>
                <Box
                    w='full'
                    h='full'
                    pos='relative'
                    borderTopLeftRadius={{lg: 'xl'}}
                    borderBottomLeftRadius={{lg: 'xl'}}
                    overflow='hidden'
                    borderWidth={1}
                    borderColor='neutral.200'
                    onClick={() => handleImageClick(0)}
                    cursor='pointer'
                >
                    <FetchableImage blobPath={imagesToShow[0]}
                                    h='full'
                                    w='full'
                                    objectFit='cover'
                                    fallback={<PlaceholderImage/>}
                    />
                    <PictureCounterBox totalImages={car.blobPaths.length}/>
                </Box>
            </GridItem>

            <GridItem colSpan={1}
                      display={{base: 'none', lg: 'block'}}
                      h='full'>
                <SimpleGrid columns={2} spacing={4}
                            w='full' h={{base: '200px', md: '480px'}}>
                    {imagesToShow.slice(1).map((src, index) => (
                        <Box
                            key={index}
                            w='full'
                            h='full'
                            borderTopRightRadius={index === 1 ? 'lg' : 'none'}
                            borderBottomRightRadius={index === 3 ? 'lg' : 'none'}
                            overflow='hidden'
                            borderWidth={1}
                            borderColor='neutral.200'
                            onClick={() => handleImageClick(index + 1)}
                        >
                            <FetchableImage blobPath={src} w='full' h='full' objectFit='cover'
                                            fallback={<PlaceholderImage bg='neutral.25'/>}
                            />
                        </Box>
                    ))}
                    {Array.from({length: placeholdersNeeded}).map((_, index) => {

                        const totalIndex = imagesToShow.slice(1).length + index; // Calculate the total index

                        return <Box
                            key={`skeleton-${index}`}
                            w='full'
                            h='full'
                            bg='neutral.25'
                            borderTopRightRadius={totalIndex === 1 ? 'lg' : 'none'}
                            borderBottomRightRadius={totalIndex === 3 ? 'lg' : 'none'}
                            overflow='hidden'
                            borderWidth={1}
                            borderColor='neutral.50'
                            onClick={() => console.log('Placeholder clicked', index)}
                        >
                            <PlaceholderImage/>
                            {/*{console.log('CarDetails, placeholderNeeded: ', imagesToShow.length)}*/}
                        </Box>
                    })}
                </SimpleGrid>
            </GridItem>
        </Grid>
        {/*</Card>*/}
    </Box>
}