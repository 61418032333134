export const selectedCountriesForMainPage = {
    Estonia: [
        "Tallinn",
        "Tartu",
        "Narva",
        "Kohtla-Järve",
        "Pärnu",
    ]
    ,
    Latvia: [
        "Riga",
        "Daugavpils",
        "Liepaja",
        "Jelgava",
        "Jurmala"]
    ,

    Lithuania: [
        "Vilnius",
        "Kaunas",
        "Klaipeda",
        "Siauliai",
        "Panevezys"]

    ,

    Finland: [
        "Helsinki",
        "Tampere",
        "Turku",
        "Oulu",
        "Jyvaskyla"]
    ,

    Sweden: [
        "Stockholm",
        "Goteborg",
        "Malmo",
        "Uppsala",
        "Upplands Vasby"]
    ,
    Norway: [
        "Oslo",
        "Bergen",
        "Trondheim",
        "Stavanger",
        "Kristiansand"]
}


export const modelsByCountryData = {


    countries: [
        {
            name: 'Estonia',
            cities: [
                {name: 'Tallinn', count: 1042},
                {name: 'Tartu', count: 234},
                {name: 'Narva', count: 234},
                {name: 'Kohtla-Jarve', count: 324},
                {name: 'Parnu', count: 121},
            ],
        },
        {
            name: 'Latvia',
            cities: [
                {name: 'Riga', count: 40},
                {name: 'Daugavpils', count: 3},
                {name: 'Liepaia', count: 345},
                {name: 'Jelgava', count: 345},
                {name: 'Jurmala', count: 345},
            ],
        },
        {
            name: 'Lithuania',
            cities: [
                {name: 'Riga', count: 40},
                {name: 'Daugavpils', count: 3},
                {name: 'Liepaia', count: 345},
                {name: 'Jelgava', count: 345},
                {name: 'Jurmala', count: 345},
            ],
        },
        {
            name: 'Finland',
            cities: [
                {name: 'Riga', count: 40},
                {name: 'Daugavpils', count: 3},
                {name: 'Liepaia', count: 345},
                {name: 'Jelgava', count: 345},
                {name: 'Jurmala', count: 345},
            ],
        },
        {
            name: 'Sweden',
            cities: [
                {name: 'Stockholm', count: 40},
                {name: 'Malmo', count: 345},
                {name: 'Upplands Väsby', count: 0},
                {name: 'Umea', count: 0},
                {name: 'Goteborg', count: 345},
            ],
        },
        {
            name: 'Norway',
            cities: [
                {name: 'Oslo', count: 40},
                {name: 'Kristjasand', count: 12},
                {name: 'Trondheim', count: 0},
                {name: 'Jelgava', count: 345},
                {name: 'Jurmala', count: 345},
            ],
        },
    ],
};
