import React from 'react';
import {VStack} from "@chakra-ui/react";
import {Link as ChakraLink} from "@chakra-ui/react";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const Sidebar = ({active, setActive, linksType = 'company'}) => {
    const location = useLocation();
    const {t} = useTranslation(['texts', 'support']);

    const companyLinks = [
        {path: '/company/about', label: 'texts:footer.about'},
        {path: '/company/legal', label: 'texts:footer.legal'},
        {path: '/company/protection', label: 'texts:footer.data-protection'},
        {path: '/company/contact', label: 'texts:footer.contact'},
    ];

    const supportLinks = [
        {path: '/support/how-to-search', label: 'support:support.search.how-to-search'},
        {path: '/support/how-to-sell', label: 'support:support.sell.how-to-sell'},
        {path: '/support/how-to-partner', label: 'support:support.sell.how-to-partner'},
        {path: '/support/sitemap', label: 'support:support.sell.sitemap'},
    ];

    const links = linksType === 'support' ? supportLinks : companyLinks;

    return (
        <VStack align="start" spacing={[1,4]}>
            {links.map(link => (
                <ChakraLink
                    as={Link}
                    to={link.path}
                    fontWeight={location.pathname.includes(link.path) ? "bold" : "normal"}
                    onClick={() => setActive(link.path)}
                    key={link.path}
                >
                    {t(link.label)}
                </ChakraLink>
            ))}
        </VStack>
    );
};