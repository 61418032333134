import React, {useState} from 'react';
import {
    CardElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import {
    Box,
    Button,
    FormControl,
    Text,
    Spinner
} from "@chakra-ui/react";
import './stripe.css';
import StatusMessages, {useMessages} from './StatusMessages';
import PaymentConfirmation from "../PaymentConfirmation";
import {useProfileStore} from "../../../../../state/store";
import {AzureUpgradeOrdersRESTConnector} from "../../../../../integration/tables/AzureUpgradeOrdersRESTConnector";


const StripeCheckoutForm2 = ({amount, car}) => {

    console.log('StripeCheckoutForm2', amount, car)

    const elements = useElements();
    const stripe = useStripe();
    const profileStore = useProfileStore();
    const token = profileStore.token;

    const [messages, addMessage] = useMessages();
    const [isLoading, setIsLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const upgradeOrdersConnector = new AzureUpgradeOrdersRESTConnector();


    const submitHandler = async e => {
        console.log('submitHandler', e.target)
        // e.preventDefault();
        console.warn('createIntent to be fired')

        if (!stripe || !elements) return;
        setIsLoading(true);

        addMessage('creating payment intent...');

        const {clientSecret, error} = await upgradeOrdersConnector.createOrderStripe(amount, car, token);

        console.log('clientSecret:', clientSecret, '\nerror:', error);
        clientSecret ? addMessage('payment intent created') : addMessage(error);

        if (clientSecret !== undefined) {
            const {error: stripeError, paymentIntent} =
                await stripe.confirmCardPayment(
                    clientSecret,
                    {
                        payment_method: {
                            card: elements.getElement(CardElement),
                        },
                    }
                );

            if (stripeError) {
                addMessage(stripeError.message);
                setIsLoading(false);
                setIsComplete(false);
            } else {
                console.log('paymentIntent', paymentIntent);
                setIsLoading(false);
                setIsComplete(true);
            }


        }
    };

    return <>
        {!isComplete && <>
            <FormControl id="payment-form" className='cardForm'>
                <CardElement id="card" options={cardStyle}/>
                <Button type="submit"
                        onClick={submitHandler}
                        disabled={!elements || !stripe || isLoading}>
                    <Box id="button-text">
                        {isLoading ? <Spinner/> : <Text>Pay now</Text>}
                    </Box>
                </Button>
            </FormControl>
            <StatusMessages messages={messages}/>
        </>
        }
        {isComplete && <PaymentConfirmation car={car} isComplete={isComplete}/>}
    </>
}

export default StripeCheckoutForm2;

const cardStyle = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#32325d"
            },
            background: 'white'
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    }
};