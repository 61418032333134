export const pickEnding = (start, array) => {

    const startAsInt = parseInt(start);
    // console.log('pickEnding', startAsInt, start);

    const subset = array.filter(year => parseInt(year.value) > startAsInt);

    subset.splice(0, 0, {value: "", name: "", disabled: true, selected: false});
    // console.log('subset', subset);
    return subset;
}

export const pickStarting = (end, array) => {
    const endAsInt = parseInt(end);
    // console.log('pickStarting', endAsInt, end);

    const subset = array.filter(year => parseInt(year.value) < endAsInt);

    subset.splice(0, 0, {value: "", name: "", disabled: true, selected: false});
    // console.log('pickStarting subset', subset);
    return subset;
}