const getKWfromHP = power => {
    return Math.round(power * 0.735499);
}

const processCharacteristicsFields = (characteristicFromResponse) => {
    console.log('processCharacteristics', characteristicFromResponse);

    const extraFields = {};

    const chrx = characteristicFromResponse.sort((a, b) => a.characteristicID - b.characteristicID);

    extraFields.fuel = chrx.find(a => a.characteristicID === 12) && chrx.find(e => e.characteristicID === 12).value;

    extraFields.transmission = chrx.find(e => e.characteristicID === 24) && chrx.find(el => el.characteristicID === 24).value;

    extraFields.drivetrain = chrx.find(el => el.characteristicID === 27) && chrx.find(e => e.characteristicID === 27).value;

    extraFields.power = chrx.find(el => el.characteristicID === 14) && chrx.find(el => el.characteristicID === 14).value;

    extraFields.powerKW = extraFields.power && getKWfromHP();

    extraFields.displacement = chrx.find(el => el.characteristicID === 13) && chrx.find(el => el.characteristicID === 13).value;

    return extraFields;
}

export {processCharacteristicsFields, getKWfromHP}