import {Checkbox, SimpleGrid, Text} from "@chakra-ui/react";

export const CheckboxList = ({dictionary, selectedItems, onChange}) => {
    return (
        <SimpleGrid columns={1} spacing={4} w="full">
            {dictionary.map((item, index) => {
                const isChecked = selectedItems.some(selectedItem => item.value === selectedItem.value);

                return (
                    <Checkbox
                        size="lg"
                        spacing="1rem"
                        colorScheme="primary"
                        isChecked={isChecked}
                        key={item.value + '-' + index}
                        onChange={(e) => onChange(e, item)}
                    >
                        <Text fontSize="14px">{item.name}</Text>
                    </Checkbox>
                );
            })}
        </SimpleGrid>
    );
};